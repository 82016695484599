import React, {useEffect} from 'react';
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";
// import {DQ_DEFINITIONS} from "../../../../utils/constants";
import {asciiSortCompareFunWithKey, getMapFromObjectListByKey} from "../../../../utils/utils";
import {makeStyles} from "@material-ui/core/styles";
import DQCircle from "../../../../assets/images/report/dqcircle.png";

const tableStyles = makeStyles((theme) => ({
    root: {
        borderCollapse: "collapse",
        "& th,td": {
            border: "1px solid black",
            padding: "5px 10px",
            fontSize: "18px"
        },
        "& th": {
            padding: "18px 12px",
            textAlign: "left",
            backgroundColor: "deepskyblue",
            color: "white",
            fontFamily: 'Source Sans Pro',
            fontWeight: 'bold',
        },
        "& th:nth-child(1)": {
            width: "30%"
        },
        "& th:nth-child(2)": {
            width: "70%"
        },
        "& td:nth-child(1)": {
            width: "30%"
        },
        "& td:nth-child(2)": {
            width: "70%"
        },
    },
}));


function BadgeRow(props) {
    const {badge} = props;
    const c = {color: "black", fontWeight: "normal", textAlign: "left", fontFamily: 'Source Sans Pro', fontSize: '15px'}

    return <>
        {
            badge &&
            <tr>
                <td style={c}>{badge.code} {badge.title}</td>
                <td style={c}>{badge.definition}</td>
            </tr>
        }
    </>
}

export default function Page19(props) {

    const {DQ_DEFINITIONS, pageNumber, coverage, instruments, onlyTable = false} = props;

    const classes = tableStyles();
    const badges = coverage;

    // if(coverage) {
    //     for(const item of coverage) {
    //         const {dqc_no} = item;
    //         const dq_num = parseInt(dqc_no.replace("DQC", ""), 10);
    //         const code = isNaN(dq_num) ? dqc_no : `DQ${dq_num}`
    //         const title = DQ_DEFINITIONS[code] ? DQ_DEFINITIONS[code].title : ""
    //         const definition = DQ_DEFINITIONS[code] ? DQ_DEFINITIONS[code].definition: "";
    //         const questions = instruments.filter((i) => i.dqc_no === dqc_no).map((i, idx) => `${idx+1}. ${i.instrument_question}`)
    //         badges.push({code, title, definition, questions});
    //     }
    // }

    return <ReportPage pageNumber={pageNumber}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={"flex-start"} height={"inherit"} width={"100%"} px={10}>
            {
                !onlyTable &&
                <Box id="6.-appendices" display={'flex'} flexDirection={'column'}>
                    <Typography variant={'h3'} style={{color: "deepskyblue", fontWeight: "bold", fontFamily: "Raleway", fontSize: '24px', paddingTop: 70}}>
                        6. Appendices
                    </Typography>
                    <Box id={"6.1-appendix-a:-description-of-dq-competencies-mentioned-in-analysis-i"}>
                        <Typography
                            variant={'h3'}
                            style={{
                                color: "orangered",
                                fontWeight: "lighter",
                                fontFamily: 'Raleway',
                                paddingTop: '30px',
                                fontSize: '22px'
                            }}
                        >
                            6.1	Appendix A: Description of DQ Competencies mentioned in <a style={{ color: 'blue', textDecoration: 'underline' }} href={`#${'2.1 Analysis I: Coverage of DQ Competencies'.toLowerCase().replace(/ /g, '-')}`}>Analysis I</a>.
                        </Typography>
                    </Box>
                </Box>
            }
            <Box paddingTop={onlyTable ? "70px" : "30px"}  width="100%">
                <table className={classes.root} style={{width: "100%"}}>
                    { !onlyTable &&
                        <tr>
                            <th>DQ Competency</th>
                            <th>Description</th>
                        </tr>
                    }
                    {
                        badges.map(b => <BadgeRow badge={b}/>)
                    }
                </table>
            </Box>
        </Box>
    </ReportPage>
}