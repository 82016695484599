import React from 'react';
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";
import {asciiSortCompareFunWithKey} from "../../../../utils/utils";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    ellipsis: {
        flexGrow: 1,
        margin: '10 10px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        '&::after': {
            // content: '"................................................................................................."',
            display: 'block',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
    },
    noWrap: {
        whiteSpace: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    level1: { fontWeight: 'bold' },
    level2: { marginLeft: '16px' },
    level3: { marginLeft: '32px' },
});


function TableContent(props) {
    const {titles, value} = props;
    const classes = useStyles();

    const getLevelClass = (title) => {
        const numbering = title.match(/^\d+(\.\d+)*\s/);
        if (!numbering) return classes.level1;

        const parts = numbering[0].trim().split('.');
        if (parts.length === 1) return classes.level1; // Level 1 (e.g., "1.")
        if (parts.length === 2) return classes.level2; // Level 2 (e.g., "1.1")
        if (parts.length >= 3) return classes.level3; // Level 3 (e.g., "1.1.1")
        return null;
    };



    return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} width="100%">
            {titles.map((title) => {
                const link = title.toLowerCase().replace(/ /g, '-');
                const levelClass = getLevelClass(title);
                // console.log("Level class for title", title, "is", levelClass);


                return (
                    <Box key={title} display={'flex'} justifyContent={'space-between'} alignItems={'center'} width="100%">
                        <a href={`#${link}`} style={{ textDecoration: 'none' }}>
                            <Typography className={`${classes.noWrap} ${levelClass}`} style={{color: "black", fontFamily: 'Source Sans Pro', fontSize: '18px', textAlign: 'justify' }}>
                                {title}
                            </Typography>
                        </a>
                        <Box className={classes.ellipsis} style={{fontWeight: levelClass === classes.level1 ? 'bold' : 'normal'}}>
                            <Typography style={{fontWeight: levelClass === classes.level1 ? 'bold' : 'normal', marginLeft: '18px', marginRight: '10px', color: "black", fontFamily: 'Source Sans Pro', fontSize: '12px', textAlign: 'center' }}>

                            </Typography>
                        </Box>
                        <Typography style={{fontWeight: levelClass === classes.level1 ? 'bold' : 'normal', color: "black", fontFamily: 'Source Sans Pro', fontSize: '18px', textAlign: 'right'}}>
                            {value}
                        </Typography>
                    </Box>
                );
            })}
        </Box>
    );
}



export default function Page2(props) {
    const {pageNumber, contents} = props;

    const contents_array = Object.entries(contents).map(([title, page_no]) => ({titles: title.split(','), page_no}));
    const numeric_asc_sort_fun = (a, b) => a.page_no - b.page_no;
    contents_array.sort(numeric_asc_sort_fun);

    return (
        <ReportPage pageNumber={pageNumber}>
            <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={"flex-start"} height={"inherit"} px={10} paddingTop={"70px"}>
                <Typography variant={'h3'} style={{color: "deepskyblue", fontWeight: "bold", fontFamily: 'Raleway', fontSize: '24px', textAlign: 'left'}}>
                    Contents
                </Typography>
                <Box mt={5}/>
                {
                    contents_array.map((item) =>
                        <TableContent titles={item.titles} value={item.page_no}/>
                    )
                }
            </Box>
        </ReportPage>
    );
}
