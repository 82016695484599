import React, {useState} from "react";
import {
    Grid,
} from "@material-ui/core";

import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {store} from "react-notifications-component";
import {submitContactUsRequest} from "../../../services/misc.service"
import {useFormik} from "formik";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

const useStyles = makeStyles((theme)=>({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: "center",
        marginTop: 10,
        marginBottom: 10
    },
    btn: {
        marginTop: 16
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(8, 15, 10),
        overflowY: 'auto'
    },
    form: {
        width: '100%',
        margin: '0 auto',
    },
    submit: {
        marginTop: 24,
        marginLeft: '30%',
        width: '40%',
    },
}));

const validateEmail = (email) => {
    let error;
    if (!email) {
        error = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        error = 'Invalid email address';
    }
    return error;
};

function PopUpForm(props){
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const { name, email, application_id, onClose} = props;
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            message: ''
        },
        validate: values => {
            const errors = {};
            const emailError = validateEmail(email);
            if (emailError) {
                errors.email = emailError;
            }
            return errors;
        },
        onSubmit: async (values, { resetForm }) => {
            const portal_name="DQ Seal Client";
            const messageWithAppId = `${values.message}\n`;
            const formData = { ...values, name, email, application_id, message: messageWithAppId };
            setLoading(true);

            try {
                const result = await submitContactUsRequest(formData.name, formData.email, formData.application_id, formData.message);
                if (result.data && result.data.success) {
                    store.addNotification({
                        title: "Request Submitted",
                        message: "Successfully submitted. We will follow up regarding your query as soon as possible.",
                        type: "success",
                        insert: "bottom",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 2000,
                            showIcon: true
                        }
                    });
                    resetForm();
                    onClose();
                }


            } catch (e) {
                // already toasted the error
            }

            setLoading(false);
        }
    });

    return (
        <>
                <div >
                    <br/>
                    <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>

                            {/*<Grid item xs={12}>*/}
                            {/*    <TextField*/}
                            {/*        variant="outlined"*/}
                            {/*        required*/}
                            {/*        fullWidth*/}
                            {/*        name="name"*/}
                            {/*        label="Name"*/}
                            {/*        type="text"*/}
                            {/*        id="name"*/}
                            {/*        autoComplete="current-password"*/}
                            {/*        onChange={formik.handleChange}*/}
                            {/*        value={formik.values.name}*/}
                            {/*    />*/}
                            {/*</Grid>*/}

                            {/*<Grid item xs={12}>*/}
                            {/*    <TextField*/}
                            {/*        variant="outlined"*/}
                            {/*        required*/}
                            {/*        fullWidth*/}
                            {/*        name="email"*/}
                            {/*        label="Email"*/}
                            {/*        type="email"*/}
                            {/*        id="email"*/}
                            {/*        onChange={formik.handleChange}*/}
                            {/*        onBlur={formik.handleBlur}*/}
                            {/*        value={formik.values.email}*/}
                            {/*        error={formik.touched.email && Boolean(formik.errors.email)}*/}
                            {/*        helperText={formik.touched.email && formik.errors.email}*/}
                            {/*    />*/}
                            {/*</Grid>*/}

                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="message"
                                    label= "Message"
                                    type="text"
                                    multiline={true}
                                    rows={6}
                                    id="message"
                                    onChange={formik.handleChange}
                                    value={formik.values.message}
                                />
                            </Grid>

                        </Grid>
                        <Button
                            style={{
                                marginTop: 24,
                                marginLeft: '30%',
                                width: '40%'
                            }}
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            submit
                        </Button>

                    </form>
                </div>
            <LoaderWithBackDrop loading={loading} />
    </>
    );
}
export default PopUpForm;