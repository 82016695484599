import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import DQGSMRelationship from "../../../../assets/images/report/DQGSMRelationship.png";
import ReportPage from "./PageContainer.report.comp";

export default function Page7(props) {
    const { pageNumber } = props;

    return <ReportPage pageNumber={pageNumber}>
        <Box id="1.4-about-the-dq-global-standards-micro-credentials-(gsm)-for-digital-competencies" display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={"flex-start"} height={"inherit"} px={10}>
            <Typography
                variant={'h3'}
                style={{
                    color: "orangered",
                    fontWeight: "lighter",
                    fontFamily: 'Raleway',
                    paddingTop: '70px',
                    fontSize: '22px'
                }}
            >
                1.4 About the DQ Global Standards Micro-credentials (GSM) for Digital Competencies
            </Typography>
            <Typography
                style={{
                    color: "black",
                    fontWeight: "lighter",
                    fontFamily: 'Source Sans Pro',
                    paddingTop: '30px',
                    fontSize: '18px',
                    textAlign: 'justify'
                }}
            >
                The DQ Global Standards Micro-credentials (DQ GSM) system is a list of the micro-learning blocks of the <b>
                IEEE DQ Global Standards (IEEE 3527.1&trade;)</b>. The DQ GSMs are developed in each of the 32 DQ competencies. The DQ GSMs help users to find out which digital skills topics, within the IEEE DQ Global Standards, that they will acquire through your program, and understand how the program will enhance specific literacy, safety, job readiness, or well-being.
            </Typography>
            <Box display={'flex'} alignItems={'center'}>
                <Box component="img" src={DQGSMRelationship} alt="DQ Competency" style={{ width: 'auto', height: '150px', marginRight: '20px', paddingTop: '30px' }} />
            <Typography
                style={{
                    color: "black",
                    fontWeight: "lighter",
                    fontFamily: 'Source Sans Pro',
                    paddingTop: '30px',
                    fontSize: '18px',
                    textAlign: 'justify'
                }}
            >
                The Report analyses reveals how your program covers specific learning objectives of DQ 32 competencies in terms of the depth and breadth through the DQ GSM scores.
            </Typography>
            </Box>
            <Typography
                style={{
                    color: "black",
                    fontWeight: "lighter",
                    fontFamily: 'Source Sans Pro',
                    paddingTop: '20px',
                    fontSize: '18px',
                    textAlign: 'justify'
                }}
            >
                When a DQ GSM has sufficient depth, it is considered that the program reasonably addresses the learning objectives of the corresponding GSM. We acknowledge that the program is aligned with a DQ Competency when there is sufficient breadth of DQ GSMs in meeting the DQ Competency Standards.
            </Typography>
            <Box>
                <Typography variant="body1" style={{ fontSize: '18px', fontFamily: 'Source Sans Pro', color: 'black', paddingTop: '20px', textAlign: 'justify' }}>
                    Through the DQ SCT analyses, you will be able to:
                </Typography>
                <ol style={{ fontSize: '18px', fontFamily: 'Source Sans Pro', color: 'black', textAlign: 'justify' }}>
                    <li>Identify areas of strength and/or areas for potential improvement of the programs,</li>
                    <li>Make a strategic decision on further enhancement of the programs,</li>
                    <li>Enhance the visibility of the programs as a best practice for specific GSMs.</li>
                </ol>
            </Box>
        </Box>
    </ReportPage>
}
