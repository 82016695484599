import {useEffect} from "react";
import {getLevel3Service} from "../../../services/app.service";

const transformMetaData = (objects) => {
    return objects.reduce((acc, obj) => {
        if (obj.dqc_no.startsWith("DQC") && obj.dqc_no.length === 5) {
            const modifiedDqcNo = obj.dqc_no.replace('C0', '').replace('C', '');
            acc[modifiedDqcNo] = {
                title: obj.full_name,
                definition: obj.definition,
                color: obj.color_code
            };
        }
        return acc;
    }, {});
};

export function useLevel3(setLevel3) {

    const loadLevel3 = async () => {
        try {
            const result = await getLevel3Service();
            if(!result.error) {
                const transformedMetaData = transformMetaData(result.data.metaData);
                setLevel3(transformedMetaData);
            }
        }
        catch (e) {
            console.error(e);
            // already toasted the error
        }
    }

    useEffect( ()=> {
        loadLevel3().then((res) => `level3 fetch initiated, ${res}`);
    }, [])
}