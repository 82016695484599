export const RESOURCES_TYPE = [
    {
        "value": "LessonCourse",
        "label": "Lesson / Course"
    },
    {
        "value": "LessonPlanGuide",
        "label": "Lesson plan / Guide"
    },
    {
        "value": "ActivityAssignment",
        "label": "Activity / Assignment"
    },
    {
        "value": "AssessmentQuiz",
        "label": "Assessment / Quiz"
    },
    {
        "value": "Framework",
        "label": "Framework"
    },
    {
        "value": "Research",
        "label": "Research"
    },
    {
        "value": "Policy",
        "label": "Policy"
    },
    {
        "value": "Others",
        "label": "Others"
    }
];

export const RESOURCES_TYPE_MAP = {
    "LessonCourse": {
        "label": "Lesson / Course"
    },
    "LessonPlanGuide": {
        "label": "Lesson plan / Guide"
    },
    "ActivityAssignment": {
        "label": "Activity / Assignment"
    },
    "AssessmentQuiz": {
        "label": "Assessment / Quiz"
    },
    "Framework": {
        "label": "Framework"
    },
    "Research": {
        "label": "Research"
    },
    "Policy": {
        "label": "Policy"
    },
    "Others": {
        "label": "Others"
    }
};