import React, {useEffect, useState} from 'react';
import { useAuth } from "../../context/auth";
import {makeStyles} from "@material-ui/core/styles";

import {Card, CardContent} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {getOverviewDataService} from "../../services/app.service";
import {APP_STATUS} from "../../utils/constants";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import Icon1 from "../../assets/images/Icon1.png"
import Icon2 from "../../assets/images/Icon2.png"
import Icon3 from "../../assets/images/Icon3.png"
import Icon5 from "../../assets/images/Icon4.png"
import Icon4 from "../../assets/images/Icon5.png"
import Icon6 from "../../assets/images/Icon6.png"
import 'aos/dist/aos.css'
import AOS from 'aos'

const useStyles = makeStyles((theme)=>({
    container: {
        padding: 50,
        marginTop:'20px',
    },
    card: {
       borderRadius: '15px',
       border: '1px solid',
       borderColor:  theme.palette.secondary.main,
       height: '180px',
        paddingTop:theme.spacing(2),
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: "bold",
        marginBottom: theme.spacing(1),
        fontSize: '1.4rem',
    },
    description: {
        color: theme.palette.text.secondary,
        fontWeight: "bold",
        textAlign: 'center',
        fontSize: '38px',
    },
    icon:{
        width: 'auto',
        height: 'auto',
        maxWidth: 50,
        maxHeight: 50,
        marginBottom: theme.spacing(1),
    }
}));

function StatusCard(props) {

    const {title, description,image} = props;
    const classes = useStyles();

    return <Card className={classes.card}>
        <CardContent>
            {/*{image && <img src={image} alt="Icon" className={classes.icon} />}*/}
            {/*<Typography variant={"h5"} align={'center'}>*/}
            {/*    {title}*/}
            {/*</Typography>*/}
            {/*<br/>*/}
            {/*<Typography className={classes.description} variant={"h4"} align={'center'}>*/}
            {/*    {description}*/}
            {/*</Typography>*/}

            <Grid container alignItems="top">
                <Grid item xs={3}>
                    {image && <img src={image} alt="Icon" className={classes.icon} />}
                </Grid>
                <Grid item xs={8} style={{textAlign:'center'}}>
                    <Typography variant="h5"  className={classes.title}>
                        {title}
                    </Typography>
                    <Typography variant="h5" className={classes.description}>
                        {description}
                    </Typography>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
}
function Home(){

    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const [statsData, setStatsData] = useState({
        total_apps: 0,
        aligned_apps: 0,
        measured_apps: 0,
        pending_payment: 0,
        in_progress: 0,
        completed: 0,
    });

    async function fetchOverviewData() {
        try {
            setLoading(true);
            const result = await getOverviewDataService();
            console.log('result: ', result);
            if(!result.error) {
                let total_apps = 0;
                let pending_payment = 0;
                let in_progress = 0;
                let completed = 0;
                let  aligned_apps= 0;
                let   measured_apps= 0;
                if (result.data.total_apps) {
                    total_apps = result.data.total_apps;
                }
                if(result.data.type_map){
                    aligned_apps = result.data.type_map[0].countC;
                    measured_apps = result.data.type_map[1].countC;
                }
                if (result.data.status_map) {
                    for (let item of result.data.status_map) {
                        switch (item._id) {
                            case APP_STATUS.DRAFT:
                            case APP_STATUS.PENDING_PAYMENT:
                                pending_payment += item.countC;
                                break;
                            case APP_STATUS.PENDING_APPROVAL:
                            case APP_STATUS.IN_PROGRESS_COLLECT_DATA:
                            case APP_STATUS.HALTED_COLLECT_DATA:
                            case APP_STATUS.IN_PROGRESS_PROCESS_DATA:
                            case APP_STATUS.HALTED_PROCESS_DATA:
                            case APP_STATUS.PENDING_REVIEW_MAPPING:
                            case APP_STATUS.PENDING_ANALYSIS:
                            case APP_STATUS.PENDING_REVIEW_REPORT:
                            case APP_STATUS.HALTED_REPORT:
                                in_progress += item.countC;
                                break;
                            case APP_STATUS.COMPLETED:
                                completed += item.countC;
                                break;
                        }
                    }

                }
                setStatsData({
                    total_apps,
                    aligned_apps,
                    measured_apps,
                    pending_payment,
                    in_progress,
                    completed,
                })
                setLoading(false);
            }
        }
        catch (e) {
            // already toasted the error
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchOverviewData().then((res) => `fetch initiated, ${res}`);
    }, [])

    useEffect(() => {
        AOS.init({
            duration: 500,
            fading: 'fade-in',
            once: true,
        });
    }, []);

    return <Grid className={classes.container} container spacing={5} justifyContent={'center'} marginTop={5}>
        <Grid item xs={4} data-aos="fade-in">
            <StatusCard title={'Total Applications'} description={statsData.total_apps ? ''+statsData.total_apps: '-'}  image={Icon1}/>
        </Grid>
        <Grid item xs={4} data-aos="fade-in" data-aos-delay="200">
            <StatusCard title={'Aligned Applications'} description={statsData.aligned_apps ? ''+statsData.aligned_apps: '-'} image={Icon2}/>
        </Grid>
        <Grid item xs={4} data-aos="fade-in" data-aos-delay="400">
            <StatusCard title={'Measured Applications'} description={statsData.measured_apps ? ''+statsData.measured_apps: '-'} image={Icon3}/>
        </Grid>
        <Grid item xs={4} data-aos="fade-in" data-aos-delay="600">
            <StatusCard title={'Pending Payment'} description={statsData.pending_payment ? ''+statsData.pending_payment: '-'} image={Icon4}/>
        </Grid>
        <Grid item xs={4} data-aos="fade-in" data-aos-delay="800">
            <StatusCard title={'In-Progress'} description={statsData.in_progress ? ''+statsData.in_progress: '-'} image={Icon5}/>
        </Grid>
        <Grid item xs={4} data-aos="fade-in" data-aos-delay="900">
            <StatusCard title={'Reports Available'} description={statsData.completed ? ''+statsData.completed: '-'} image={Icon6}/>
        </Grid>
        <LoaderWithBackDrop loading={loading} />
    </Grid>
}

export default Home;