import React from 'react';
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";
import {makeStyles} from "@material-ui/core/styles";
// import {DQ_DEFINITIONS} from "../../../../utils/constants";
import {asciiSortCompareFunWithKey} from "../../../../utils/utils";

const tableStyles = makeStyles((theme) => ({
    root: {
        borderCollapse: "collapse",
        tableLayout: 'fixed',
        width: '100vw',
        "& th,td": {
            border: "1px solid black",
            padding: "5px 10px",
            fontSize: "18px",
        },
        "& th": {
            fontFamily: 'Source Sans Pro',
            fontWeight: 'bold',
            padding: "18px 12px",
            textAlign: "left",
            backgroundColor: "deepskyblue",
            color: "white",
        },
        "& th:nth-child(1), & td:nth-child(1)": {
            width: "70%"
        },
        "& th:nth-child(2), & td:nth-child(2)": {
            width: "30%"
        },
    },
}));

function MicroBadgeRow({ badge, micro_badge }) {
    const badge_style = { backgroundColor: "#f6bc8f", fontWeight: "bold", color: "black", fontFamily: 'Source Sans Pro', fontSize: '18px' };
    const micro_style = { fontWeight: "normal", fontFamily: 'Source Sans Pro', fontSize: '18px' };
    const center_text_style = { textAlign: "center", fontFamily: 'Source Sans Pro', fontSize: '18px' };

    return (
        <tr>
            {badge ? (
                <>
                    <td style={{ ...badge_style }}>{badge.title}</td>
                    <td style={{ ...badge_style, ...center_text_style }}>{/*badge.count*/}</td>
                </>
            ) : (
                <>
                    <td style={{ ...micro_style }}>{micro_badge.title}</td>
                    <td style={{ ...micro_style, ...center_text_style }}>{micro_badge.count}</td>
                </>
            )}
        </tr>
    );
}

export default function Page10(props) {

    const {DQ_DEFINITIONS, pageNumber, depth, coverageMap, onlyTable = false, isLastPage} = props;
    let firstBadgeInfo = null;
    let firstMicroBadgeInfo = null;


    const classes = tableStyles();

    const badges = depth;
    // if(depth.data) {
    //     for(const item of depth.data) {
    //         if(!item.type) {
    //             const {dqc_no, mb_no, mb_name, level9_count, is_first_mb} = item;
    //             const dq_num = parseInt(dqc_no.replace("DQC", ""), 10);
    //             const code = isNaN(dq_num) ? dqc_no : `DQ${dq_num}`
    //             const title = DQ_DEFINITIONS[code] ? DQ_DEFINITIONS[code].title : ""
    //             if (!badges[dqc_no]) badges[dqc_no] = {micro_badges: []}
    //             if (!badges[dqc_no].badge) badges[dqc_no].badge = {code, title, count: coverageMap[dqc_no].count}
    //             badges[dqc_no].micro_badges.push({title: `${mb_no}: ${mb_name}`, count: level9_count})
    //             badges[dqc_no].micro_badges.sort(asciiSortCompareFunWithKey('title'));
    //         }
    //     }
    // }

    if (!firstBadgeInfo && !firstMicroBadgeInfo && badges.length > 0) {
        for (let item of badges) {
            if (item.isBadge) {
                firstBadgeInfo = item.title;
            } else {
                firstMicroBadgeInfo = item.title.replace(":", "");
            }
            if (firstBadgeInfo && firstMicroBadgeInfo) break;
        }
    }

    return <ReportPage pageNumber={pageNumber}>
        <Box id="2.2-analysis-ii:-the-content-is-aligned-with-the-following-dq-gsms-based-on-the-depth-of-the-learning-messages" display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={"flex-start"} height={"inherit"} width={"100%"} px={10}>
            { !onlyTable &&
                <Box>
                    <Typography
                        variant={'h3'}
                        style={{
                            color: "orangered",
                            fontWeight: "lighter",
                            fontFamily: 'Raleway',
                            paddingTop: '70px',
                            fontSize: '22px'
                        }}
                    >
                        2.2	Analysis II: The content is aligned with the following DQ GSMs based on the depth of the learning messages.
                    </Typography>
                    <Typography
                        style={{
                            color: "black",
                            fontWeight: "lighter",
                            fontFamily: 'Source Sans Pro',
                            paddingTop: '30px',
                            fontSize: '18px',
                            textAlign: 'justify'
                        }}
                    >
                        This section analyses the program’s alignment with the DQ GSMs based on the depth of the learning messages identified. The depth of learning messages refers to the extent of content coverage within each DQ GSM. This analysis evaluates the extent to which your content covers specific learning objectives within the DQ 32 competencies. By earning DQ GSMs, we acknowledge the content adequately addresses the learning objectives related to the corresponding points.
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent="center" paddingTop={'30px'} >
                        <Box style={{
                            backgroundColor: '#2396f3',
                            borderRadius: '25px',
                            padding: '16px 32px',
                            maxWidth: '80%',
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }}>
                            <Typography variant="caption" style={{
                                color: 'white',
                                fontFamily: 'Source Sans Pro',
                                fontWeight: 'bold',
                                fontSize: '18px'
                            }}>
                                Depth of learning messages refers to the extent of content coverage within each DQ GSM.
                            </Typography>
                        </Box>
                    </Box>
                    <Typography
                        style={{
                            color: "black",
                            fontWeight: "lighter",
                            fontFamily: 'Source Sans Pro',
                            paddingTop: '30px',
                            fontSize: '18px',
                            textAlign: 'justify'
                        }}
                    >
                        The content is aligned with the following DQ GSMs as listed in the table below.
                        For example, {firstMicroBadgeInfo && `‘${firstMicroBadgeInfo}’ is a DQ GSM for the DQ Competency`} {firstBadgeInfo && `‘${firstBadgeInfo}’.`}
                    </Typography>
                </Box>
            }
            <Box alignSelf={onlyTable ? "flex-start" : "inherit"} paddingTop={onlyTable ? "70px" : "30px"}>
                <Box width="100%">
                    <table className={classes.root} style={{width: "100%"}}>
                        {!onlyTable &&
                            <tr>
                                <th style={{textAlign: 'left'}}>Digital Competency</th>
                                <th># of Messages</th>
                            </tr>
                        }
                        <tbody>
                        {depth.map((item, index) => (
                            <MicroBadgeRow
                                key={index}
                                badge={item.isBadge ? item : null}
                                micro_badge={!item.isBadge ? item : null}
                            />
                        ))}
                        </tbody>
                    </table>
                </Box>
                {isLastPage &&
                    <Typography
                        style={{
                            color: "black",
                            fontWeight: "lighter",
                            fontFamily: 'Source Sans Pro',
                            paddingTop: '15px',
                            fontSize: '18px',
                            textAlign: 'left'
                        }}
                    >
                        A description of each DQ GSM can be found in <a style={{ color: 'blue', textDecoration: 'underline' }} href={`#${'6.2 Appendix B: Description of DQ GSMs mentioned in Analysis II'.toLowerCase().replace(/ /g, '-')}`}>Appendix B</a>.
                    </Typography>
                }
            </Box>
        </Box>
    </ReportPage>
}