export const PROGRAM_VENUE = [
    {
        "value": "Homebased",
        "label": "Home"
    },
    {
        "value": "Schools",
        "label": "School"
    },
    {
        "value": "Companypremises",
        "label": "Company office"
    },
    // {
    //     "value": "Computerlab",
    //     "label": "Computer laboratory"
    // },

    // {
    //     "value": "Mobilephonebased",
    //     "label": "Mobile phone-based"
    // },
    // {
    //     "value": "Outdoorpremises",
    //     "label": "Outdoor premises"
    // },
    // {
    //     "value": "PCMacbased",
    //     "label": "PC/Mac-based"
    // },
    {
        "value": "TrainingCentre",
        "label": "Community center"
    },
    {
        "value": "MuseumExhibition",
        "label": "Museum / Exhibition"
    },
    {
        "value": "Online",
        "label": "Online"
    },
    {
        "value": "Others",
        "label": "Others"
    }
];

export const PROGRAM_VENUE_MAP = {
    "Homebased": {
        "label": "Home"
    },
    "Schools": {
        "label": "School"
    },
    "Companypremises": {
        "label": "Company office"
    },
    // "Computerlab": {
    //     "label": "Computer laboratory"
    // },

    // "Mobilephonebased": {
    //     "label": "Mobile phone-based"
    // },
    // "Outdoorpremises": {
    //     "label": "Outdoor premises"
    // },
    // "PCMacbased": {
    //     "label": "PC/Mac-based"
    // },
    "TrainingCentre": {
        "label": "Community Centre"
    },
    "MuseumExhibition": {
        "label": "Museum / Exhibition"
    },
    "Online": {
        "label": "Online"
    },
    "Others": {
        "label": "Others"
    }
};