import React, {useCallback, useState} from "react";
import {
    Container,
    Typography,
    Box,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Card,
    Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import PoweredbyDQLogo from "../../assets/images/PoweredByDQ_Default.png";
import DQStandardsIcons from "../../assets/images/QAReportIcon.png"
import ProgramDashboard from "../../assets/images/programDashboardIcon.png"
import DQAssessment from "../../assets/images/DQAssessment.png"
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {SEAL_TYPE} from "../../utils/constants";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    instruction: {
        margin: theme.spacing(3, 3, 3, 0),
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'stretch',
        marginTop: theme.spacing(6),
    },
    card: {
        border:'1px solid #052F44',
        backgroundColor: "#F4F4F4",
        borderRadius: "30px",
        // width: "40rem",
        position:'relative',
        paddingBottom: '76px !important',
        flex: 1,
        padding: "0 !important",
        boxShadow: "6px 0px 20px rgba(0, 0, 0, 0.3)",
        margin:'0 25px',
        transition: "transform 0.2s ease-in-out",
        '&:hover': {
            transform: "scale(1.05)",
            cursor: "pointer",
        },
    },
}));

const SelectApplicationTypePage = (props) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const handleNavigate = useCallback((dqseal_type) => {
        history.push('/application/create', { SealType: dqseal_type });
    }, [history]);

    return (
        <Container className={classes.container}>
            <Typography color="inherit" variant="h4" style={{ textAlign: "center", fontWeight: "bold" }}>
                Add Application
            </Typography>
            <Typography className={classes.instruction} variant="body1" align="center">
                <InfoIcon /> Please select which Powered by DQ package you would like for your program.
            </Typography>
            <Grid container className={classes.cardContainer} spacing={5} align="center">
                <Grid item xs={3} md={3} className={classes.card} align="center" onClick={() => handleNavigate(SEAL_TYPE.ALIGN)}>
                    <img src={PoweredbyDQLogo} style={{ width: "40%",}}/>
                    <Typography variant="h4" style={{ backgroundColor: "#1F497D", fontWeight: "500", color: "#FFFFFF", marginTop:'10px' }}>
                        ALIGNED
                    </Typography>

                    <Typography  style={{ margin: "30px 20px 10px 20px", fontSize: "18px"}}>
                        Check to see if your program is aligned to the IEEE DQ Global Standards (IEEE
                        3527.1<sup>TM</sup>)
                    </Typography>
                    <List style={{marginLeft: '10px',marginTop:'-20px', fontSize:'1.2rem'}}>
                        <ListItem style={{marginLeft: '20px', marginTop:'20px',fontSize:'1.2rem'}}>
                            <ListItemText primaryTypographyProps={{fontSize: '18px'}} primary="Includes:"/>
                        </ListItem>
                        <ListItem>
                            <img src={DQStandardsIcons} style={{ width: "20%",}}/>
                            <ListItemText style={{ marginLeft: "17px",fontSize: "18px", marginBottom: '12px'}} primary="DQ Standards Conformity Test" />
                        </ListItem>
                    </List>
                    <Button style={{position:'absolute', bottom:'20px', left: '50%',transform: 'translateX(-50%)', backgroundColor:'#052F44', color:'white', borderRadius:'15px', fontSize:'13px', padding:'12px 24px'}} variant="contained">Select</Button>
                </Grid>

                <Grid item xs={3} md={3} className={classes.card} align="center" onClick={() => handleNavigate(SEAL_TYPE.CERTIFY)}>
                    <img src={PoweredbyDQLogo} style={{ width: "40%" }} />
                    <Typography variant="h4" style={{ backgroundColor: "#FF4D00", fontWeight: "500", color: "#FFFFFF", marginTop:'10px' }}>
                        MEASURED
                    </Typography>
                    <Typography style={{ margin: "30px 20px 10px 20px", fontSize: "18px"}}>
                        Assess learners’ digital literacy levels and monitor program efficacy with a global benchmark
                    </Typography>
                    <List style={{marginLeft:'10px', marginTop:'-20px', fontSize:'1.2rem'}}>
                        <ListItem style={{marginLeft: '20px', marginTop:'20px'}}>
                            <ListItemText>Includes:</ListItemText>
                        </ListItem>
                        <ListItem>
                            <img src={DQStandardsIcons} style={{ width: "20%",}}/>
                            <ListItemText style={{ marginLeft: "17px", fontSize: "18px"}} primary="DQ Standards Conformity Test" />
                        </ListItem>
                        <ListItem style={{marginLeft:'7px'}}>
                            <img src={DQAssessment} style={{ width: "15%",}}/>
                            <ListItemText style={{ marginLeft: "26px", fontSize: "18px"}} primary="Curated DQ Assessment" />
                        </ListItem>
                        <ListItem style={{marginLeft:'7px'}}>
                            <img src={ProgramDashboard} style={{ width: "15%",}}/>
                            <ListItemText style={{ marginLeft: "26px", fontSize: "18px", marginBottom: '12px'}} primary="DQ Program Dashboard" />
                        </ListItem>
                    </List>
                    <Button style={{position:'absolute', bottom:'20px', left: '50%', transform: 'translateX(-50%)',backgroundColor:'#052F44', color:'white', borderRadius:'15px', fontSize:'13px', padding:'12px 24px'}} variant="contained">Select</Button>
                </Grid>
            </Grid>
            <LoaderWithBackDrop loading={loading} />
        </Container>
    );
};

export default SelectApplicationTypePage;