export const ONLINE_SYSTEM = [
    {
        "value": "yes",
        "label": "Delivered via API through your existing website or portal"
    },
    {
        "value": "no",
        "label": "Delivered through DQ’s assessment web-based portal"
    }
];

export const ONLINE_SYSTEM_MAP = {
    "yes": {
        "label": "Delivered via API through your existing website or portal"
    },
    "no":{
        "label": "Delivered through DQ’s assessment web-based portal"
    }
};