export const ORG_TYPES = [
    {
        "value": "EdTech",
        "label": "EdTech"
    },
    {
        "value": "GovAgency",
        "label": "Government agency"
    },
    {
        "value": "MNC",
        "label": "Multi-National Company (MNC)"
    },
    {
        "value": "NGO",
        "label": "Non-Governmental Organization (NGO)"
    },
    {
        "value": "ResInst",
        "label": "Research institute"
    },
    {
        "value": "SME",
        "label": "Small-Medium Enterprise (SME)"
    },
    {
        "value": "SocEnt",
        "label": "Social enterprise"
    },
    {
        "value": "Startup",
        "label": "Startup"
    },
    {
        "value": "Uni",
        "label": "University"
    },
    {
        "value": "other",
        "label": "Other"
    }
];

export const ORG_TYPES_MAP = {
    "EdTech": {
        "label": "EdTech"
    },
    "GovAgency": {
        "label": "Government agency"
    },
    "MNC": {
        "label": "Multi-National Company (MNC)"
    },
    "NGO": {
        "label": "Non-Governmental Organization (NGO)"
    },
    "ResInst": {
        "label": "Research institute"
    },
    "SME": {
        "label": "Small-Medium Enterprise (SME)"
    },
    "SocEnt": {
        "label": "Social enterprise"
    },
    "Startup": {
        "label": "Startup"
    },
    "Uni": {
        "label": "University"
    },
    "other": {
        "label": "Other"
    }
}