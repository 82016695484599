import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import {
    Card, CardContent, Accordion, AccordionSummary, AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";
import {Copyright} from '../../../components';
import { useFormik } from 'formik';
import {loginUser} from '../../../services/auth.service';
import { useAuth } from "../../../context/auth";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {LOCAL_STORAGE_KEYS} from "../../../utils/constants";
import PoweredbyDQ from "../../../assets/images/PoweredByDQ_Default.png"
import DQLabLogo from "../../../assets/images/DQLAB_logo.png"
import RegisterIcon from "../../../assets/images/register.png"
import MappingIcon from "../../../assets/images/contentMapping.png"
import ReportIcon from "../../../assets/images/report.png"
import CertifiedIcon from "../../../assets/images/certified.png"
import VisualizeIcon from "../../../assets/images/visulaize-impacts.png"
import 'aos/dist/aos.css'
import AOS from 'aos'

const useStyles = makeStyles((theme) => ({
    textField: {
        "& .MuiFilledInput-root": {
            backgroundColor: '#BDE3E3',
            borderRadius: '30px !important',
            "& fieldset": {
                borderRadius: '30px !important',
            },
        },
        "& .MuiFilledInput-root.Mui-focused": {
            backgroundColor: '#BDE3E3',
        },
        '& .MuiInputLabel-root': {
            color: '#023F5F',
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: '#023F5F',
        },
        '& .MuiOutlinedInput-input': {
            color: '#023F5F',
        },
    },
    paper: {
        background: theme.palette.background.default,
        color: theme.palette.loginPage.text,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent:'center',
        textAlign:'center',
        fontFamily:'Surt',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '90%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        color:theme.palette.background.default,
        fontFamily:'Surt',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        background: theme.palette.loginPage.text,
        color:  theme.palette.background.default,
        width:'100%',
        fontSize: '21px',
        '&:hover':{
            background: theme.palette.loginPage.text,
            color: theme.palette.background.default,
        }
    },
    section1: {
        height:'80%',
        fontFamily:'Surt',
        '@media (min-width: 768px) and (max-width: 960px)': {
            height:'75%',
        },
        '@media (min-width: 960px) and (max-width: 1024px)': {
            height:'40%',
        },
    },
    section2: {
        background: theme.palette.loginPage.background,
        paddingBottom:'40px',
        paddingTop:'60px',
        color:  theme.palette.loginPage.text,
        textAlign:'center',
        width:'100vw',
        alignItems: 'center',
        display:'flex',
        justifyContent:'center',
        fontFamily:'Surt',
    },
    section3:{
        background:theme.palette.background.default,
        color:  theme.palette.loginPage.text,
        textAlign:'center',
        alignItems: 'center',
        display:'flex',
        fontFamily:'Surt',
        flexDirection:'column',
        paddingBottom:'30px',
        paddingTop:'40px',
    },
    formBackground: {
        background: theme.palette.loginPage.text,
        padding: '20px',
    },
    cardContainer: {
        display: 'flex',
        // flexWrap:'nowrap',
        gridTemplateColumns: 'repeat(auto-fit, minmax(min(15rem, 100%), 1fr))',
        fontFamily: 'Surt',
        justifyItems:'center',
        '@media (min-width: 768px) and (max-width: 960px)': {
            display: 'grid',
        },
        '@media (min-width: 960px) and (max-width: 1024px)': {
            display: 'grid',
        },
    },
    card: {
        display: 'block',
        justifyContent: 'center',
        backgroundColor:'#FFFFF',
        borderRadius:'20px',
        margin:'15px',
        marginBottom: '20% !important',
        paddingTop:'2em !important',
    },
    accordionContainer:{
        width:'85%',
        maxWidth:'100vw',
        padding:'30px',
        marginTop:'25px',
        textAlign:'left'
    },
    accordionTab: {
        background: 'transparent',
        color: theme.palette.loginPage.text,
    },
    iconWrapper: {
        position: 'relative',
        display: 'inline-block',
        width: '40%',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '90px',
            height: '90px',
            borderRadius: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
        },
    },
    iconImage: {
        width: '67%',
        marginTop:'15px',
        zIndex: 2,
        position: 'relative',
    },
}));

const Login = (props) => {
    // const [isLoggedIn, setLoggedIn] = useState(false);
    const classes = useStyles();

    const { setAuthToken, setAuthUser } = useAuth();
    const referer = props.location.referer || '/home';
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            remember_me: false
        },
        onSubmit: async (values) => {
            console.log('Form data => ', values);
            try {
                setLoading(true);
                const result = await loginUser(values.email, values.password, values.remember_me);
                console.log('result.data -> ', result.data);
                if (result.data && result.data.user) {
                    await setAuthToken(result.data.auth_token);
                    await setAuthUser(result.data.user);
                    localStorage.setItem(LOCAL_STORAGE_KEYS.refresh_token, JSON.stringify(result.data.refresh_token));
                    localStorage.setItem(LOCAL_STORAGE_KEYS.user_email, JSON.stringify(result.data.user.email));
                    return <Redirect to={referer} />;
                    // setLoggedIn(true);
                }
                setLoading(false);
            } catch (e) {
                // already toasted the error
                setLoading(false);
            }
        }
    });

    useEffect(() => {
        AOS.init({
            duration: 500,
        });
    }, []);

    // if (isLoggedIn) {
    //     return <Redirect to={referer} />;
    // }
    const isMobile = window.innerWidth < 768;
    const isIpads = window.innerWidth >= 768 && window.innerWidth <= 960;
    const isTablet = window.innerWidth >= 960 && window.innerWidth <= 1024;
    const isLaptop=window.innerWidth >= 1200 && window.innerWidth <= 1470;
    return (
        <>
            <div>
                <Grid container className={classes.section1} data-aos="fade-in" data-aos-delay='100'>
                    <Grid item xs={12} md={12}  mt={6} style={{backgroundColor:'white'}}>
                        <Container component="main" maxWidth="sm">
                            <div className={classes.paper} style={{
                                marginTop: '40px',
                                paddingBottom: '50px',
                            }}>
                                <img src={PoweredbyDQ} alt="Image"
                                     style={{
                                         width:'300px',
                                         cursor: 'pointer'
                                     }}/>
                                <form className={classes.form} noValidate onSubmit={formik.handleSubmit}
                                      style={{fontFamily: 'Surt'}}>
                                    <TextField
                                        className={classes.textField}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        autoFocus
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                    />
                                    <TextField
                                        className={classes.textField}
                                        style={{borderRadius:'30px'}}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                        onChange={formik.handleChange}
                                        value={formik.values.password}
                                    />
                                    <Grid container>
                                        <FormControlLabel
                                            control={<Checkbox
                                                name="remember_me"
                                                id="remember_me"
                                                value={formik.values.remember_me}
                                                onChange={formik.handleChange}
                                            />}
                                            label="Remember me"
                                            style={{color: '#023F5F', fontFamily: 'Surt'}}
                                        />
                                        <Grid item xs style={{
                                            textAlign: 'right',
                                            paddingTop: '2%',
                                            fontFamily: 'Surt',
                                            color: '#023F5F'
                                        }}>
                                            <Link href="/account/recover" variant="body2">
                                                Forgot password?
                                            </Link>
                                        </Grid>
                                    </Grid>
                                    <Typography align='center'>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                        >
                                            Sign In
                                        </Button>
                                    </Typography>

                                    <Grid item style={{textAlign: 'center', fontFamily: 'Surt', color: '#023F5F'}}>
                                        <Link href="/register" variant="body2">
                                            {"Don't have an account? Sign Up"}
                                        </Link>
                                    </Grid>

                                </form>
                            </div>

                            <LoaderWithBackDrop loading={loading}/>
                        </Container>
                    </Grid>
                </Grid>

                <Grid container className={classes.section2}>
                    <Grid item xs={12} data-aos="fade-up" data-aos-delay="250">
                        <Typography variant="h3" gutterBottom style={{fontWeight:'bold', fontFamily:'Surt',color:'#023F5F', fontSize: isMobile ? '1.7em' : '3em'}}>
                            How it Works
                        </Typography>
                        <Typography variant="h5" gutterBottom style={{padding:'10px 20px 50px 20px ',fontFamily:'Surt', fontSize: isMobile ? '1.2em' : '1.5em'}}>
                            The following is a typical process of getting Powered by DQ. Depending on your needs, <br />the process can take from around 1-4 weeks.
                        </Typography>
                    </Grid>
                    <Grid container className={classes.cardContainer} style={{padding: isMobile ? '0px' : isLaptop ? '0 4%' : '0 10%', flexWrap: isMobile ? 'wrap' : 'nowrap' , justifyContent: 'center'}}>
                        <Grid item xs={9} sm={12} md={12} >
                            <Card className={classes.card}  data-aos="fade-in" data-aos-offset="100" style={{
                                height: isMobile ? 'fit-content' : isTablet ? '570px' : isIpads ? '570px' :'600px', position:'relative',
                                paddingBottom: isMobile ? '15px' : '0px',
                            }}>
                                <CardContent>
                                    <Grid item xs={12} md={12}>
                                        <div className={classes.iconWrapper} style={{backgroundColor:'#D94544', borderRadius:'50%', height:'90px', width:'90px'}}>
                                            <img src={RegisterIcon} alt="Image" className={classes.iconImage} style={{width: '60%', marginTop:'18px', marginLeft:'5px'}}/>
                                        </div>
                                    </Grid>
                                    <Typography variant="h4" gutterBottom style={{paddingTop:'11%',fontFamily:'Surt',fontSize: isMobile ? '1.2em' : isTablet ? '1.6em' : '1.7em',}}>
                                        Step 1:
                                    </Typography>
                                    <Typography variant="h5" gutterBottom style={{ fontFamily:'Surt',fontSize: isMobile ? '1em' : isTablet ? '1.2em' : '1.3em',}}>
                                        Register
                                    </Typography>
                                    <Typography style={{padding:'4%', left:'0', marginTop:'25px',
                                        fontStyle:'italic', fontSize:isMobile ? '14px' :  isTablet ? '19px' : '19px',fontFamily:'Surt'}}
                                    >
                                        Sign up and make payment to start the application process.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={9} sm={12} md={12} >
                            <Card className={classes.card}  data-aos="fade-in" data-aos-offset="200" style={{
                                height:  isMobile ? 'fit-content' : isTablet ? '570px' : isIpads ? '570px' :'600px', position:'relative',
                                paddingBottom: isMobile ? '15px' : '0px',
                            }} >
                                <CardContent >
                                    <Grid item xs={12} md={12}>
                                        <Box className={classes.iconWrapper} style={{backgroundColor:'#EF9632', borderRadius:'50%', height:'90px', width:'90px'}}>
                                            <img src={MappingIcon} alt="Image" className={classes.iconImage}/>
                                        </Box>
                                    </Grid>
                                    <Typography variant="h4" gutterBottom style={{marginTop:'8%',fontFamily:'Surt', fontSize: isMobile ? '1.2em' : isTablet ? '1.6em' : '1.7em',}}>
                                        Step 2:
                                    </Typography>
                                    <Typography variant="h5" gutterBottom style={{ fontFamily:'Surt',fontSize: isMobile ? '1em' : isTablet ? '1.2em' : '1.3em',}}>
                                        Submit Program
                                    </Typography>
                                    <Typography style={{padding:'4%',marginTop:'25px', left:'0', fontStyle:'italic', fontSize:isMobile ? '14px' :  isTablet ? '19px' : '19px',fontFamily:'Surt'}}>
                                        Conformity test assessing the alignment of your digital literacy program to the IEEE DQ Global Standards  (IEEE
                                        3527.1<sup>TM</sup>)
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={9} sm={12} md={12} >
                            <Card className={classes.card}  data-aos="fade-in" data-aos-offset="350" style={{
                                height: isMobile ? 'fit-content' : isTablet ? '570px' : isIpads ? '570px' :'600px', position:'relative',
                                paddingBottom: isMobile ? '15px' : '0px',
                            }}>
                                <CardContent>
                                    <Grid item xs={12} md={12}>
                                        <Box className={classes.iconWrapper} style={{backgroundColor:'#A4AF4E', borderRadius:'50%', height:'90px', width:'90px'}}>
                                            <img src={RegisterIcon} alt="Image" className={classes.iconImage} style={{width: '60%',marginTop:'18px', marginLeft:'5px'}}/>
                                        </Box>
                                    </Grid>
                                    <Typography variant="h4" gutterBottom style={{paddingTop:'8%',fontFamily:'Surt',fontSize: isMobile ? '1.2em' : isTablet ? '1.6em' : '1.7em',}}>
                                        Step 3:
                                    </Typography>
                                    <Typography variant="h5" gutterBottom style={{ fontFamily:'Surt',fontSize: isMobile ? '1em' : isTablet ? '1.2em' : '1.3em',}}>
                                        Get Report
                                    </Typography>
                                    <Typography style={{padding:'4%', marginTop:'25px', height:isMobile? '130px' : isIpads ? '178px' : '178px', fontStyle:'italic', fontSize:isMobile ? '14px' :  isTablet ? '19px' : '19px',fontFamily:'Surt'}}
                                    >
                                        Get a report showcasing what DQ competencies your program aligns with and what areas can be enhanced.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={9} sm={12} md={12} >
                            <Card className={classes.card}  data-aos="fade-in" data-aos-offset="450" style={{
                                height: isMobile ? 'fit-content' : isTablet ? '570px' : isIpads ? '570px' :'600px', position:'relative',
                                paddingBottom: isMobile ? '15px' : '0px',
                            }}>
                                <CardContent>
                                    <Grid item xs={12} md={12}>
                                        <Box className={classes.iconWrapper} style={{backgroundColor:'#53BDC0', borderRadius:'50%', height:'90px', width:'90px'}}>
                                            <img src={CertifiedIcon} alt="Image" className={classes.iconImage} style={{width: '60%',}}/>
                                        </Box>
                                    </Grid>
                                    <Typography variant="h4" gutterBottom style={{marginTop:'7%',fontFamily:'Surt',fontSize: isMobile ? '1.2em' : isTablet ? '1.6em' : '1.7em',}}>
                                        Step 4:
                                    </Typography>
                                    <Typography variant="h5" gutterBottom style={{ fontFamily:'Surt',fontSize: isMobile ? '1em' : isTablet ? '1.2em' : '1.3em',}}>
                                        Add Assessment
                                    </Typography>
                                    <Typography style={{padding:'4%', fontStyle:'italic', fontSize:isMobile ? '14px' :  isTablet ? '19px' : '19px',fontFamily:'Surt', marginTop:'25px'}}>
                                        Get a custom assessment based on your program’s content alignment for your users to test their skills before and after going through your program.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={9} sm={12} md={12} >
                            <Card className={classes.card} data-aos="fade-in" data-aos-offset="550" style={{
                                height:  isMobile ? 'fit-content' : isTablet ? '570px' : isIpads ? '570px' :'600px', position:'relative',
                                paddingBottom: isMobile ? '15px' : '0px',
                            }}>
                                <CardContent>
                                    <Grid item xs={12} md={12}>
                                        <Box className={classes.iconWrapper} style={{backgroundColor:'#0D7172', borderRadius:'50%', height:'90px', width:'90px'}}>
                                            <img src={VisualizeIcon} alt="Image" className={classes.iconImage}/>
                                        </Box>
                                    </Grid>
                                    <Typography variant="h4" gutterBottom
                                                style={{marginTop: '10%', fontFamily: 'Surt', fontSize: isMobile ? '1.2em' : isTablet ? '1.6em' : '1.7em',}}>
                                        Step 5:
                                    </Typography>
                                    <Typography variant="h5" gutterBottom style={{ fontFamily:'Surt',fontSize: isMobile ? '1em' : isTablet ? '1.2em' : '1.3em',}}>
                                        Visualize Impact
                                    </Typography>
                                    <Typography style={{padding:'4%', fontStyle:'italic',marginTop:'25px', fontSize:isMobile ? '14px' :  isTablet ? '19px' : '19px',fontFamily:'Surt'}}>
                                        Get a custom dashboard to see the efficacy and impact of your program by gender, school, class, age, and more!
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.section3}>
                    <Grid item md={12} xs={9} data-aos-offset="100" data-aos="fade-up">
                        <Typography variant="h3" gutterBottom style={{fontFamily:'SurtBold',fontSize: isMobile ? '1.7em' : '3em' }}>
                            Frequently Asked Questions
                        </Typography>
                    </Grid>
                    <div className={classes.accordionContainer} style={{padding: isMobile ? '0px' : '30px',}} data-aos-offset="100" data-aos="fade-up">
                        <Accordion className={classes.accordionTab} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography variant="h6" style={{fontWeight:'bold',fontFamily:'Surt', fontSize: isMobile ? '1em' : '1.25em'}}>How do I start getting Powered by DQ?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography style={{color:'#0A898B',fontFamily:'Surt'}}>
                                    <ul>
                                        <li>
                                            Decide whether you want to be <b>DQ Aligned </b>, <b>DQ Measured</b>, or <b>DQ
                                            Enhanced</b>. Once you have decided, please sign up and submit your
                                            application. Our team will reach out to you with the next steps to be
                                            completed. If you want to get <b>DQ Enhanced </b>, please contact us at <a
                                            href="mailto:contact@dqforall.com"> contact@dqforall.com.</a>
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionTab}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="h6" style={{fontWeight:'bold',fontFamily:'Surt', fontSize: isMobile ? '1em' : '1.25em'}}>How long does it take to get Powered by DQ?</Typography>
                            </AccordionSummary>
                            <AccordionDetails >
                                <Typography style={{color:'#0A898B',fontFamily:'Surt'}}>
                                    <ul>
                                        <li>
                                            <b>DQ Aligned</b> can take as little as 3 days, provided you have everything ready.
                                        </li>
                                        <li>
                                            <b>DQ Measured</b> and <b>DQ Enhanced</b> can take a week or longer depending on your program's complexity. Generally, it does not take longer than a month to get <b>DQ Measured</b>.
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionTab}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="h6" style={{fontWeight:'bold',fontFamily:'Surt', fontSize: isMobile ? '1em' : '1.25em'}}>How much does it cost?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography style={{color:'#0A898B',fontFamily:'Surt'}}>
                                    <ul>
                                        <li>
                                            <b>DQ Aligned</b> costs $2,500 USD for a typical program that takes under 5 hours to complete. For programs exceeding 5 hours, an extra $500 USD per hour of content will be applied.
                                        </li>
                                        <li>
                                            <b>DQ Measured</b> depends on how many users you intend to have assessments with. You can contact us at <a href="mailto:contact@dqforall.com">contact@dqforall.com</a> for more information.
                                        </li>
                                        <li>
                                            <b>DQ Enhanced</b> depends on the amount of time and content needed to curate your program/framework.
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionTab}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="h6" style={{fontWeight:'bold', fontFamily:'Surt', fontSize: isMobile ? '1em' : '1.25em'}}>How do I make payment? What are the payment options?</Typography>
                            </AccordionSummary>
                            <AccordionDetails >
                                <Typography style={{color:'#0A898B',fontFamily:'Surt'}}>
                                    <ul>
                                        <li>
                                            You can make payment via credit card or work with our finance team to pay via international wire transfers.
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionTab}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="h6" style={{fontWeight:'bold', fontFamily:'Surt', fontSize: isMobile ? '1em' : '1.25em'}}>Is Powered by DQ available in other languages? Can I upload content in other languages?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography style={{color:'#0A898B',fontFamily:'Surt'}}>
                                    <ul>
                                        <li>
                                            Currently, our reports, and communications are only in English. However, our assessments and analytic tools may be available in other languages. Please inquire for more details.
                                        </li>
                                        <li>
                                            Therefore, please ensure all program content uploaded to our platform is translated into English.
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionTab}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="h6" style={{fontWeight:'bold', fontFamily:'Surt', fontSize: isMobile ? '1em' : '1.25em'}}>What if I want a custom assessment?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography style={{color:'#0A898B',fontFamily:'Surt'}}>
                                    <ul>
                                        <li>
                                            We will first prepare the assessment based on the DQ Standards Conformity Test results. Afterward, we welcome you to share your needs to further customize the Assessment further.
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionTab}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="h6" style={{fontWeight:'bold', fontFamily:'Surt', fontSize: isMobile ? '1em' : '1.25em'}}>If I build an assessment, how do my users access it?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography style={{color:'#0A898B',fontFamily:'Surt'}}>
                                    <ul>
                                        <li>
                                            Our team provides flexibility for program providers to either 1) access your newly built assessment on our mobile-friendly website or 2) integrate an API version to be connected with your platform. We also provide technical support if needed as well.
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionTab}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="h6" style={{fontWeight:'bold',fontFamily:'Surt', fontSize: isMobile ? '1em' : '1.25em'}}>What if my program is not digital skills related?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography style={{color:'#0A898B',fontFamily:'Surt'}}>
                                    <ul>
                                        <li>
                                            We focus primarily on programs that are related to digital citizenship, digital literacy, digital wellbeing, digital readiness, and digital skills. We do not align programs like history, science, arts, math, physical education, etc. at this time.
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionTab}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="h6" style={{fontWeight:'bold',fontFamily:'Surt', fontSize: isMobile ? '1em' : '1.25em'}}>If I just want a DQ Assessment do I need to get Powered by DQ?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography style={{color:'#0A898B',fontFamily:'Surt'}}>
                                    <ul>
                                        <li>
                                            No need. You may contact us to purchase licenses and deploy the standardized DQ Assessment immediately.

                                        </li>
                                        <li>
                                            However, if you would like to customize the Assessment, you will need to first go through the DQ Standards Conformity Test and get Powered by DQ.
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionTab}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="h6" style={{fontWeight:'bold',fontFamily:'Surt', fontSize: isMobile ? '1em' : '1.25em'}}>Are my program materials and company information protected and safe on your platform?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography style={{color:'#0A898B',fontFamily:'Surt'}}>
                                    <ul>
                                        <li>
                                            Yes, we take our partners’ IP, data, and privacy seriously. You can be assured that your information will be secured and never released or visible to anyone.
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <Box mt={7}>
                        <Copyright />
                    </Box>
                </Grid>

            </div>
        </>
    );
}

export default withRouter(Login);