import { createTheme }  from '@material-ui/core/styles'
const theme = createTheme({
    palette: {
        primary: {
            main: '#052F44',
        },
        secondary: {
            main: '#A3A4A5',
        },
        error: {
            main: '#f44336',
        },
        warning: {
            main: '#ff9800',
        },
        info: {
            main: '#2196f3',
        },
        success: {
            main: '#4caf50',
        },
        loginPage:{
            text: '#023F5F',
            background: '#A0D0D1',
        },
        // Custom additional colors
        // You can adjust these to your preference
        text: {
            primary: '#052F44',
            secondary: '#11888B',
        },
        background: {
            default: '#ffffff',
            paper: '#F4F4F4',
            cards: '#F4F4F4',
        },
    },
})
export default theme