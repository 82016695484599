export const REGIONS = [
    {
        "value": "Afghanistan",
        "label": "Afghanistan"
    },
    {
        "value": "Albania",
        "label": "Albania"
    },
    {
        "value": "Algeria",
        "label": "Algeria"
    },
    {
        "value": "Andorra",
        "label": "Andorra"
    },
    {
        "value": "Angola",
        "label": "Angola"
    },
    {
        "value": "AntiguaBarbuda",
        "label": "Antigua and Barbuda"
    },
    {
        "value": "Argentina",
        "label": "Argentina"
    },
    {
        "value": "Armenia",
        "label": "Armenia"
    },
    {
        "value": "Australia",
        "label": "Australia"
    },
    {
        "value": "Austria",
        "label": "Austria"
    },
    {
        "value": "Azerbaijan",
        "label": "Azerbaijan"
    },
    {
        "value": "Bahamas",
        "label": "The Bahamas"
    },
    {
        "value": "Bahrain",
        "label": "Bahrain"
    },
    {
        "value": "Bangladesh",
        "label": "Bangladesh"
    },
    {
        "value": "Barbados",
        "label": "Barbados"
    },
    {
        "value": "Belarus",
        "label": "Belarus"
    },
    {
        "value": "Belgium",
        "label": "Belgium"
    },
    {
        "value": "Belize",
        "label": "Belize"
    },
    {
        "value": "Benin",
        "label": "Benin"
    },
    {
        "value": "Bhutan",
        "label": "Bhutan"
    },
    {
        "value": "Bolivia",
        "label": "Bolivia"
    },
    {
        "value": "BosniaHerzegovina",
        "label": "Bosnia and Herzegovina"
    },
    {
        "value": "Botswana",
        "label": "Botswana"
    },
    {
        "value": "Brazil",
        "label": "Brazil"
    },
    {
        "value": "Brunei",
        "label": "Brunei"
    },
    {
        "value": "Bulgaria",
        "label": "Bulgaria"
    },
    {
        "value": "BurkinaFaso",
        "label": "Burkina Faso"
    },
    {
        "value": "Burundi",
        "label": "Burundi"
    },
    {
        "value": "Cambodia",
        "label": "Cambodia"
    },
    {
        "value": "Cameroon",
        "label": "Cameroon"
    },
    {
        "value": "Canada",
        "label": "Canada"
    },
    {
        "value": "CapeVerde",
        "label": "Cape Verde"
    },
    {
        "value": "CentralAfricanRepublic",
        "label": "Central African Republic"
    },
    {
        "value": "Chad",
        "label": "Chad"
    },
    {
        "value": "Chile",
        "label": "Chile"
    },
    {
        "value": "China",
        "label": "China"
    },
    {
        "value": "Colombia",
        "label": "Colombia"
    },
    {
        "value": "Comoros",
        "label": "Comoros"
    },
    {
        "value": "RepublicoftheCongo",
        "label": "Congo, Republic of the"
    },
    {
        "value": "DCR",
        "label": "Congo, Democratic Republic of the"
    },
    {
        "value": "CostaRica",
        "label": "Costa Rica"
    },
    {
        "value": "CotedIvoire",
        "label": "Cote d'Ivoire"
    },
    {
        "value": "Croatia",
        "label": "Croatia"
    },
    {
        "value": "Cuba",
        "label": "Cuba"
    },
    {
        "value": "Cyprus",
        "label": "Cyprus"
    },
    {
        "value": "CzechRepublic",
        "label": "Czech Republic"
    },
    {
        "value": "Denmark",
        "label": "Denmark"
    },
    {
        "value": "Djibouti",
        "label": "Djibouti"
    },
    {
        "value": "Dominica",
        "label": "Dominica"
    },
    {
        "value": "DominicanRepublic",
        "label": "Dominican Republic"
    },
    {
        "value": "EastTimor",
        "label": "East Timor (Timor-Leste)"
    },
    {
        "value": "Ecuador",
        "label": "Ecuador"
    },
    {
        "value": "Egypt",
        "label": "Egypt"
    },
    {
        "value": "ElSalvador",
        "label": "El Salvador"
    },
    {
        "value": "EquatorialGuinea",
        "label": "Equatorial Guinea"
    },
    {
        "value": "Eritrea",
        "label": "Eritrea"
    },
    {
        "value": "Estonia",
        "label": "Estonia"
    },
    {
        "value": "Ethiopia",
        "label": "Ethiopia"
    },
    {
        "value": "Fiji",
        "label": "Fiji"
    },
    {
        "value": "Finland",
        "label": "Finland"
    },
    {
        "value": "France",
        "label": "France"
    },
    {
        "value": "Gabon",
        "label": "Gabon"
    },
    {
        "value": "Gambia",
        "label": "The Gambia"
    },
    {
        "value": "Georgia",
        "label": "Georgia"
    },
    {
        "value": "Germany",
        "label": "Germany"
    },
    {
        "value": "Ghana",
        "label": "Ghana"
    },
    {
        "value": "Greece",
        "label": "Greece"
    },
    {
        "value": "Grenada",
        "label": "Grenada"
    },
    {
        "value": "Guatemala",
        "label": "Guatemala"
    },
    {
        "value": "Guinea",
        "label": "Guinea"
    },
    {
        "value": "GuineaBissau",
        "label": "Guinea-Bissau"
    },
    {
        "value": "Guyana",
        "label": "Guyana"
    },
    {
        "value": "Haiti",
        "label": "Haiti"
    },
    {
        "value": "Honduras",
        "label": "Honduras"
    },
    {
        "value": "Hungary",
        "label": "Hungary"
    },
    {
        "value": "Iceland",
        "label": "Iceland"
    },
    {
        "value": "India",
        "label": "India"
    },
    {
        "value": "Indonesia",
        "label": "Indonesia"
    },
    {
        "value": "Iran",
        "label": "Iran"
    },
    {
        "value": "Iraq",
        "label": "Iraq"
    },
    {
        "value": "Ireland",
        "label": "Ireland"
    },
    {
        "value": "Israel",
        "label": "Israel"
    },
    {
        "value": "Italy",
        "label": "Italy"
    },
    {
        "value": "Jamaica",
        "label": "Jamaica"
    },
    {
        "value": "Japan",
        "label": "Japan"
    },
    {
        "value": "Jordan",
        "label": "Jordan"
    },
    {
        "value": "Kazakhstan",
        "label": "Kazakhstan"
    },
    {
        "value": "Kenya",
        "label": "Kenya"
    },
    {
        "value": "Kiribati",
        "label": "Kiribati"
    },
    {
        "value": "NorthKorea",
        "label": "Korea, North"
    },
    {
        "value": "SouthKorea",
        "label": "Korea, South"
    },
    {
        "value": "Kosovo",
        "label": "Kosovo"
    },
    {
        "value": "Kuwait",
        "label": "Kuwait"
    },
    {
        "value": "Kyrgyzstan",
        "label": "Kyrgyzstan"
    },
    {
        "value": "Laos",
        "label": "Laos"
    },
    {
        "value": "Latvia",
        "label": "Latvia"
    },
    {
        "value": "Lebanon",
        "label": "Lebanon"
    },
    {
        "value": "Lesotho",
        "label": "Lesotho"
    },
    {
        "value": "Liberia",
        "label": "Liberia"
    },
    {
        "value": "Libya",
        "label": "Libya"
    },
    {
        "value": "Liechtenstein",
        "label": "Liechtenstein"
    },
    {
        "value": "Lithuania",
        "label": "Lithuania"
    },
    {
        "value": "Luxembourg",
        "label": "Luxembourg"
    },
    {
        "value": "Macedonia",
        "label": "Macedonia"
    },
    {
        "value": "Madagascar",
        "label": "Madagascar"
    },
    {
        "value": "Malawi",
        "label": "Malawi"
    },
    {
        "value": "Malaysia",
        "label": "Malaysia"
    },
    {
        "value": "Maldives",
        "label": "Maldives"
    },
    {
        "value": "Mali",
        "label": "Mali"
    },
    {
        "value": "Malta",
        "label": "Malta"
    },
    {
        "value": "MarshallIslands",
        "label": "Marshall Islands"
    },
    {
        "value": "Mauritania",
        "label": "Mauritania"
    },
    {
        "value": "Mauritius",
        "label": "Mauritius"
    },
    {
        "value": "Mexico",
        "label": "Mexico"
    },
    {
        "value": "Micronesia",
        "label": "Micronesia, Federated States of"
    },
    {
        "value": "Moldova",
        "label": "Moldova"
    },
    {
        "value": "Monaco",
        "label": "Monaco"
    },
    {
        "value": "Mongolia",
        "label": "Mongolia"
    },
    {
        "value": "Montenegro",
        "label": "Montenegro"
    },
    {
        "value": "Morocco",
        "label": "Morocco"
    },
    {
        "value": "Mozambique",
        "label": "Mozambique"
    },
    {
        "value": "Myanmar",
        "label": "Myanmar (Burma)"
    },
    {
        "value": "Namibia",
        "label": "Namibia"
    },
    {
        "value": "Nauru",
        "label": "Nauru"
    },
    {
        "value": "Nepal",
        "label": "Nepal"
    },
    {
        "value": "Netherlands",
        "label": "Netherlands"
    },
    {
        "value": "New Zealand",
        "label": "New Zealand"
    },
    {
        "value": "Nicaragua",
        "label": "Nicaragua"
    },
    {
        "value": "Niger",
        "label": "Niger"
    },
    {
        "value": "Nigeria",
        "label": "Nigeria"
    },
    {
        "value": "Norway",
        "label": "Norway"
    },
    {
        "value": "Oman",
        "label": "Oman"
    },
    {
        "value": "Pakistan",
        "label": "Pakistan"
    },
    {
        "value": "Palau",
        "label": "Palau"
    },
    {
        "value": "Panama",
        "label": "Panama"
    },
    {
        "value": "PapuaNewGuinea",
        "label": "Papua New Guinea"
    },
    {
        "value": "Paraguay",
        "label": "Paraguay"
    },
    {
        "value": "Peru",
        "label": "Peru"
    },
    {
        "value": "Philippines",
        "label": "Philippines"
    },
    {
        "value": "Poland",
        "label": "Poland"
    },
    {
        "value": "Portugal",
        "label": "Portugal"
    },
    {
        "value": "Qatar",
        "label": "Qatar"
    },
    {
        "value": "Romania",
        "label": "Romania"
    },
    {
        "value": "Russia",
        "label": "Russia"
    },
    {
        "value": "Rwanda",
        "label": "Rwanda"
    },
    {
        "value": "StKittsNevis",
        "label": "Saint Kitts and Nevis"
    },
    {
        "value": "SaintLucia",
        "label": "Saint Lucia"
    },
    {
        "value": "StVincent&Grenadines",
        "label": "Saint Vincent and the Grenadines"
    },
    {
        "value": "Samoa",
        "label": "Samoa"
    },
    {
        "value": "SanMarino",
        "label": "San Marino"
    },
    {
        "value": "SaoTomePrincipe",
        "label": "Sao Tome and Principe"
    },
    {
        "value": "SaudiArabia",
        "label": "Saudi Arabia"
    },
    {
        "value": "Senegal",
        "label": "Senegal"
    },
    {
        "value": "Serbia",
        "label": "Serbia"
    },
    {
        "value": "Seychelles",
        "label": "Seychelles"
    },
    {
        "value": "SierraLeone",
        "label": "Sierra Leone"
    },
    {
        "value": "Singapore",
        "label": "Singapore"
    },
    {
        "value": "Slovakia",
        "label": "Slovakia"
    },
    {
        "value": "Slovenia",
        "label": "Slovenia"
    },
    {
        "value": "SolomonIslands",
        "label": "Solomon Islands"
    },
    {
        "value": "Somalia",
        "label": "Somalia"
    },
    {
        "value": "SouthAfrica",
        "label": "South Africa"
    },
    {
        "value": "SouthSudan",
        "label": "South Sudan"
    },
    {
        "value": "Spain",
        "label": "Spain"
    },
    {
        "value": "SriLanka",
        "label": "Sri Lanka"
    },
    {
        "value": "Sudan",
        "label": "Sudan"
    },
    {
        "value": "Suriname",
        "label": "Suriname"
    },
    {
        "value": "Swaziland",
        "label": "Swaziland"
    },
    {
        "value": "Sweden",
        "label": "Sweden"
    },
    {
        "value": "Switzerland",
        "label": "Switzerland"
    },
    {
        "value": "Syria",
        "label": "Syria"
    },
    {
        "value": "Taiwan",
        "label": "Taiwan"
    },
    {
        "value": "Tajikistan",
        "label": "Tajikistan"
    },
    {
        "value": "Tanzania",
        "label": "Tanzania"
    },
    {
        "value": "Thailand",
        "label": "Thailand"
    },
    {
        "value": "Togo",
        "label": "Togo"
    },
    {
        "value": "Tonga",
        "label": "Tonga"
    },
    {
        "value": "TrinidadTobago",
        "label": "Trinidad and Tobago"
    },
    {
        "value": "Tunisia",
        "label": "Tunisia"
    },
    {
        "value": "Turkey",
        "label": "Turkey"
    },
    {
        "value": "Turkmenistan",
        "label": "Turkmenistan"
    },
    {
        "value": "Tuvalu",
        "label": "Tuvalu"
    },
    {
        "value": "Uganda",
        "label": "Uganda"
    },
    {
        "value": "Ukraine",
        "label": "Ukraine"
    },
    {
        "value": "UAE",
        "label": "United Arab Emirates"
    },
    {
        "value": "Uk",
        "label": "United Kingdom"
    },
    {
        "value": "USA",
        "label": "United States of America"
    },
    {
        "value": "Uruguay",
        "label": "Uruguay"
    },
    {
        "value": "Uzbekistan",
        "label": "Uzbekistan"
    },
    {
        "value": "Vanuatu",
        "label": "Vanuatu"
    },
    {
        "value": "VaticanCity",
        "label": "Vatican City (Holy See)"
    },
    {
        "value": "Venezuela",
        "label": "Venezuela"
    },
    {
        "value": "Vietnam",
        "label": "Vietnam"
    },
    {
        "value": "Yemen",
        "label": "Yemen"
    },
    {
        "value": "Zambia",
        "label": "Zambia"
    },
    {
        "value": "Zimbabwe",
        "label": "Zimbabwe"
    }
]

export const REGIONS_MAP = {
    "Afghanistan": {
        "name": "Afghanistan"
    },
    "Albania": {
        "name": "Albania"
    },
    "Algeria": {
        "name": "Algeria"
    },
    "Andorra": {
        "name": "Andorra"
    },
    "Angola": {
        "name": "Angola"
    },
    "AntiguaBarbuda": {
        "name": "Antigua and Barbuda"
    },
    "Argentina": {
        "name": "Argentina"
    },
    "Armenia": {
        "name": "Armenia"
    },
    "Australia": {
        "name": "Australia"
    },
    "Austria": {
        "name": "Austria"
    },
    "Azerbaijan": {
        "name": "Azerbaijan"
    },
    "Bahamas": {
        "name": "The Bahamas"
    },
    "Bahrain": {
        "name": "Bahrain"
    },
    "Bangladesh": {
        "name": "Bangladesh"
    },
    "Barbados": {
        "name": "Barbados"
    },
    "Belarus": {
        "name": "Belarus"
    },
    "Belgium": {
        "name": "Belgium"
    },
    "Belize": {
        "name": "Belize"
    },
    "Benin": {
        "name": "Benin"
    },
    "Bhutan": {
        "name": "Bhutan"
    },
    "Bolivia": {
        "name": "Bolivia"
    },
    "BosniaHerzegovina": {
        "name": "Bosnia and Herzegovina"
    },
    "Botswana": {
        "name": "Botswana"
    },
    "Brazil": {
        "name": "Brazil"
    },
    "Brunei": {
        "name": "Brunei"
    },
    "Bulgaria": {
        "name": "Bulgaria"
    },
    "BurkinaFaso": {
        "name": "Burkina Faso"
    },
    "Burundi": {
        "name": "Burundi"
    },
    "Cambodia": {
        "name": "Cambodia"
    },
    "Cameroon": {
        "name": "Cameroon"
    },
    "Canada": {
        "name": "Canada"
    },
    "CapeVerde": {
        "name": "Cape Verde"
    },
    "CentralAfricanRepublic": {
        "name": "Central African Republic"
    },
    "Chad": {
        "name": "Chad"
    },
    "Chile": {
        "name": "Chile"
    },
    "China": {
        "name": "China"
    },
    "Colombia": {
        "name": "Colombia"
    },
    "Comoros": {
        "name": "Comoros"
    },
    "RepublicoftheCongo": {
        "name": "Congo, Republic of the"
    },
    "DCR": {
        "name": "Congo, Democratic Republic of the"
    },
    "CostaRica": {
        "name": "Costa Rica"
    },
    "CotedIvoire": {
        "name": "Cote d'Ivoire"
    },
    "Croatia": {
        "name": "Croatia"
    },
    "Cuba": {
        "name": "Cuba"
    },
    "Cyprus": {
        "name": "Cyprus"
    },
    "CzechRepublic": {
        "name": "Czech Republic"
    },
    "Denmark": {
        "name": "Denmark"
    },
    "Djibouti": {
        "name": "Djibouti"
    },
    "Dominica": {
        "name": "Dominica"
    },
    "DominicanRepublic": {
        "name": "Dominican Republic"
    },
    "EastTimor": {
        "name": "East Timor (Timor-Leste)"
    },
    "Ecuador": {
        "name": "Ecuador"
    },
    "Egypt": {
        "name": "Egypt"
    },
    "ElSalvador": {
        "name": "El Salvador"
    },
    "EquatorialGuinea": {
        "name": "Equatorial Guinea"
    },
    "Eritrea": {
        "name": "Eritrea"
    },
    "Estonia": {
        "name": "Estonia"
    },
    "Ethiopia": {
        "name": "Ethiopia"
    },
    "Fiji": {
        "name": "Fiji"
    },
    "Finland": {
        "name": "Finland"
    },
    "France": {
        "name": "France"
    },
    "Gabon": {
        "name": "Gabon"
    },
    "Gambia": {
        "name": "The Gambia"
    },
    "Georgia": {
        "name": "Georgia"
    },
    "Germany": {
        "name": "Germany"
    },
    "Ghana": {
        "name": "Ghana"
    },
    "Greece": {
        "name": "Greece"
    },
    "Grenada": {
        "name": "Grenada"
    },
    "Guatemala": {
        "name": "Guatemala"
    },
    "Guinea": {
        "name": "Guinea"
    },
    "GuineaBissau": {
        "name": "Guinea-Bissau"
    },
    "Guyana": {
        "name": "Guyana"
    },
    "Haiti": {
        "name": "Haiti"
    },
    "Honduras": {
        "name": "Honduras"
    },
    "Hungary": {
        "name": "Hungary"
    },
    "Iceland": {
        "name": "Iceland"
    },
    "India": {
        "name": "India"
    },
    "Indonesia": {
        "name": "Indonesia"
    },
    "Iran": {
        "name": "Iran"
    },
    "Iraq": {
        "name": "Iraq"
    },
    "Ireland": {
        "name": "Ireland"
    },
    "Israel": {
        "name": "Israel"
    },
    "Italy": {
        "name": "Italy"
    },
    "Jamaica": {
        "name": "Jamaica"
    },
    "Japan": {
        "name": "Japan"
    },
    "Jordan": {
        "name": "Jordan"
    },
    "Kazakhstan": {
        "name": "Kazakhstan"
    },
    "Kenya": {
        "name": "Kenya"
    },
    "Kiribati": {
        "name": "Kiribati"
    },
    "NorthKorea": {
        "name": "Korea, North"
    },
    "SouthKorea": {
        "name": "Korea, South"
    },
    "Kosovo": {
        "name": "Kosovo"
    },
    "Kuwait": {
        "name": "Kuwait"
    },
    "Kyrgyzstan": {
        "name": "Kyrgyzstan"
    },
    "Laos": {
        "name": "Laos"
    },
    "Latvia": {
        "name": "Latvia"
    },
    "Lebanon": {
        "name": "Lebanon"
    },
    "Lesotho": {
        "name": "Lesotho"
    },
    "Liberia": {
        "name": "Liberia"
    },
    "Libya": {
        "name": "Libya"
    },
    "Liechtenstein": {
        "name": "Liechtenstein"
    },
    "Lithuania": {
        "name": "Lithuania"
    },
    "Luxembourg": {
        "name": "Luxembourg"
    },
    "Macedonia": {
        "name": "Macedonia"
    },
    "Madagascar": {
        "name": "Madagascar"
    },
    "Malawi": {
        "name": "Malawi"
    },
    "Malaysia": {
        "name": "Malaysia"
    },
    "Maldives": {
        "name": "Maldives"
    },
    "Mali": {
        "name": "Mali"
    },
    "Malta": {
        "name": "Malta"
    },
    "MarshallIslands": {
        "name": "Marshall Islands"
    },
    "Mauritania": {
        "name": "Mauritania"
    },
    "Mauritius": {
        "name": "Mauritius"
    },
    "Mexico": {
        "name": "Mexico"
    },
    "Micronesia": {
        "name": "Micronesia, Federated States of"
    },
    "Moldova": {
        "name": "Moldova"
    },
    "Monaco": {
        "name": "Monaco"
    },
    "Mongolia": {
        "name": "Mongolia"
    },
    "Montenegro": {
        "name": "Montenegro"
    },
    "Morocco": {
        "name": "Morocco"
    },
    "Mozambique": {
        "name": "Mozambique"
    },
    "Myanmar": {
        "name": "Myanmar (Burma)"
    },
    "Namibia": {
        "name": "Namibia"
    },
    "Nauru": {
        "name": "Nauru"
    },
    "Nepal": {
        "name": "Nepal"
    },
    "Netherlands": {
        "name": "Netherlands"
    },
    "New Zealand": {
        "name": "New Zealand"
    },
    "Nicaragua": {
        "name": "Nicaragua"
    },
    "Niger": {
        "name": "Niger"
    },
    "Nigeria": {
        "name": "Nigeria"
    },
    "Norway": {
        "name": "Norway"
    },
    "Oman": {
        "name": "Oman"
    },
    "Pakistan": {
        "name": "Pakistan"
    },
    "Palau": {
        "name": "Palau"
    },
    "Panama": {
        "name": "Panama"
    },
    "PapuaNewGuinea": {
        "name": "Papua New Guinea"
    },
    "Paraguay": {
        "name": "Paraguay"
    },
    "Peru": {
        "name": "Peru"
    },
    "Philippines": {
        "name": "Philippines"
    },
    "Poland": {
        "name": "Poland"
    },
    "Portugal": {
        "name": "Portugal"
    },
    "Qatar": {
        "name": "Qatar"
    },
    "Romania": {
        "name": "Romania"
    },
    "Russia": {
        "name": "Russia"
    },
    "Rwanda": {
        "name": "Rwanda"
    },
    "StKittsNevis": {
        "name": "Saint Kitts and Nevis"
    },
    "SaintLucia": {
        "name": "Saint Lucia"
    },
    "StVincent&Grenadines": {
        "name": "Saint Vincent and the Grenadines"
    },
    "Samoa": {
        "name": "Samoa"
    },
    "SanMarino": {
        "name": "San Marino"
    },
    "SaoTomePrincipe": {
        "name": "Sao Tome and Principe"
    },
    "SaudiArabia": {
        "name": "Saudi Arabia"
    },
    "Senegal": {
        "name": "Senegal"
    },
    "Serbia": {
        "name": "Serbia"
    },
    "Seychelles": {
        "name": "Seychelles"
    },
    "SierraLeone": {
        "name": "Sierra Leone"
    },
    "Singapore": {
        "name": "Singapore"
    },
    "Slovakia": {
        "name": "Slovakia"
    },
    "Slovenia": {
        "name": "Slovenia"
    },
    "SolomonIslands": {
        "name": "Solomon Islands"
    },
    "Somalia": {
        "name": "Somalia"
    },
    "SouthAfrica": {
        "name": "South Africa"
    },
    "SouthSudan": {
        "name": "South Sudan"
    },
    "Spain": {
        "name": "Spain"
    },
    "SriLanka": {
        "name": "Sri Lanka"
    },
    "Sudan": {
        "name": "Sudan"
    },
    "Suriname": {
        "name": "Suriname"
    },
    "Swaziland": {
        "name": "Swaziland"
    },
    "Sweden": {
        "name": "Sweden"
    },
    "Switzerland": {
        "name": "Switzerland"
    },
    "Syria": {
        "name": "Syria"
    },
    "Taiwan": {
        "name": "Taiwan"
    },
    "Tajikistan": {
        "name": "Tajikistan"
    },
    "Tanzania": {
        "name": "Tanzania"
    },
    "Thailand": {
        "name": "Thailand"
    },
    "Togo": {
        "name": "Togo"
    },
    "Tonga": {
        "name": "Tonga"
    },
    "TrinidadTobago": {
        "name": "Trinidad and Tobago"
    },
    "Tunisia": {
        "name": "Tunisia"
    },
    "Turkey": {
        "name": "Turkey"
    },
    "Turkmenistan": {
        "name": "Turkmenistan"
    },
    "Tuvalu": {
        "name": "Tuvalu"
    },
    "Uganda": {
        "name": "Uganda"
    },
    "Ukraine": {
        "name": "Ukraine"
    },
    "UAE": {
        "name": "United Arab Emirates"
    },
    "Uk": {
        "name": "United Kingdom"
    },
    "USA": {
        "name": "United States of America"
    },
    "Uruguay": {
        "name": "Uruguay"
    },
    "Uzbekistan": {
        "name": "Uzbekistan"
    },
    "Vanuatu": {
        "name": "Vanuatu"
    },
    "VaticanCity": {
        "name": "Vatican City (Holy See)"
    },
    "Venezuela": {
        "name": "Venezuela"
    },
    "Vietnam": {
        "name": "Vietnam"
    },
    "Yemen": {
        "name": "Yemen"
    },
    "Zambia": {
        "name": "Zambia"
    },
    "Zimbabwe": {
        "name": "Zimbabwe"
    }
}
