import React from "react";
import { BeatLoader } from "react-spinners";

const LoaderWithBackDrop = (props) => {

    const { loading } = props;

    return (
        <>
            {
                loading && <div className={'backdrop-loading-container'}>
                    <BeatLoader loading={true}  size={84} color={'#0a3488'} />
                </div>
            }
        </>
    );
}

export default LoaderWithBackDrop;