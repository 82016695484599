export const PROGRAM_TARGETS = [
    {
        "value": "General",
        "label": "General public"
    },
    {
        "value": "Parents",
        "label": "Parents / Guardians"
    },
    {
        "value": "Teachers",
        "label": "Teachers / Educators"
    },
    {
        "value": "EarlyYears",
        "label": "Preschoolers / Kindergartener"
    },
    {
        "value": "Children",
        "label": "Primary (Elementary) school students"
    },
    {
        "value": "Teens",
        "label": "Secondary (Middle / High) school students"
    },
    {
        "value": "Youth",
        "label": "Tertiary (University / College / Vocational / Polytechnic) students"
    },
    {
        "value": "WomenGirls",
        "label": "Women / Girls"
    },
    {
        "value": "Workforce",
        "label": "Workforce"
    },
    {
        "value": "SeniorCitizens",
        "label": "Senior citizens"
    },
    {
        "value": "AcademicsResearchers",
        "label": "Academics / Researchers"
    },
    {
        "value": "Others",
        "label": "Others"
    }
    // {
    //     "value": "Gamers",
    //     "label": "Gamers"
    // },
    // {
    //     "value": "CivilService",
    //     "label": "Civil Service"
    // },
    // {
    //     "value": "PPP",
    //     "label": "Public-Private Partnership"
    // },
    // {
    //     "value": "Women",
    //     "label": "Women"
    // },
    // {
    //     "value": "Seniors",
    //     "label": "Senior Citizens"
    // },
    // {
    //     "value": "SchoolLeaders",
    //     "label": "School Leaders"
    // },
    // {
    //     "value": "StudentsK12",
    //     "label": "K-12 School Students"
    // },
    // {
    //     "value": "StudentsUni",
    //     "label": "University Students"
    // },
];

export const PROGRAM_TARGETS_MAP = {
    "General": {
        "label": "General public"
    },
    "Parents": {
        "label": "Parents / Guardians"
    },
    "Teachers": {
        "label": "Teachers / Educators"
    },
    "EarlyYears": {
        "label": "Preschoolers / Kindergartener"
    },
    "Children": {
        "label": "Primary (Elementary) school students"
    },
    "Teens": {
        "label": "Secondary (Middle / High) school students"
    },
    "Youth": {
        "label": "Tertiary (University / College / Vocational / Polytechnic) students"
    },
    "WomenGirls": {
        "label": "Women / Girls"
    },
    // "Women": {
    //     "label": "Women / Girls"
    // },
    "Workforce": {
        "label": "Workforce"
    },
    "SeniorCitizens":{
        "label": "Senior Citizens"
    },
    "AcademicsResearchers":{
        "label": "Academics / Researchers"
    },
    "Others": {
        "label": "Others"
    }
    // "Gamers": {
    //     "label": "Gamers"
    // },
    // "CivilService": {
    //     "label": "Civil Service"
    // },
    // "PPP": {
    //     "label": "Public-Private Partnership"
    // },
    // "Seniors": {
    //     "label": "Seniors Citizens"
    // },
    // "SchoolLeaders": {
    //     "label": "School Leaders"
    // },
    // "StudentsK12": {
    //     "label": "K-12 School Students"
    // },
    // "StudentsUni": {
    //     "label": "University Students"
    // },

};