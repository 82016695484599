import {invokeApi} from '../bl_libs/invokeApi';
import {LOCAL_STORAGE_KEYS} from "../utils/constants";
import crypto from "crypto";
import {removeSaltAtPos, save_e2ee_keys, use_e2ee} from "../utils/utils";

const NodeRSA = require('node-rsa');

const getRSAPubKey = async () => {
    let requestObj = {
        path: '/my_dq/auth/passencrypt/pubkey',
        method: 'GET'
    };
    const resp = await invokeApi(requestObj, use_e2ee());
    if (resp.data.publicKey) return resp.data.publicKey;
    return false
}

export const loginUser = async (email, _password, remember_me) => {
    let requestObj = {
        path: '/dqseal_client/auth/dqscl/login',
        method: 'POST'
    };

    let encapsulation = false;
    let xc_dq_encode = false;
    let password = _password;
    const publicKeyString = await getRSAPubKey();
    if (publicKeyString) {
        try {
            const publicKey = new NodeRSA(publicKeyString);
            const nonce = crypto.randomBytes(16).toString('hex');
            const timestamp = new Date().toISOString();
            const crypto_str = `${nonce}-${timestamp}`;
            const password_obj = JSON.stringify({ _password, crypto_str });

            password = publicKey.encrypt(password_obj, 'base64');
            encapsulation = true;
            if (use_e2ee()) xc_dq_encode = true;
        } catch (e) {
            // will continue sending the plain password text
        }
    }

    const postData = {
        email,
        password,
        encapsulation,
        xc_dq_encode,
        remember_me
    };
    requestObj['postData'] = postData;

    const result = await invokeApi(requestObj);
    if (xc_dq_encode && result.data && result.data.encoded_inf) {
        // recover the decoded data
        result.data = JSON.parse(atob(removeSaltAtPos(result.data.encoded_inf)));
    }
    if (result.data && result.data.e2ee_pub_key) save_e2ee_keys(result);

    return result;
}

export const registerUser = async (email, _password, remember_me) => {
    let requestObj = {
        path: '/dqseal_client/auth/dqscl/register',
        method: 'POST'
    };

    let encapsulation = false;
    let xc_dq_encode = false;
    let password = _password;
    const publicKeyString = await getRSAPubKey();
    if (publicKeyString) {
        try {
            const publicKey = new NodeRSA(publicKeyString);
            const nonce = crypto.randomBytes(16).toString('hex');
            const timestamp = new Date().toISOString();
            const crypto_str = `${nonce}-${timestamp}`;
            const password_obj = JSON.stringify({ _password, crypto_str });

            password = publicKey.encrypt(password_obj, 'base64');
            encapsulation = true;
            if (use_e2ee()) xc_dq_encode = true;
        } catch (e) {
            // will continue sending the plain password text
        }
    }

    requestObj['postData'] = {
        email,
        password,
        encapsulation,
        xc_dq_encode,
        remember_me
    };

    const result = await invokeApi(requestObj);
    if (xc_dq_encode && result.data && result.data.encoded_inf) {
        // recover the decoded data
        result.data = JSON.parse(atob(removeSaltAtPos(result.data.encoded_inf)));
    }
    if (result.data && result.data.e2ee_pub_key) save_e2ee_keys(result);

    return result;
}

export const logoutUser = () => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/auth/dqscl/logout',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        all_devices: true
    };

    return invokeApi(requestObj);
};

export const initResetPassword = (email) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/auth/dqscl/init/reset/password',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        email
    };

    return invokeApi(requestObj);
}

export const resetPassword = async (reset_code, _password) => {
    let requestObj = {
        path: '/dqseal_client/auth/dqscl/password/reset',
        method: 'POST',
    };

    let encapsulation = false;
    let password = _password;
    const publicKeyString = await getRSAPubKey();
    if (publicKeyString) {
        try {
            const publicKey = new NodeRSA(publicKeyString);
            const nonce = crypto.randomBytes(16).toString('hex');
            const timestamp = new Date().toISOString();
            const crypto_str = `${nonce}-${timestamp}`;
            const password_obj = JSON.stringify({ _password, crypto_str });

            password = publicKey.encrypt(password_obj, 'base64');
            encapsulation = true;
        } catch (e) {
            // will continue sending the plain password text
        }
    }

    requestObj['postData'] = {
        reset_code,
        encapsulation,
        password
    };

    return invokeApi(requestObj);
}

export const changePassword = async (old_password, new_password) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path: '/dqseal_client/auth/dqscl/password/change',
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    let encapsulation = false;
    let xc_dq_encode = false;
    const publicKeyString = await getRSAPubKey();
    if (publicKeyString) {
        try {
            const publicKey = new NodeRSA(publicKeyString);
            const nonce = crypto.randomBytes(16).toString('hex');
            const timestamp = new Date().toISOString();
            const crypto_str = `${nonce}-${timestamp}`;
            const old_password_obj = JSON.stringify({ old_password, crypto_str });
            const new_password_obj = JSON.stringify({ new_password, crypto_str });

            old_password = publicKey.encrypt(old_password_obj, 'base64');
            new_password = publicKey.encrypt(new_password_obj, 'base64');
            encapsulation = true;
            if (use_e2ee()) xc_dq_encode = true;
        } catch (e) {
            // will continue sending the plain password text
        }
    }

    requestObj['postData'] = {
        old_password,
        new_password,
        encapsulation,
        xc_dq_encode
    };

    return invokeApi(requestObj, use_e2ee());
};

export const resendEmailConfirmation = () => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/auth/dqscl/email/resend/confirm_token',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };

    return invokeApi(requestObj);
};

export const confirmEmail = (confirmation_token) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/auth/dqscl/email/confirm',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        confirmation_token
    };

    return invokeApi(requestObj);
}

export const refreshToken = () => {
    const auth_token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    const refresh_token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.refresh_token));
    let requestObj = {
        path   : '/dqseal_client/auth/dqscl/refresh/token',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${auth_token}`,
            'Refresh-Token': refresh_token
        }
    };
    requestObj['postData'] = {
        auth_token,
        refresh_token
    };

    return invokeApi(requestObj);
};