import { useEffect } from 'react';
import {getAnalysisService} from "../../../services/app.service";
import {ANALYSIS_TYPES} from "../../../utils/constants";
import {asciiSortCompareFunWithKey} from "../../../utils/utils";

export function useDQCCoverage(application_id, setAnalysis) {

    const loadAnalysis = async () => {
        try {
            const result = await getAnalysisService(application_id, ANALYSIS_TYPES.LEVEL_5_ANALYSIS);
            if(!result.error) {
                const analysis = result.data
                analysis.data.sort(asciiSortCompareFunWithKey('dqc_no'))
                setAnalysis(analysis);
            }
        }
        catch (e) {
            console.error(e);
            // already toasted the error
        }
    }

    useEffect( ()=> {
        loadAnalysis().then((res) => `dqc coverage fetch initiated, ${res}`);
    }, [])
}