export const PROGRAM_STATUS = [
    {
        "value": "Active",
        "label": "The program is still in use today."
    },
    {
        "value": "Pilot",
        "label": "The program is still being developed today or will be launched soon."
    },
    {
        "value": "Inactive",
        "label": "The program is no longer in use today."
    }
];

export const PROGRAM_STATUS_MAP = {
    "Active": {
        "label": "The program is still in use today."
    },
    "Pilot":{
        "label":"The program is still being developed today or will be launched soon."
    },
    "Inactive": {
        "label": "The program is no longer in use today."
    }
};