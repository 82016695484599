import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BusinessIcon from '@material-ui/icons/Business';
import List from '@material-ui/core/List';
import ExistToAppIcon from '@material-ui/icons/ExitToApp';

import { withRouter } from "react-router";


const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));


const MainList = (props) => {

    const { history, path, logoutAction } = props;

    return (
        <List>

            <ListItem selected={path === 'home'} button onClick={()=>history.push('/home')}>
                <ListItemIcon>
                    <VisibilityIcon style={{color: '#F4F4F4'}}/>
                </ListItemIcon>
                <ListItemText primary="Overview" />
            </ListItem>

            <ListItem selected={path === 'company__view'} button onClick={()=>history.push('/company/view')}>
                <ListItemIcon>
                    <BusinessIcon style={{color: '#F4F4F4'}}/>
                </ListItemIcon>
                <ListItemText primary="Organization" />
            </ListItem>

            <ListItem selected={path === 'application__list'} button onClick={()=>history.push('/application/list')}>
                <ListItemIcon>
                    <AllInboxIcon style={{color: '#F4F4F4'}}/>
                </ListItemIcon>
                <ListItemText primary="Applications" />
            </ListItem>

            <ListItem button onClick={logoutAction}>
                <ListItemIcon>
                    <ExistToAppIcon style={{color: '#F4F4F4'}}/>
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </ListItem>
        </List>
    );
}

export default withRouter(MainList);