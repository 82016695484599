import React from 'react';
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";
// import {DQ_DEFINITIONS} from "../../../../utils/constants";
import CoverageChart from "./CoverageChart.report.comp";

export default function Page8(props) {

    const {DQ_DEFINITIONS, pageNumber, overallCoverage, coverage, onlyTable = false, isLastPage, xMax} = props;
    const total_messages = coverage && coverage.grand_total && coverage.grand_total.count ? coverage.grand_total.count : 0;
    const total_dqc = coverage && coverage.data ? coverage.data.length : 0;
    function getDQC(dqc_no) {
        const dq_num = parseInt(dqc_no.replace("DQC", ""), 10);
        const code = isNaN(dq_num) ? dqc_no : `DQ${dq_num}`
        const title = DQ_DEFINITIONS[code] ? DQ_DEFINITIONS[code].title : ""
        const color = DQ_DEFINITIONS[code] ? DQ_DEFINITIONS[code].color : "#444"
        return {code, title, color};
    }
    function getDQCName(dqc_no) {
        const {title, code} = getDQC(dqc_no);
        return code + (title ? `: ${title}` : '');
    }

    const sortedCoverageData = overallCoverage && overallCoverage.data
        ? [...overallCoverage.data].sort((a, b) => b.percent - a.percent)
        : [];

    // Check that sortedCoverageData has at least two items
    const hasSufficientData = sortedCoverageData.length >= 2;
    const highestDQ = hasSufficientData ? sortedCoverageData[0] : null;
    const secondHighestDQ = hasSufficientData ? sortedCoverageData[1] : null;
    const chartHeight = sortedCoverageData.length * 3.33;

    return <ReportPage pageNumber={pageNumber}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={"flex-start"} height={"inherit"} px={10}>
            { !onlyTable &&
            <Box id={"2.-dq-sct-analyses"}>
                <Typography variant={'h3'} style={{color: "deepskyblue", fontWeight: "bold", fontFamily: "Raleway", fontSize: '24px', paddingTop: 70}}>
                    2.	DQ SCT Analyses
                </Typography>
                <Box id={"2.1-analysis-i:-coverage-of-dq-competencies"}>
                <Typography
                    variant={'h3'}
                    style={{
                        color: "orangered",
                        fontWeight: "lighter",
                        fontFamily: 'Raleway',
                        paddingTop: '30px',
                        fontSize: '22px'
                    }}
                >
                    2.1	Analysis I: Coverage of DQ Competencies
                </Typography>
                </Box>
                <Box>
                    <Typography
                        style={{
                            color: "black",
                            fontWeight: "lighter",
                            fontFamily: 'Source Sans Pro',
                            paddingTop: '30px',
                            fontSize: '18px',
                            textAlign: 'justify'
                        }}
                    >
                        This section analyzes your program regarding the coverage of 32 DQ Competencies. Through our analysis, <span>{total_messages} </span>
                        learning messages were reviewed and <span>{total_dqc} </span> out of 32
                        DQ Competencies were found from the reviewed messages.
                    </Typography>
                    <Typography
                        style={{
                            color: "black",
                            fontWeight: "lighter",
                            fontFamily: 'Source Sans Pro',
                            paddingTop: '30px',
                            fontSize: '18px',
                            textAlign: 'justify'
                        }}
                    >
                        Figure 1 below presents the distribution of <span>{total_messages} </span>
                        learning messages across <span>{total_dqc} </span>
                        DQ Competencies by listing the DQ Competencies in decreasing order of percentages.&nbsp;
                        {highestDQ && `${highestDQ.dqc_no.replace("DQC", "DQ")} ${getDQC(highestDQ.dqc_no).title} has the highest percentage of messages, representing ${highestDQ.percent.toFixed(2)}% of the program.`}
                        {secondHighestDQ && ` ${secondHighestDQ.dqc_no.replace("DQC", "DQ")} ${getDQC(secondHighestDQ.dqc_no).title} follows with ${secondHighestDQ.percent.toFixed(2)}%.`}
                    </Typography>
                </Box>
            </Box>
            }
            {sortedCoverageData.length > 0 &&
                <>
                    <Box paddingTop={onlyTable ? '70px' : '30px'}></Box>
                    {/*<Box  style={{height: "100%", width: "100%"}}>*/}
                        <Box width={"100%"} my={2}  style={{ minHeight: "15%", height: `${chartHeight}%` }}>
                        <CoverageChart xMax={xMax} coverage={
                        sortedCoverageData.map((item) => ({
                            ...item,
                            ...getDQC(item.dqc_no)
                        }))
                        }/>
                </Box>

                { isLastPage &&
                    <Typography
                        style={{
                            color: "black",
                            fontWeight: "lighter",
                            fontFamily: 'Source Sans Pro',
                            paddingTop: '15px',
                            fontSize: '18px',
                            textAlign: 'center'
                        }}
                    >
                    Figure 1. Distribution of learning messages across DQ Competencies.
                    </Typography>
                }
                    {/*</Box>*/}
                </>
            }
        </Box>

    </ReportPage>
}