export const PROGRAM_COST = [
    {
        "value": "free",
        "label": "Free"
    },
    {
        "value": "paid",
        "label": "Paid"
    }
];

export const PROGRAM_COST_MAP = {
    "free": {
        "label": "Free"
    },
    "paid":{
        "label":"Paid"
    }
};