import React from 'react';
import PoweredByDQ from "../../../../assets/images/report/poweredbydq.png";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";

export default function Page1(props) {

    const {pageNumber, companyInfo, programInfo, onlyTable = false} = props;

    return <ReportPage pageNumber={pageNumber}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={"center"} height={"inherit"}>
            <Typography variant={'h3'} style={{color: "deepskyblue",width:'512px', fontWeight: "bold", textAlign:'center',fontFamily: 'Raleway', fontSize: '42'}}>
                DQ Standards Conformity Test Report
            </Typography>
            <Typography variant={'h4'} style={{color: "grey", fontWeight: "lighter",padding:'25px 0px', fontFamily: "Raleway", fontSize: '30'}}>
                for
            </Typography>
            <Typography variant={'h4'} style={{
                color: "grey",
                fontWeight: "bold",
                fontFamily: "Raleway",
                fontSize: '30',
                textAlign: 'center',
                padding: '0 20px',
                maxWidth: '100%',
                margin: '0 auto'
            }}>
                {companyInfo.NameOrg}
            </Typography>
            <Box component="img" src={PoweredByDQ} alt="Powered By DQ" style={{ width: 'auto', height: '150px', marginRight: '20px', paddingTop: '30px' }} />
        </Box>
    </ReportPage>
}