import React from 'react';
import Bg19 from "../../../../assets/images/report/bg19.png";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";
import PoweredBy from "../../../../assets/images/report/poweredbydq.png";

export default function Page21(props) {

    const {pageNumber, onlyTable = false} = props;

    return <ReportPage pageNumber={pageNumber}>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} height={'inherit'} width={'inherit'} gridRowGap={10}>
            <Typography
                style={{
                    color: "black",
                    fontWeight: "bold",
                    fontFamily: 'Source Sans Pro',
                    paddingTop: '30px',
                    fontSize: '28px',
                    textAlign: 'center'
                }}
            >                If you have any other questions, feel free to contact us:
            </Typography>
            <br/>
            <a href={'mailto:research@dqforall.com'} style={{fontSize: 28, color: "blue", fontWeight: "bold", textAlign: "center", textDecoration: 'underline'}}>
                research@dqforall.com
            </a>
            <Box display={'flex'} alignItems={'center'}>
            <Box component="img" src={PoweredBy} alt="Powered By DQ" style={{ width: 'auto', height: '180px', marginRight: '20px', paddingTop: '30px' }} />
            </Box>
        </Box>
    </ReportPage>
}