import React, { useState, useEffect } from "react";
import {
    Container,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Typography,
    Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field, useFormikContext } from "formik";
import {getCompanyInfoService, updateCompanyInfoService} from "../../services/company.service";
import {useHistory} from "react-router-dom";
import {useAuth} from "../../context/auth";
import {REGIONS} from "../../utils/META/cat_101";
import {ORG_TYPES} from "../../utils/META/cat_102";
import {SECTORS} from "../../utils/META/cat_103";
import {HIST_DURATIONS} from "../../utils/META/cat_104";
import {SIZE_EMPLOYEE} from "../../utils/META/cat_105";
import {toast} from "../../utils/utils";
import {LOCAL_STORAGE_KEYS, TOAST_TYPES} from "../../utils/constants";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        minWidth: "100%",
    },
}));

const defaultInitialValues = {
    NameOrg: "Test Organization",
    NameContact: "John Doe",
    NameDept: "Software Development, Software Developer",
    Region: "Singapore",
    OrgType: "EdTech",
    OrganizationEntered: "",
    Sector: "AutomotiveIndustries",
    SectorEntered: "",
    History: "lessthan1year",
    SizeEmployee: "11-50employees",
    OrgVision: "Our vision is to revolutionize the IT sector. Our mission is to provide top quality software services.",
    sameAsOrgInfo: false,
};

const emptyInitialValues = {
    NameOrg: "",
    NameContact: "",
    NameDept: "",
    Region: "",
    OrgType: "",
    OrganizationEntered: "",
    Sector: "",
    SectorEntered: "",
    History: "",
    SizeEmployee: "",
    OrgVision: "",
    sameAsOrgInfo: false,
};

// Your form fields...
const FormTextField = ({ label, name, ...rest }) => (
    <Field
        as={TextField}
        label={label}
        name={name}
        fullWidth
        required
        margin="normal"
        variant="outlined"
        InputLabelProps={{ shrink: !!rest.value }}
        {...rest}
    />

);


const FormSelect = ({ label, name, options }) => (
    <FormControl fullWidth variant="outlined" margin="normal" required>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Field as={Select} label={label} name={name}>
            {options.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </Field>
    </FormControl>
);


const FormComponent = ({organizationInfo, userEmail}) => {
    const { values, setFieldValue } = useFormikContext();
    const [isSameAsOrg, setIsSameAsOrg] = useState(false);

    useEffect(() => {
        if (isSameAsOrg) {
            setFieldValue('nameCompany', values.NameOrg);
            // setFieldValue('addressCompany', organizationInfo.addressCompany);
            // setFieldValue('contactCompany', organizationInfo.contactCompany);
            setFieldValue('nameContactPerson', values.NameContact);
            // setFieldValue('emailContactPerson', userEmail);
            // setFieldValue('contactContactPerson', organizationInfo.contactContactPerson);

        }
    }, [isSameAsOrg, organizationInfo, setFieldValue]);

    const handleCheckboxChange = (event) => {
        setIsSameAsOrg(event.target.checked);
        setFieldValue('sameAsOrgInfo', event.target.checked);

        if (!event.target.checked) {
            setFieldValue('nameCompany', '');
            setFieldValue('nameContactPerson', '');
            // setFieldValue('emailContactPerson', '');
        }
    };

    return (
        <Form>
            <FormTextField label="Name of organization" name="NameOrg" value={values.NameOrg} />
            <FormTextField label="Name of contact person" name="NameContact" value={values.NameContact} />
            <FormTextField
                label="Department and position of contact person"
                name="NameDept"
                value={values.NameDept}
            />
            <FormSelect
                label="Country of organization’s headquarters"
                name="Region"
                options={REGIONS}
                value={values.Region}
            />
            <FormSelect
                label="Type of organization"
                name="OrgType"
                options={ORG_TYPES}
                value={values.OrgType}
            />
            {values.OrgType === 'other' && (
                <FormTextField label="Please specify type of organization." name="OrganizationEntered" value={values.OrganizationEntered} />
            )}
            <FormSelect
                label="Sector of organization"
                name="Sector"
                options={SECTORS}
                value={values.Sector}
            />
            {values.Sector === 'other' && (
                <FormTextField label="Please specify sector of organization." name="SectorEntered" value={values.SectorEntered} />
            )}
            <FormSelect
                label="Age of organization"
                name="History"
                options={HIST_DURATIONS}
                value={values.History}
            />
            <FormSelect
                label="Size of organization"
                name="SizeEmployee"
                options={SIZE_EMPLOYEE}
                value={values.SizeEmployee}
            />
            <FormTextField
                label="Mission and vision of organization"
                name="OrgVision"
                value={values.OrgVision}
            />
            <Box mt={5}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Billing Information
                </Typography>
            </Box>

            <FormControlLabel
                control={
                    <Checkbox
                        checked={values.sameAsOrgInfo}
                        onChange={handleCheckboxChange}
                        name="sameAsOrgInfo"
                    />
                }
                label="Same as Organization Information"
            />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FormTextField
                            label="Name of company"
                            name="nameCompany"
                            variant="outlined"
                            fullWidth
                            value={values.nameCompany}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormTextField
                            label="Address of company"
                            name="addressCompany"
                            variant="outlined"
                            fullWidth
                            value={values.addressCompany}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormTextField
                            label="Contact number of company"
                            name="contactCompany"
                            variant="outlined"
                            fullWidth
                            value={values.contactCompany}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormTextField
                            label="Name of contact person"
                            name="nameContactPerson"
                            variant="outlined"
                            fullWidth
                            value={values.nameContactPerson}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormTextField
                            label="Email of contact person"
                            name="emailContactPerson"
                            variant="outlined"
                            fullWidth
                            value={values.emailContactPerson}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormTextField
                            label="Contact number of contact person"
                            name="contactContactPerson"
                            variant="outlined"
                            fullWidth
                            value={values.contactContactPerson}
                        />
                    </Grid>
                </Grid>
            <Box mt={2}>
                <Button type="submit" variant="contained" color="primary">
                    Save
                </Button>
            </Box>
        </Form>
    );
};

const CompanyInfoUpdatePage = () => {
    const classes = useStyles();

    const history = useHistory();
    const { authUser, setAuthUser } = useAuth();
    const [formValues, setFormValues] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const userEmail = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.user_email));

    const getCompanyInfo = async () => {
        try {
            setLoading(true);
            const result = await getCompanyInfoService();
            if(!result.error) {
                if (result.data.comp) {
                    const infoObject = {
                        NameOrg: result.data.comp.name_org,
                        NameContact: result.data.comp.name_contact,
                        NameDept: result.data.comp.name_dept,
                        Region: result.data.comp.region,
                        OrgType: result.data.comp.org_type,
                        OrganizationEntered: result.data.comp.org_type === 'other' ? result.data.comp.organization_entered : '',
                        Sector: result.data.comp.sector ,
                        SectorEntered: result.data.comp.sector === 'other' ? result.data.comp.sector_entered : '',
                        History: result.data.comp.history,
                        SizeEmployee: result.data.comp.size_employee,
                        OrgVision: result.data.comp.org_vision,
                        sameAsOrgInfo: result.data.comp.billing_info && result.data.comp.billing_info.same_as_org ? result.data.comp.billing_info.same_as_org : '',
                        nameCompany: result.data.comp.billing_info && result.data.comp.billing_info.name_org ? result.data.comp.billing_info.name_org : '',
                        addressCompany: result.data.comp.billing_info && result.data.comp.billing_info.address_org ? result.data.comp.billing_info.address_org : '',
                        contactCompany: result.data.comp.billing_info && result.data.comp.billing_info.contact_no_org ? result.data.comp.billing_info.contact_no_org  : '',
                        nameContactPerson: result.data.comp.billing_info && result.data.comp.billing_info.name_contact ? result.data.comp.billing_info.name_contact  : '',
                        emailContactPerson: result.data.comp.billing_info && result.data.comp.billing_info.email_contact ? result.data.comp.billing_info.email_contact  : '',
                        contactContactPerson: result.data.comp.billing_info && result.data.comp.billing_info.contact_no_contact ? result.data.comp.billing_info.contact_no_contact  : ''
                    };
                    setFormValues(infoObject);
                }
                setLoading(false);
            }
        }
        catch (e) {
            // already toasted the error
            setLoading(false);
        }
    };

    useEffect(() => {
        if (authUser.company_info_exist) {
            getCompanyInfo().then((res) => `fetch initiated, ${res}`);
        } else {
            setFormValues(defaultInitialValues);
        }
    }, [authUser]);

    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            const result = await updateCompanyInfoService(values);
            console.log('result.data -> ', result.data);
            if (result.data.success) {
                toast(TOAST_TYPES.SUCCESS, "Successfully updated the company information", "Proceed by clicking on the “Applications” page in the navigation panel on the left-hand side of the screen.")
                await setAuthUser({
                    ...authUser,
                    company_info_exist: true
                });
                history.push('/home');
                setLoading(false);
            } else {
                toast(TOAST_TYPES.DANGER, "Bad Request Data", "some of the info might not be correct, info not updated at this moment, try again.");
                setLoading(false);
            }


        } catch (e) {
            // already toasted the error
            setLoading(false);
        }
    };

    return (
        <Container className={classes.container}>
            <Typography variant="h4" component="h1" gutterBottom>
                Organization Information
            </Typography>
            {
                formValues &&
                <Formik
                        initialValues={(authUser.company_info_exist) ? formValues : emptyInitialValues}
                        onSubmit={handleSubmit}
                    >
                        {formikProps => <FormComponent {...formikProps} organizationInfo={formValues} userEmail={userEmail}/>}
                    </Formik>
            }
            <LoaderWithBackDrop loading={loading} />
        </Container>
    );
};

export default CompanyInfoUpdatePage;
