import React, {useEffect} from 'react';
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";
// import {DQ_DEFINITIONS} from "../../../../utils/constants";
import {asciiSortCompareFunWithKey, getMapFromObjectListByKey} from "../../../../utils/utils";
import {makeStyles} from "@material-ui/core/styles";
import DQCircle from "../../../../assets/images/report/dqcircle.png";

const tableStyles = makeStyles((theme) => ({
    root: {
        borderCollapse: "collapse",
        tableLayout: 'fixed',
        width: '100vw',
        "& th,td": {
            border: "1px solid black",
            padding: "5px 10px",
            fontSize: "18px"
        },
        "& th": {
            padding: "18px 12px",
            textAlign: "left",
            backgroundColor: "deepskyblue",
            color: "white",
            fontFamily: 'Source Sans Pro',
            fontWeight: 'bold',
        },
        "& th:nth-child(1)": {
            width: "30%"
        },
        "& th:nth-child(2),": {
            width: "70%"
        },
        "& td:nth-child(1)": {
            width: "30%"
        },
        "& td:nth-child(2),": {
            width: "70%"
        },
    },
}));


function BadgeRow(props) {
    const {badge} = props;
    const c = {color: "black", fontWeight: "normal", textAlign: "left", fontFamily: 'Source Sans Pro', fontSize: '16px'}

    return <>
        {
            badge &&
            <tr>
                {/*<td className={'bold'}>{badge.code}</td>*/}
                <td style={c}>{badge.title}</td>
                <td style={c}>{badge.definition}</td>
                {/*<td>{badge.questions.map((q) => <>{q}<br/></>)}</td>*/}
            </tr>
        }
    </>
}

export default function Page16a(props) {

    const {DQ_DEFINITIONS, pageNumber, breadth, onlyTable = false, isLastPage} = props;
    const classes = tableStyles();
    const badges = breadth;

    // if(breadth.data) {
    //     for(const item of breadth.data) {
    //         const {dqc_no} = item;
    //         const dq_num = parseInt(dqc_no.replace("DQC", ""), 10);
    //         const code = isNaN(dq_num) ? dqc_no : `DQ${dq_num}`
    //         const title = DQ_DEFINITIONS[code] ? DQ_DEFINITIONS[code].title : ""
    //         const definition = DQ_DEFINITIONS[code] ? DQ_DEFINITIONS[code].definition : "";
    //         badges.push({code, title, definition});
    //     }
    // }

    return <ReportPage pageNumber={pageNumber}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={"flex-start"} height={"inherit"} width={"100%"} px={10}>
            { !onlyTable &&
                <Box id={"3.2.1-measure-your-aligned-dq-competency"}>
                    <Typography
                        variant={'h6'}
                        style={{
                            color: "blue",
                            fontWeight: "lighter",
                            fontFamily: 'Raleway',
                            paddingTop: '70px',
                            fontSize: '20px'
                        }}
                    >
                        3.2.1	Measure your Aligned DQ Competency
                    </Typography>
                    { badges.length > 0 ? (
                        <Typography
                            style={{
                                color: "black",
                                fontWeight: "lighter",
                                fontFamily: 'Source Sans Pro',
                                paddingTop: '30px',
                                fontSize: '18px',
                                textAlign: 'justify'
                            }}
                        >
                            Given the alignment of your program with DQ competencies, you have the opportunity to utilize our
                            comprehensive instrument bank to craft bespoke assessments.
                        </Typography>
                    ):(
                        <Typography
                            style={{
                                color: "black",
                                fontWeight: "lighter",
                                fontFamily: 'Source Sans Pro',
                                paddingTop: '30px',
                                fontSize: '18px',
                                textAlign: 'justify'
                            }}
                        >
                            Since your program is not aligned with at least one DQ Competency, we are unable to identify
                            potential areas for measurement in the following table. We recommend enhancing content of your program as outlined in&nbsp;
                            <a style={{ color: 'blue', textDecoration: 'underline' }} href={`#${'3.-enhancement'.toLowerCase().replace(/ /g, '-')}`}>Section 3.1</a>.
                        </Typography>
                    )}
                </Box>
            }
            <Box alignSelf={onlyTable ? "flex-start" : "inherit"} paddingTop={onlyTable ? '70px' : '30px'}>
                <table className={classes.root} style={{width: "100%"}}>
                    { !onlyTable &&
                        <tr>
                            <th>DQ Competency</th>
                            <th>What can be Measured</th>
                        </tr>
                    }
                    {
                        badges.length > 0 ?
                            (
                                badges.map(b => <BadgeRow badge={b}/>)
                            ) :
                            (
                                <>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                </>
                            )
                    }
                </table>
            </Box>
            {isLastPage &&
                <Typography
                    style={{
                        color: "black",
                        fontWeight: "lighter",
                        fontFamily: 'Source Sans Pro',
                        paddingTop: '15px',
                        fontSize: '18px',
                        textAlign: 'left'
                    }}
                >
                    If you are keen on exploring how to integrate DQ Assessments into your program, please let us know.
                </Typography>
            }
        </Box>
    </ReportPage>
}