import React, { useEffect } from 'react';
import {LOCAL_STORAGE_KEYS} from "../../utils/constants";

const EnQaMode = () => {

    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.qa_mode, '1');
    }, []);

    return (
        <p
            style={{
                padding: '15vw',
                textAlign: "center"
            }}
        >
            {process.env.REACT_APP_VERSION}
            <br/>
            Env: {process.env.REACT_APP_NAME}
            QA-Mode: Enabled
        </p>
    );
};

export default EnQaMode;