import React, { useEffect } from 'react';
import {LOCAL_STORAGE_KEYS} from "../../utils/constants";



const DsQaMode = () => {

    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.qa_mode, '0');
    }, []);

    return (
        <p
            style={{
                padding: '15vw',
                textAlign: "center"
            }}
        >
            {process.env.REACT_APP_VERSION}
            <br/>
            Env: {process.env.REACT_APP_NAME}
            QA-Mode: Disabled
        </p>
    );
};


export default DsQaMode;
