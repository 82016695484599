import React, {useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper, Grid} from '@material-ui/core';
import {useHistory} from "react-router-dom";
import { SEAL_TYPE_VERBOSE, APP_STATUS_VERBOSE, APP_STATUS, SEAL_TYPE } from "../../utils/constants";
import {listAllApplications} from "../../services/app.service";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
    },
    paper: {
        position: 'absolute',
        width: '80%',
        maxHeight: '80%',
        overflowY: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
    },
    button: {
        marginBottom: theme.spacing(2),
        padding: "0 !important",
        backgroundColor: "transparent",
        color: theme.palette.primary.main,
        boxShadow: "none !important",
        float:"right",
        fontSize: '18px',
        '&:hover': {
            backgroundColor: "transparent",
            color: theme.palette.primary.main,
        },
    },
}));

const date_options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };



const ListApplications = (props) => {
    const {path} = props;
    const classes = useStyles();
    const [appData, setAppData] = useState([]);
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    async function fetchApplications() {
        try {
            setLoading(true);
            const result = await listAllApplications();
            if(!result.error) {
                setAppData(result.data.all_apps);
                setLoading(false);
            }
        }
        catch (e) {
            // already toasted the error
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchApplications().then((res) => `fetch initiated, ${res}`);
    }, [])

    const viewApplication = (e, row) => {
        history.push({pathname: '/application/detail', state: row})
    }

    const createAppAction = () => {
        history.push('/application/select')
    }

    function getStatusColor(status) {
        const redStatuses = [
            APP_STATUS.PENDING_PAYMENT,
            APP_STATUS.IN_PROGRESS_COLLECT_DATA,
            APP_STATUS.READY_TO_CERTIFY,
            APP_STATUS.ASSESSMENTS_READY
        ];

        if (redStatuses.includes(status)) {
            return 'red';
        } else if (status === APP_STATUS.COMPLETED) {
            return 'blue';
        } else {
            return 'inherit';
        }
    }

    return (
        <Container className={classes.container}>
            <Grid container style={{display:"flex", justifyContent:"flex-end", alignItems:" right"}}>
                <Button
                    startIcon={<AddIcon/>}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={createAppAction}
                >
                    ADD NEW APPLICATION
                </Button>
            </Grid>
            <TableContainer component={Paper} style={{border:'1px solid #052F44'}}>
                <Table aria-label="simple table">
                    <TableHead style={{backgroundColor:'white', boxShadow: "0 4px 6px -2px rgba(0, 0, 0, 0.3)",}}>
                        <TableRow>
                            <TableCell style={{fontSize:'17px', fontWeight:'500'}}>Application ID</TableCell>
                            <TableCell style={{fontSize:'17px', fontWeight:'500'}}>Program Title</TableCell>
                            <TableCell style={{fontSize:'17px', fontWeight:'500'}}>Submission Date</TableCell>
                            <TableCell style={{fontSize:'17px', fontWeight:'500'}}>Powered by DQ Package</TableCell>
                            <TableCell style={{fontSize:'17px', fontWeight:'500'}}>Status</TableCell>
                            <TableCell style={{fontSize:'17px', fontWeight:'500'}}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            appData.map((row) =>
                                <TableRow  style={{ color: "#11888B",}}>
                                    <TableCell  style={{ color: "#11888B",}}>{row.application_id.slice(-16)}</TableCell>
                                    <TableCell style={{ width: '200px', color: "#11888B", }}>
                                        {row.programInfo ? row.programInfo.program_name : " "}
                                    </TableCell>
                                    <TableCell  style={{ color: "#11888B",}}>{(new Date(row.submission_time)).toLocaleDateString(undefined, date_options)}</TableCell>
                                    <TableCell  style={{ color: "#11888B",}}>{SEAL_TYPE_VERBOSE[row.dqseal_type]}</TableCell>
                                    <TableCell style={{ color: getStatusColor(row.app_status) }}>
                                        {APP_STATUS_VERBOSE[row.app_status]}
                                    </TableCell>
                                    <TableCell>
                                        {/*<Grid container>*/}
                                        {/*    {*/}
                                        {/*        actions[row.status] && actions[row.status].map((action_obj) => {*/}
                                        {/*            if(action_obj.type === ACTION_TYPE.BUTTON) {*/}
                                        {/*                return <Grid item xs>*/}
                                        {/*                    <Button variant="contained" onClick={(e) => action_obj.callback(e, row)}>{action_obj.title}</Button>*/}
                                        {/*                </Grid>*/}
                                        {/*            }*/}
                                        {/*            return null;*/}
                                        {/*        })*/}
                                        {/*    }*/}
                                        {/*</Grid>*/}
                                        <Button style={{backgroundColor:'#052F44', color:'white', borderRadius:'20px', fontSize:'13px', padding:'6px 12px'}} variant="contained" onClick={(e) => viewApplication(e, row)}>View</Button>
                                    </TableCell>
                                    {/*<TableCell>*/}
                                    {/*    <Button startIcon={<DetailsIcon />} onClick={handleOpen}>*/}
                                    {/*        View Details*/}
                                    {/*    </Button>*/}
                                    {/*</TableCell>*/}
                                </TableRow>
                            )
                        }

                    </TableBody>
                </Table>
            </TableContainer>
            <LoaderWithBackDrop loading={loading} />
        </Container>
    );
};

export default ListApplications;