import React, { useEffect, useRef, useState } from 'react';
import {
    List,
    ListItem,
    ListItemText,
    Container,
    Typography,
    Card,
    CardContent,
    Select,
    Grid,
    Divider,
    Box,
    IconButton,
    TextField,
    FormControl, InputLabel, MenuItem,
} from '@material-ui/core';
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {APP_STATUS, ASSESSMENT_REQ_TYPES, TOAST_TYPES} from "../../../utils/constants";
import {postAddAssessmentReq} from "../../../services/certify.service";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {toast} from "../../../utils/utils";
import CertifiedLogo from "../../../assets/images/Certified_logo.png"
import preAssessment from "../../../assets/images/preAssessment.png"
import preAssessment2 from "../../../assets/images/preAssessment2.png"
import postAssessment from "../../../assets/images/postAssessment.png"
import postAssessment2 from "../../../assets/images/postAssessment2.png"
import citizenshipTest from "../../../assets/images/citizenship-icon.png"
import citizenshipTest2 from "../../../assets/images/citizenship-icon2.png"
import parentingTest from "../../../assets/images/digitalParenting.png"
import customPreAssessment from "../../../assets/images/customPreAssessment.png"
import customPreAssessment2 from "../../../assets/images/customPreAssessment2.png"
import customPostAssessment from "../../../assets/images/customPostAssessment.png"
import customPostAssessment2 from "../../../assets/images/customPostAssessment2.png"
import DQAssessment from "../../../assets/images/DQAssessment.png"
import programDashboard from "../../../assets/images/programDashboardIcon.png"
import {Autocomplete} from "@mui/material";
import {REGIONS, REGIONS_MAP} from "../../../utils/META/cat_101";
import {getApplicationAlignmentService, getApplicationService} from "../../../services/app.service";
import {useLocation} from "react-router-dom";
import {ONLINE_SYSTEM, ONLINE_SYSTEM_MAP} from "../../../utils/META/cat_117";
import {SECTORS_MAP} from "../../../utils/META/cat_103";

const useStyles = makeStyles((theme) => ({
    disabledField: {
        '& .MuiOutlinedInput-root.Mui-disabled': {
            color: 'inherit',
            background: 'white',
            border: `1px solid ${theme.palette.divider}`,
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'inherit',
            },
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
        },
    },
    container: {
        padding: theme.spacing(1),
        textAlign:'center',
        width:'100%',
    },
    paper: {
        position: 'absolute',
        width: '80%',
        maxHeight: '80%',
        overflowY: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    instruction: {
        margin: theme.spacing(2, 3, 2, 0),
    },
    button: {
        width: '15%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1, 3, 1, 3),
        backgroundColor: theme.palette.info.main,
        fontSize: '20px',
        color: theme.palette.background.default,
        border: 'none',
        borderRadius: '5px',
        margin:theme.spacing(2),
    },
    mt: {
        marginTop: theme.spacing(2),
    },
    mb: {
        marginBottom: theme.spacing(2),
    },
    listItem: {
        paddingLeft: theme.spacing(7),
        position: 'relative',
    },
    bullet: {
        content: '""',
        position: 'absolute',
        left: theme.spacing(4),
        top: '50%',
        transform: 'translateY(-50%)',
        width: '6px',
        height: '6px',
        borderRadius: '50%',
        color:'black',
    },
    card: {
        textAlign: 'justify',
        width: '20vw',
        margin:'14px',
        display: 'flex',
        flexDirection: 'column', 
        justifyContent: 'space-between',
        border:'1px solid #A3A4A5',
        paddingTop:'30px',
        backgroundColor: "#F4F4F4",
        borderRadius: "30px",
        // boxShadow: "6px 0px 20px rgba(0, 0, 0, 0.3)",
        flex: '1 0 auto',
        '&:not(:last-child)': {
            marginRight: 0,
        },
    },
    assessmentCard: {
        textAlign: 'justify',
        margin:'14px',
        border:'1px solid #052F44',
        backgroundColor: "#F4F4F4",
        borderRadius: "12px",
        // boxShadow: "6px 0px 20px rgba(0, 0, 0, 0.3)",
    },
    verticalDivider: {
        borderRight: `2px solid lightgrey`,
        height: '455px',
        margin:'0 1%',
    },
    nestedCard: {
        borderRadius: theme.spacing(1),
        border: `2px solid black`,
        boxShadow: 'none',
        height: '19vh',
        margin:theme.spacing(1),
    },
    nestedCardContainer: {
        display: 'flex',
        flexWrap:'wrap',
        alignItems:'start',
    },
    nestedButton:{
        width:'90%',
        padding: theme.spacing(1, 2, 1, 2),
        height:'200px',
        backgroundColor: theme.palette.background.paper,
        fontSize: '14px',
        color: "black",
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: '15px',
        margin:theme.spacing(1),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '50vw',
        margin: '0 auto',
        marginTop: theme.spacing(2),
        textAlign:'left',
    },
    textField: {
        marginBottom: theme.spacing(1),
    },
    mySpan: {
    display: 'block',
    },
    sectionContainer:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'stretch',
        marginTop: theme.spacing(6),
        padding: theme.spacing(4, 10),
    },
    sectionItem:{
        backgroundColor: "white",
        flex: 1,
        // padding: "0 !important",
        margin:'0 25px',
        borderRadius: "30px",
        boxShadow: "6px 0px 20px rgba(0, 0, 0, 0.3)",
        border: '1px solid #233043',
        padding: theme.spacing(3),
    },
    label: {
        textAlign: 'left',
        marginRight: theme.spacing(2),
        paddingLeft: '15px !important',
    },
    value: {
        marginTop: theme.spacing(0.5),
        marginLeft: theme.spacing(2),
        color: theme.palette.text.secondary,
        textAlign:'left',
    },
    title: {
        marginBottom:'10px',
        color: theme.palette.text.secondary,
        paddingLeft: '15px !important',
        display: 'flex',
        alignItems: 'flex-end',
    },
    button1: {
        '&:hover': {
            backgroundColor: '#41BAE4',
            color:'white',
        },
    },
    activeButton1: {
        backgroundColor: '#41BAE4',
        color:'white',
    },
    button2: {
        '&:hover': {
            backgroundColor: '#6D8DC8',
            color:'white',
        },
    },
    activeButton2: {
        backgroundColor: '#6D8DC8',
        color:'white',
    },
    button3: {
        '&:hover': {
            backgroundColor: '#E4428E',
            color:'white',
        },
    },
    activeButton3: {
        backgroundColor: '#E4428E',
        color:'white',
    },
    button4: {
        '&:hover': {
            backgroundColor: '#D60464',
            color:'white',
        },
    },
    activeButton4: {
        backgroundColor: '#D60464',
        color:'white',
    },
    button5: {
        '&:hover': {
            backgroundColor: '#E66027',
            color: 'white',

        },
    },
    activeButton5: {
        backgroundColor: '#E66027',
        color: 'white',
    }


}));


function AssessmentModule(props){
    const classes = useStyles();
    const {application_id, status, updateStatus, onSubmit, handleButtonClick, updateAppData } = props;
    const [loading, setLoading] = useState(false);
    const [isAppAligned, setIsAppAligned] = useState(false);

    const [formData, setFormData] = useState({
        noOfUsers: '',
        onlineSystem: '',
        requirements: '',
        nameOfCountries: '',
        onlinePlatformDesc:'',
        callBackUrl:'To be Decided',
        callBackKey:'To be Decided',
        requirementDesc:'',

    });
    const [selectedCard, setSelectedCard] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [isHovered, setIsHovered] = useState({});
    const handleMouseEnter = (buttonId) => {
        setIsHovered((prevStates) => ({
            ...prevStates,
            [buttonId]: true,
        }));
    };

    const handleMouseLeave = (buttonId) => {
        setIsHovered((prevStates) => ({
            ...prevStates,
            [buttonId]: false,
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

    };
    const handleCardClick = (buttonIndex) => {
        if (selectedCard === buttonIndex) {
            setSelectedCard(null);
            setShowForm(false);
        } else {
            setSelectedCard(buttonIndex);
            setShowForm(true);
        }
        handleButtonClick(buttonIndex);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
        updateAppData(formData);
    };

    const handleCountryChange = (event, value) => {
        const nameOfCountriesString = value.map(item => REGIONS_MAP[item.value]?.name || item.value).join(', ');
        setFormData({
            ...formData,
            ["nameOfCountries"]: nameOfCountriesString,
        });
    };

    useEffect(() => {
        const getAppAlignment = async () => {
            if (status >= APP_STATUS.READY_TO_CERTIFY) {
                try {
                    setLoading(true);
                    const result = await getApplicationAlignmentService(application_id);
                    if (result && result.data && !result.error) {
                        setIsAppAligned(result.data.length > 0)
                    }
                    else {
                        console.log('No data exists or error fetching data');
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        getAppAlignment().then(r=> console.log(r));
    }, [application_id, status]);

const submitAddAssessmentReq = async () => {

        const postData = {
            'application_id': application_id,
            'assess_type': selectedCard,
            'expected_users': formData.noOfUsers,
            'client_own_platform_info': formData.onlineSystem,
            'client_own_callback_url': formData.callBackUrl,
            'client_own_callback_key': formData.callBackKey,
            // 'client_own_platform_info_desc': formData.onlinePlatformDesc.length > 0 ? formData.onlinePlatformDesc : '',
            'client_requirements_info': formData.requirements,
            'client_requirements_info_desc': formData.requirementDesc.length > 0 ? formData.requirementDesc : '',
            'intended_name_of_countries': formData.nameOfCountries
        }

        try {
            setLoading(true);
            const result = await postAddAssessmentReq(postData);
            if (!result.error) {
                toast(TOAST_TYPES.SUCCESS, "Request Submitted", "Successfully submitted request, we will contact you back soon.")
                if (result.data.success) {
                    updateStatus(result.data.status);
                }
            }
            setLoading(false);
        }
        catch (e) {
            setLoading(false);
            //already toasted
        }
        // make the post call to => /certify/assessment/request
    }

    return (
        <Container className={classes.container}>
            <Container className={classes.container}>
                <Typography component="h1" variant="h5" color="inherit" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                    Add Assessment
                </Typography>
                <Typography className={classes.instruction} style={{ textAlign: 'left' }} variant="body1">Please select the type of assessment you would like to include into your program. You can also choose if you would like a pre-post version that helps with measuring your users’ efficacy before and after the program.
                </Typography>
                <Box display="flex" alignItems="start">
                    <Card className={classes.card}>
                        <CardContent >
                            <Typography variant="h6" style={{ textAlign: 'center',color: "#11888B",}}>DQ-Selected Assessments</Typography>
                            <Typography component="div" style={{height:'180px',padding:'10px 20px 0px 20px'}}>
                                These assessments are selected and grouped  based on your program’s alignment to the DQ Competencies and  ready-to-go. </Typography>
                            { !isAppAligned &&
                                <Typography component="div" style={{padding:'0px 25px 0px 20px', color:'red', fontSize:'10px', marginTop: '-30px'}}>
                                Not available since the program is not aligned with at least one DQ Competency. </Typography>
                            }
                        <Grid container spacing={0.5} className={classes.nestedCardContainer}>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                            <Button
                                id={`button-1`}
                                className={`${classes.nestedButton} ${classes.button1} ${(selectedCard === ASSESSMENT_REQ_TYPES.PRE_SELECT_PRE_POST && showForm) ? classes.activeButton1 : ''
                                }`}
                                onClick={() => handleCardClick(ASSESSMENT_REQ_TYPES.PRE_SELECT_PRE_POST)}
                                disabled={!isAppAligned}
                                onMouseEnter={() => handleMouseEnter('button-1')}
                                onMouseLeave={() => handleMouseLeave('button-1')}
                            >
                                <div  style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-between'}}>
                                    <img
                                        src={preAssessment}
                                        alt="preAssessmentIcon"
                                        width={48}
                                        height={48}
                                        style={{
                                            display: isHovered['button-1'] || (selectedCard === ASSESSMENT_REQ_TYPES.PRE_SELECT_PRE_POST && showForm) ? 'none' : 'block',
                                        }}
                                    />
                                    <img
                                        src={preAssessment2}
                                        alt="preAssessmentIcon"
                                        width={40}
                                        height={40}
                                        style={{display: isHovered['button-1'] || (selectedCard === ASSESSMENT_REQ_TYPES.PRE_SELECT_PRE_POST && showForm) ? 'block' : 'none',}}
                                    />
                                    <Typography variant="button" sx={(theme) => ({
                                                textAlign: 'center',
                                                [theme.breakpoints.up('md')]: { fontSize: '12px' },
                                            })}>DQ-Selected <br /> Pre and  <br />  Post Assessment</Typography>
                                </div>
                            </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                            <Button
                                id={`button-2`}
                                className={`${classes.nestedButton} ${classes.button2} ${
                                    (selectedCard === ASSESSMENT_REQ_TYPES.PRE_SELECT_POST_ONLY && showForm) ? classes.activeButton2 : ''
                                }`}
                                onClick={() => handleCardClick(ASSESSMENT_REQ_TYPES.PRE_SELECT_POST_ONLY)}
                                disabled={!isAppAligned}
                                onMouseEnter={() => handleMouseEnter('button-2')}
                                onMouseLeave={() => handleMouseLeave('button-2')}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                  <img
                                      src={postAssessment}
                                      alt="postAssessmentIcon"
                                      width={48}
                                      height={48}
                                      style={{
                                          display: isHovered['button-2'] || (selectedCard === ASSESSMENT_REQ_TYPES.PRE_SELECT_POST_ONLY && showForm) ? 'none' : 'block',
                                      }}
                                  />
                                    <img
                                      src={postAssessment2}
                                      alt="postAssessmentIcon"
                                      width={40}
                                      height={40}
                                      style={{
                                          display: isHovered['button-2'] || (selectedCard === ASSESSMENT_REQ_TYPES.PRE_SELECT_POST_ONLY && showForm) ? 'block' : 'none',
                                      }}
                                  />
                                <Typography variant="button">DQ-SELECTED POST ASSESSMENT ONLY</Typography>
                                </div>
                            </Button>
                            </Grid>
                        </Grid>
                        </CardContent>
                    </Card>
                    {/*<Divider className={classes.verticalDivider} />*/}
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="h6" style={{ textAlign: 'center',color: "#11888B", }}>Standard Assessments</Typography>
                            <Typography component="div" style={{height:'180px', padding:'10px 20px 0px 20px'}}>These are standardized assessments for anyone to use and are ready-to-go that cover a broad set of topics or specific to targeted audiences.</Typography>
                        <Grid container className={classes.nestedCardContainer} alignItems="center" justify="center">
                            <Grid item xs={12} sm={12} md={12} lg={6} align="center">
                            <Button
                                id={`button-3`}
                                className={`${classes.nestedButton} ${classes.button3} ${
                                    (selectedCard === ASSESSMENT_REQ_TYPES.STANDARD_DCT && showForm) ? classes.activeButton3 : ''
                                }`}
                                onClick={() => handleCardClick(ASSESSMENT_REQ_TYPES.STANDARD_DCT)}
                                onMouseEnter={() => handleMouseEnter('button-3')}
                                onMouseLeave={() => handleMouseLeave('button-3')}
                            >
                                <div  style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                    <img
                                        src={citizenshipTest}
                                        alt="citizenshipTestIcon"
                                        width={48}
                                        height={48}
                                        style={{marginBottom:'20%', marginTop:'-20%',
                                            display: isHovered['button-3'] || (selectedCard === ASSESSMENT_REQ_TYPES.STANDARD_DCT && showForm)  ? 'none' : 'block',
                                        }}
                                    />
                                    <img
                                        src={citizenshipTest2}
                                        alt="citizenshipTestIcon"
                                        width={40}
                                        height={40}
                                        style={{marginBottom:'20%', marginTop:'-20%',
                                            display: isHovered['button-3'] || (selectedCard === ASSESSMENT_REQ_TYPES.STANDARD_DCT && showForm) ? 'block' : 'none',
                                        }}
                                    />
                                    <Typography variant="button">Digital Citizenship Test</Typography>
                                </div>
                            </Button>
                            </Grid>
                            {/*<Grid item xs={12} sm={12} md={12} lg={6}>*/}
                            {/*<Button*/}
                            {/*    id={`button-4`}*/}
                            {/*    className={`${classes.nestedButton} ${*/}
                            {/*        (selectedCard === ASSESSMENT_REQ_TYPES.STANDARD_DPT && showForm) ? classes.activeButton : ''*/}
                            {/*    }`}*/}
                            {/*    onClick={() => handleCardClick(ASSESSMENT_REQ_TYPES.STANDARD_DPT)}*/}
                            {/*>*/}
                            {/*    <div  style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>*/}
                            {/*        <img*/}
                            {/*            src={parentingTest}*/}
                            {/*            alt="parentingTestIcon"*/}
                            {/*            width={48}*/}
                            {/*            height={48}*/}
                            {/*            style={{marginBottom:'20%', marginTop:'-20%'}}*/}
                            {/*        />*/}
                            {/*        <Typography variant="button"> Digital Parenting Test</Typography>*/}
                            {/*    </div>*/}
                            {/*</Button>*/}
                            {/*</Grid>*/}
                        </Grid>
                        </CardContent>
                    </Card>
                    {/*<Divider className={classes.verticalDivider} />*/}
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="h6" style={{textAlign:'center',color: "#11888B",}}>Custom
                                Assessments
                            </Typography>
                            <Typography component="div" style={{height:'180px', padding:'10px 20px 0px 20px'}}>This custom assessment is based on your specific needs of what you want to measure. Please take note that this does require more time to develop.
                            </Typography>
                        <Grid container className={classes.nestedCardContainer}>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <Button
                                id={`button-5`}
                                className={`${classes.nestedButton} ${classes.button4} ${
                                    (selectedCard === ASSESSMENT_REQ_TYPES.CUSTOM_PRE_POST && showForm) ? classes.activeButton4 : ''
                                }`}
                                onClick={() => handleCardClick(ASSESSMENT_REQ_TYPES.CUSTOM_PRE_POST)}
                                onMouseEnter={() => handleMouseEnter('button-5')}
                                onMouseLeave={() => handleMouseLeave('button-5')}
                            >
                                <div  style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                    <img
                                        src={customPreAssessment}
                                        alt="customPreAssessmentIcon"
                                        width={48}
                                        height={48}
                                        style={{marginBottom:'15%', marginTop:'-20%',
                                            display: isHovered['button-5'] || (selectedCard === ASSESSMENT_REQ_TYPES.CUSTOM_PRE_POST && showForm) ? 'none' : 'block',
                                        }}
                                    />
                                    <img
                                        src={customPreAssessment2}
                                        alt="customPreAssessmentIcon"
                                        width={38}
                                        height={38}
                                        style={{marginBottom:'15%', marginTop:'-20%',
                                            display: isHovered['button-5'] || (selectedCard === ASSESSMENT_REQ_TYPES.CUSTOM_PRE_POST && showForm) ? 'block' : 'none',
                                        }}
                                    />
                                    <Typography variant="button">  Custom Pre and Post Assessment</Typography>
                                </div>

                            </Button>
                        </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                            <Button
                                id={`button-6`}
                                className={`${classes.nestedButton} ${classes.button5} ${
                                    (selectedCard === ASSESSMENT_REQ_TYPES.CUSTOM_POST_ONLY && showForm) ? classes.activeButton5 : ''
                                }`}
                                onClick={() => handleCardClick(ASSESSMENT_REQ_TYPES.CUSTOM_POST_ONLY)}
                                onMouseEnter={() => handleMouseEnter('button-6')}
                                onMouseLeave={() => handleMouseLeave('button-6')}
                            >
                                <div  style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                    <img
                                        src={customPostAssessment}
                                        alt="customPostAssessmentIcon"
                                        width={48}
                                        height={48}
                                        style={{marginBottom:'15%', marginTop:'-20%',
                                            display: isHovered['button-6'] || (selectedCard === ASSESSMENT_REQ_TYPES.CUSTOM_POST_ONLY && showForm) ? 'none' : 'block',
                                        }}
                                    />
                                    <img
                                        src={customPostAssessment2}
                                        alt="customPostAssessmentIcon"
                                        width={40}
                                        height={40}
                                        style={{marginBottom:'15%', marginTop:'-20%',
                                            display: isHovered['button-6'] || (selectedCard === ASSESSMENT_REQ_TYPES.CUSTOM_POST_ONLY && showForm) ? 'block' : 'none',
                                        }}

                                    />
                                    <Typography variant="button">Custom Post Assessment Only</Typography>
                                </div>

                            </Button>
                            </Grid>
                        </Grid>
                        </CardContent>
                    </Card>
                </Box>
                {showForm && (
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <FormControl fullWidth variant="outlined" margin="normal">
                            <TextField
                                label="Expected number of users"
                                className={classes.textField}
                                id="noOfUsers"
                                name="noOfUsers"
                                type="number"
                                value={formData.noOfUsers}
                                onChange={handleInputChange}
                                variant="outlined"
                                required
                            />
                        </FormControl>
                        <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel  htmlFor="onlineSystem">Which of the following delivery options do you prefer for your assessment?</InputLabel>
                        <Select
                            className={classes.textField}
                            id="onlineSystem"
                            value={formData.onlineSystem}
                            label="Which of the following delivery options do you prefer for your assessment?"
                            name="onlineSystem"
                            onChange={handleInputChange}
                            variant="outlined"
                            required
                        >
                            {ONLINE_SYSTEM.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                        {formData.onlineSystem === 'yes' ? (
                            <>
                            <FormControl fullWidth variant="outlined" margin="normal">
                                <TextField
                                    label="Callback URL"
                                    className={`${classes.textField} ${classes.disabledField}`}
                                    name="callBackUrl"
                                    variant="outlined"
                                    required={true}
                                    value={formData.callBackUrl}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                            <FormControl fullWidth variant="outlined" margin="normal">
                                <TextField
                                    label="Callback key"
                                    className={`${classes.textField} ${classes.disabledField}`}
                                    name="callBackKey"
                                    variant="outlined"
                                    required={true}
                                    value={formData.callBackKey}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                            </>
                        ) : null}
                        <FormControl fullWidth variant="outlined" margin="normal">
                            <InputLabel htmlFor="requirements">Do you wish to include any custom impact metrics within the assessment’s dashboard?</InputLabel>
                            <Select
                                id="requirements"
                                label="Do you wish to include any custom impact metrics within the assessment’s dashboard?"
                                name="requirements"
                                value={formData.requirements}
                                onChange={handleInputChange}
                                variant="outlined"
                                required
                            >
                                <MenuItem value="yes">Yes</MenuItem>
                                <MenuItem value="no">No</MenuItem>
                            </Select>
                        </FormControl>

                        {formData.requirements === 'yes' && (
                            <FormControl fullWidth variant="outlined" margin="normal">
                                <TextField
                                    label="Details of requirements"
                                    name="requirementDesc"
                                    value={formData.requirementDesc}
                                    onChange={handleInputChange}
                                    multiline={true}
                                    maxRows={10}
                                    variant="outlined"
                                    required={true}
                                />
                            </FormControl>
                        )}
                        <FormControl fullWidth variant="outlined" margin="normal">
                            <Autocomplete
                                multiple
                                fullWidth={true}
                                className={classes.textField}
                                name="nameOfCountries"
                                options={Object.values(REGIONS)}
                                getOptionLabel={(option) => option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                onChange={handleCountryChange}
                                disableCloseOnSelect
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth={true}
                                        variant="outlined"
                                        label="Which countries are you intending to roll the assessment to? (Select all that apply)"
                                        InputLabelProps={ { required: true }}
                                    />
                                )}
                            />
                        </FormControl>
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={submitAddAssessmentReq}
                            disabled={status > APP_STATUS.READY_TO_CERTIFY}
                            style={{width:'10vw', backgroundColor:'#052F44', color:'white', borderRadius:'15px'}}
                        >
                            Submit
                        </Button>
                    </form>
                )}
            </Container>


            <LoaderWithBackDrop loading={loading} />
        </Container>
    );
}

function CertifiedModule(props) {

    const {application_id, status} = props;

    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [isAssessmentModuleShown, setIsAssessmentModuleShown] = useState(false);
    const [submittedFormData, setSubmittedFormData] = useState(null);
    const [selectedAssessment, setSelectedAssessment] = useState(null);
    const {state = {}, search} = useLocation();
    const [appData, setAppData] = useState(null);
    const handleSelectedCard = (selectedCardValue) => {
        setSelectedAssessment(selectedCardValue);
    };

    const handleFormSubmit = (formData) => {
        setSubmittedFormData(formData);
    };

    const key_desc = {
        assessmentType: "Type of Assessment",
        noOfUsers: "Expected number of users",
        onlineSystem: "Delivery mode of assessment",
        callBackUrl: "Call Back URL",
        callBackKey: "Call Back Key",
        requirements:"Details of requirements",
        requirementDesc:"Description of requirements",
        nameOfCountries: "Target Countries"
    }

    const getAssessmentTypeLabel = (value) => {
        switch (value) {
            case 1:
                return 'DQ Selected Pre and Post Assessment';
            case 2:
                return 'DQ Selected Post Assessment Only';
            case 3:
                return 'Digital Citizenship Test';
            case 4:
                return 'Digital Parenting Test';
            case 5:
                return 'Custom Pre and Post Assessment';
            case 6:
                return 'Custom Post Assessment Only';
            default:
                return ' ';
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (status > APP_STATUS.READY_TO_CERTIFY) {
                try {
                    setLoading(true);
                    const result = await getApplicationService(application_id);
                    const onlineSystem = ONLINE_SYSTEM_MAP[result.data.assessment_req_info.client_own_platform_info].label ?
                        ONLINE_SYSTEM_MAP[result.data.assessment_req_info.client_own_platform_info].label : result.data.assessment_req_info.client_own_platform_info;
                    const assessmentTypeLabel = getAssessmentTypeLabel(result.data.assessment_req_info.assess_type);
                    if (result && result.data.assessment_req_info && !result.error) {
                        const postedData = {
                            'assessmentType': assessmentTypeLabel,
                            'noOfUsers': result.data.assessment_req_info.expected_users,
                            'onlineSystem': onlineSystem,
                            'callBackUrl': result.data.assessment_req_info.client_own_callback_url,
                            'callBackKey': result.data.assessment_req_info.client_own_callback_key,
                            // 'onlinePlatformDesc': result.data.assessment_req_info.client_own_platform_info_desc,
                            'requirements': result.data.assessment_req_info.client_requirements_info,
                            'requirementDesc': result.data.assessment_req_info.client_requirements_info_desc,
                            'nameOfCountries': result.data.assessment_req_info.intended_name_of_countries
                        };
                        setSubmittedFormData(postedData);
                        console.log(result);
                    } else {
                        console.log('No data exists or error fetching data');
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchData().then(r => console.log(r));
    }, [application_id, status]);
    const updateAppData = (newData) => {
        setAppData(newData);
    };


    const countriesInitialValues = submittedFormData && submittedFormData.nameOfCountries
        ? submittedFormData.nameOfCountries.split(', ').map(value => Object.values(REGIONS).find(option => option.value === value)).filter(Boolean)
        : [];

    const handleClick = event => {
        setIsAssessmentModuleShown(current => !current);
    };

    return (
        <>
            {
                status === APP_STATUS.READY_TO_CERTIFY ?
                    <Container className={classes.container}>
                        {!isAssessmentModuleShown ? (
                            <div>
                                <Container className={classes.container}>
                                    <Typography component="h1" variant="h5" color="inherit" style={{ fontWeight: 'bold', textAlign:'center' }}>
                                        Add Impact Tracking Tools with Powered by DQ Measured
                                    </Typography>
                                    <Typography color="inherit" style={{textAlign:'center', padding:'10px 0' }}>
                                        Assess learner’s digital literacy levels and monitor intervention efficacy with a global benchmark
                                    </Typography>
                                    <Grid container className={classes.sectionContainer}>
                                        <Grid item className={classes.sectionItem}>
                                            <img style={{ width: "25%", marginTop:'-80px'}} src={DQAssessment}></img>
                                            <Typography component="h1" variant="h5" color="inherit" style={{ textAlign:'center', fontSize:'20px', padding:'17px 0' }}>
                                                Curated assessments tailored to program specifics to measure learners’ digital literacy levels
                                            </Typography>
                                            <ul style={{fontSize:'16px', textAlign:'left'}}>
                                                <li>Learners are assessed via a mobile, web-based platform or through
                                                    your program via API
                                                </li>
                                                <li>Provide learners with DQ Scorecard detailing their digital literacy
                                                    levels compared to a global benchmark
                                                </li>
                                                <li>White-label option is available to ensure brand consistency
                                                </li>
                                            </ul>
                                        </Grid>
                                        <Grid item className={classes.sectionItem}>
                                            <img style={{ width: "25%", marginTop:'-80px'}} src={programDashboard}></img>
                                            <Typography component="h1" variant="h5" color="inherit" style={{ textAlign:'center', fontSize:'20px',padding:'17px 0'}}>
                                                Real-time monitoring tool to track program impact for program providers
                                            </Typography>
                                            <ul style={{fontSize: '16px', textAlign: 'left'}}>
                                                <li>Track digital literacy levels of program participants
                                                </li>
                                                <li>Monitor program reach and participation with geographic and
                                                    demographic filters (region, age, gender, socioeconomic status)
                                                </li>
                                                <li>Demonstrate program impact by analyzing learners' improvement rate
                                                </li>
                                                <li>Access a global databank to compare your nation’s digital literacy levels against 100+ countries
                                                </li>
                                            </ul>
                                        </Grid>
                                    </Grid>
                                </Container>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={handleClick}
                                    style={{backgroundColor:'#052F44', color:'white', borderRadius:'15px'}}
                                >
                                    Next
                                </Button>
                            </div>
                        ) :   <AssessmentModule {...props} onSubmit={handleFormSubmit} handleButtonClick={handleSelectedCard} updateAppData={updateAppData}/>}
                            <LoaderWithBackDrop loading={loading} />
                                </Container> : <Container className={classes.container}>
                                    <Grid container justifyContent="center" alignItems="center">
                                    <Grid item md={9} style={{textAlign:'center'}}>
                                        {status === APP_STATUS.ASSESSMENTS_READY ?
                                            (
                                                <Typography component="h1" variant="h5" color="inherit" style={{ fontWeight: 'bold', textAlign:'center' }}>
                                                    "Assessment is Available Now!"
                                                </Typography>
                                            )
                                             :
                                             (
                                                 <Container className={classes.container}>
                                                     <Grid md={12} style={{ textAlign: 'center' }}>
                                                         <Typography component="h1" variant="h5" color="inherit" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                             Your application is currently under review. You will receive an email notification once the assessment has been finalized for you to review next. Thank you.
                                                         </Typography>
                                                     </Grid>
                                                     <Grid container justifyContent="center" alignItems="top" style={{ marginTop:'15px'}}>
                                                         <Grid item md={10}>
                                                             {submittedFormData && (
                                                                 <Card className={classes.assessmentCard} style={{ width: '100%' }}>
                                                                     <CardContent>
                                                                          <Grid container spacing={3} style={{marginTop:'25px',}}>
                                                                                 <Grid item xs={12} className={classes.title}>
                                                                                     <Typography component="h1" variant="h5" style={{fontWeight:'bold'}}>Assessment Information</Typography>
                                                                                 </Grid>
                                                                                 <Grid item xs={12}>
                                                                                     <Divider style={{ backgroundColor: '#052F44',  marginBottom:'15px', }}/>
                                                                                 </Grid>
                                                                                     {Object.entries(submittedFormData).map(([key, value]) => value &&(
                                                                                         <Grid item xs={12} key={key}>
                                                                                             <Grid container justify="center" alignItems="flex-start">
                                                                                                 <Grid item xs={6}>
                                                                                                     <Typography variant="h6" className={classes.label}>{key_desc[key]}</Typography>
                                                                                                 </Grid>
                                                                                                 <Grid item xs={6}>
                                                                                                     <Typography variant="body1" className={classes.value}>{typeof submittedFormData[key] === 'function' ? submittedFormData[key](value) : value}</Typography>
                                                                                                 </Grid>
                                                                                             </Grid>
                                                                                         </Grid>
                                                                                     ))}
                                                                          </Grid>
                                                                     </CardContent>
                                                                 </Card>

                                                             )}
                                                         </Grid>
                                                     </Grid>
                                                 </Container>
                                             )}
                                    </Grid>
                                    </Grid>
                    </Container>
            }

        </>
    );
}

export default CertifiedModule;