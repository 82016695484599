import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import React from "react";
import {CURRENT_VERSION} from "../../utils/constants";

const Copyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {' © 2024 '}
            <Link color="inherit" href="https://dqlab.net/">
            DQ LAB Pte Ltd.
            </Link>{' '}
            {'All rights reserved.'}
            {/* {new Date().getFullYear()}
            {`. (v-${CURRENT_VERSION})`} */}
        </Typography>
    );
}

export default Copyright;