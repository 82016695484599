import {useEffect, useState} from 'react';
import {getAppDetailService} from "../../../services/app.service";
import {getCompanyInfoService} from "../../../services/company.service";

export function useAppDetails(application_id) {

    const [appDetails, setAppDetails] = useState({});

    const loadAppDetails = async () => {
        try {
            const result = await getAppDetailService(application_id);
            const result2 = await getCompanyInfoService();
            if (!result.error && !result2.error) {
                const program_info = result.data.program_info;
                const company_info = result2.data.comp;

                let details = {programInfo: {}, companyInfo: {}};
                if (program_info) {
                    details.programInfo = programMap(program_info);
                }
                if(company_info) {
                    details.companyInfo = companyMap(company_info);
                }
                setAppDetails(details);
            }
        } catch (e) {
            // already toasted the error
        }
    }

    useEffect( ()=> {
        if(application_id) {
            loadAppDetails().then((res) => `app details fetch initiated, ${res}`);
        }
    }, [])

    return appDetails;
}

function programMap(program_info) {
    return {
        ProgramName: program_info.program_name,
        ProgramAims: program_info.program_aims,
        ProgramTarget: program_info.program_target,
        ProgramTargetOther: program_info.program_target.includes('Others') ? program_info.program_target_other : '',
        ProgramTargetMinAge: (program_info.program_target.includes('Teens') ||
            program_info.program_target.includes('Youth') ||
            program_info.program_target.includes('EarlyYears') ||
            program_info.program_target.includes('Children')) ?
            program_info.program_target_min_age : '',
        ProgramTargetMaxAge: (program_info.program_target.includes('Teens') ||
            program_info.program_target.includes('Youth') ||
            program_info.program_target.includes('EarlyYears') ||
            program_info.program_target.includes('Children')) ?
            program_info.program_target_max_age : '',
        TargetAge: program_info.target_age,
        TargetCountry: program_info.target_country,
        ResourcesType: program_info.resources_type,
        ResourcesTypeOther: program_info.resources_type.includes('Others') ? program_info.resources_type_other : '',
        ResourcesFormat: program_info.resources_format,
        ResourcesFormatOther: program_info.resources_format.includes('Others') ? program_info.resources_format_other : '',
        ProgramVenue: program_info.program_venue,
        ProgramVenueOther: program_info.program_venue.includes('Others') ? program_info.program_venue_other : '',
        ProgramDuration: program_info.program_duration,
        OtherDurationHours: program_info.program_duration === 'morethan20hours' ? program_info.program_duration_other : '',
        StartYear: program_info.start_year,
        Status: program_info.status,
        Links: program_info.links,
        ProgramPrice: program_info.program_price,
        Success: program_info.success,
        SuccessMeasure: program_info.success_measure,
        RefFramework: program_info.ref_framework,
        RefAlignment: program_info.ref_alignment,
        KPITrack: program_info.kpi_track,
        Devices: program_info.devices,
        DeviceTypeOther: program_info.devices.includes('Others') ? program_info.devices_other : '',
    }
}


function companyMap(company_info) {
    return {
        NameOrg: company_info.name_org,
        NameContact: company_info.name_contact,
        NameDept: company_info.name_dept,
        Region: company_info.region,
        OrgType: company_info.org_type,
        OrganizationEntered:company_info.organization_entered,
        Sector: company_info.sector,
        SectorEntered: company_info.sector_entered,
        History: company_info.history,
        SizeEmployee: company_info.size_employee,
        OrgVision: company_info.org_vision,
    }
}