export const RESOURCES_FORMAT = [
    {
        "value": "InteractiveWebsite",
        "label": "Interactive website"
    },
    {
        "value": "DownloadableDocument",
        "label": "Downloadable documents (Word, PDF, slides, etc.)"
    },
    {
        "value": "TextPictureEBook",
        "label": "Textbook / Picture book / eBook"
    },
    {
        "value": "Game",
        "label": "Game"
    },
    {
        "value": "Podcast",
        "label": "Podcast"
    },
    {
        "value": "VideoAnimation",
        "label": "Video / Animation"
    },
    {
        "value": "OfflineSeminarWorkshop",
        "label": "Offline seminar / Workshop"
    },
    {
        "value": "Webinar",
        "label": "Webinar"
    },
    {
        "value": "Others",
        "label": "Others"
    }
];

export const RESOURCES_FORMAT_MAP = {
    "InteractiveWebsite": {
        "label": "Interactive Website"
    },
    "DownloadableDocument": {
        "label": "Downloadable documents (Word, PDF, slides, etc.)"
    },
    "TextPictureEBook": {
        "label": "Textbook / Picture book / eBook"
    },
    "Game": {
        "label": "Game"
    },
    "Podcast": {
        "label": "Podcast"
    },
    "VideoAnimation": {
        "label": "Video / Animation"
    },
    "OfflineSeminarWorkshop": {
        "label": "Offline seminar / Workshop"
    },
    "WWebinar": {
        "label": "Webinar"
    },
    "Others": {
        "label": "Others"
    }
};