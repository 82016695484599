export const HIST_DURATIONS = [
    {
        "value": "lessthan1year",
        "label": "Less than a year"
    },
    {
        "value": "1-5years",
        "label": "1-5 years"
    },
    {
        "value": "6-10years",
        "label": "6-10 years"
    },
    {
        "value": "11-15years",
        "label": "11-15 years"
    },
    {
        "value": "16-20years",
        "label": "16-20 years"
    },
    {
        "value": "morethan20years",
        "label": "More than 20 years"
    }
];

export const HIST_DURATIONS_MAP = {
    "lessthan1year": {
        "label": "Less than a year"
    },
    "1-5years": {
        "label": "1-5 years"
    },
    "6-10years": {
        "label": "6-10 years"
    },
    "11-15years": {
        "label": "11-15 years"
    },
    "16-20years": {
        "label": "16-20 years"
    },
    "morethan20years": {
        "label": "More than 20 years"
    }
}