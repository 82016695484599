import React from 'react';
import {Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ReportPage from "./PageContainer.report.comp";

function OrgDetailRow(props) {
    const {title, value} = props;
    return <tr>
        <td style={{border: '1px solid black', paddingRight: 20, paddingLeft: 20, paddingTop: 10, paddingBottom: 10}}>
            <Typography variant={'h4'} style={{color: "deepskyblue", fontWeight: "bold", fontSize: "20px", textAlign: "left"}}>
                {title}
            </Typography>
        </td>
        <td style={{border: '1px solid black', paddingRight: 20, paddingLeft: 20}}>
            <Typography style={{color: "black", fontWeight: "lighter", fontSize: "18px", fontFamily: 'Source Sans Pro', textAlign: "left", wordWrap: 'break-word'}}>
                {value}
            </Typography>
        </td>
    </tr>
}

export default function Page4(props) {

    const {chunk, index, pageNumber, onlyTable = false} = props;

    return (
        <>
            <ReportPage pageNumber={pageNumber}>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'flex-start'}
                    alignItems={'flex-start'}
                    height={'inherit'}
                    width={'inherit'}
                    gridRowGap={10}
                    px={10}
                    style={{ paddingTop: index === 0 ? '0px' : '70px' }}
                >
                    {!onlyTable && index === 0 && (
                        <Box id={"1.2-program-overview"} flexBasis={'10%'}>
                            <Typography
                                variant={'h3'}
                                style={{
                                    color: "orangered",
                                    fontWeight: "lighter",
                                    fontFamily: 'Raleway',
                                    paddingTop: '70px',
                                    fontSize: '22px'
                                }}
                            >
                                1.2 Program Overview
                            </Typography>
                        </Box>
                    )}
                    <Box width={'100%'} overflowX="auto">
                        <table style={{ borderCollapse: 'collapse', width: '100%', tableLayout: 'fixed' }}>
                            <tbody>
                            {chunk.map((row, idx) => (
                                <OrgDetailRow key={idx} title={row.title} value={row.value} />
                            ))}
                            </tbody>
                        </table>
                    </Box>
                </Box>
            </ReportPage>
        </>
    );
}