import * as fflate from 'fflate';

async function compress(data) {
    const compressedData = await fflate.gzipSync(data);
    return compressedData;
}

async function uncompress(data) {
    const uncompressedData = await fflate.gunzipSync(data);
    return new TextDecoder().decode(uncompressedData);
}

export const ECC_cipher_helper = {
    encryptData: async (dataToEncrypt, publicKey) => {
        if (!publicKey) {
            return; // Public key not yet fetched
        }
        // Import the public key from JWK format
        const importedPublicKey = await crypto.subtle.importKey(
            'jwk',
            publicKey,
            {
                name: 'ECDH',
                namedCurve: 'P-256'
            },
            true, // Extractable
            []
        );

        // Generate an ephemeral key pair for ECDH
        const ephemeralKeyPair = await crypto.subtle.generateKey(
            {
                name: 'ECDH',
                namedCurve: 'P-256'
            },
            true, // Extractable
            ['deriveKey', 'deriveBits']
        );

        // Derive a shared secret using ECDH
        const sharedSecret = await crypto.subtle.deriveBits(
            {
                name: 'ECDH',
                public: importedPublicKey
            },
            ephemeralKeyPair.privateKey,
            256
        );

        const sharedSecretKey = await crypto.subtle.importKey(
            'raw', // Key format
            sharedSecret,
            { name: 'AES-GCM' }, // Algorithm
            true, // Extractable
            ['encrypt', 'decrypt'] // Key usages
        );

        // Encrypt the data using AES-GCM
        const iv = crypto.getRandomValues(new Uint8Array(16)); // 16 bytes for AES-GCM
        const encryptedData = await crypto.subtle.encrypt(
            {
                name: 'AES-GCM',
                iv: iv
            },
            sharedSecretKey,
            new TextEncoder().encode(dataToEncrypt)
        );

        // Export the ephemeral public key to JWK format
        const ephemeralPublicKeyJwk = await crypto.subtle.exportKey('jwk', ephemeralKeyPair.publicKey);

        return {
            data: Buffer.from(encryptedData).toString('base64'),
            iv: Buffer.from(iv).toString('base64'),
            ephemeralPublicKey: ephemeralPublicKeyJwk
        };
    },

    decryptData: async (data_object, privateKey) => {
        const {
            data: encryptedData,
            iv: iv,
            ephemeralPublicKey: ephemeralPublicKeyJwk,
            compressed_response: decompress_data
        } = data_object;

        if (!privateKey) {
            return; // Private key not yet fetched
        }

        // Import the private key from JWK format
        const importedPrivateKey = await crypto.subtle.importKey(
            'jwk',
            privateKey,
            {
                name: 'ECDH',
                namedCurve: 'P-256'
            },
            true, // Extractable
            ['deriveKey', 'deriveBits']
        );

        // Import the ephemeral public key from JWK format
        const importedEphemeralPublicKey = await crypto.subtle.importKey(
            'jwk',
            ephemeralPublicKeyJwk,
            {
                name: 'ECDH',
                namedCurve: 'P-256'
            },
            true, // Extractable
            [] // No key usages needed for the ephemeral public key
        );

        // Derive a shared secret using ECDH
        const sharedSecret = await crypto.subtle.deriveBits(
            {
                name: 'ECDH',
                public: importedEphemeralPublicKey // Use the imported ephemeral public key
            },
            importedPrivateKey,
            256
        );

        // Import the shared secret as a CryptoKey
        const sharedSecretKey = await crypto.subtle.importKey(
            'raw',
            sharedSecret,
            { name: 'AES-GCM' },
            true,
            ['encrypt', 'decrypt']
        );

        // Decrypt the data using AES-GCM
        const decryptedData = await crypto.subtle.decrypt(
            {
                name: 'AES-GCM',
                iv: Buffer.from(iv, 'base64')
            },
            sharedSecretKey,
            Buffer.from(encryptedData, 'base64')
        );

        if (decompress_data) {
            const decryptedDataArray = new Uint8Array(decryptedData);
            const originalData = await uncompress(decryptedDataArray);
            return originalData;
        } else {
            return new TextDecoder().decode(decryptedData);
        }
    }

}