import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {Card, CardContent, Step, StepIcon, StepLabel, Stepper, TableContainer} from "@material-ui/core";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import PaymentIcon from '@material-ui/icons/Payment';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import PrintIcon from '@material-ui/icons/Print';
import ApplicationModule from "./modules/Application.module";
import PaymentModule from "./modules/Payment.module";
import CollectionModule from "./modules/Collection.module";
import CertifiedModule from "./modules/Certified.module";
import AnalyticsModule from "./modules/Assessment&Analytics.module";
import DataMindModule from "./modules/DataMind.module";
import ReportModule from "./modules/Report.module";
import {APP_STATUS, APP_STATUS_VERBOSE, SEAL_TYPE, SEAL_TYPE_VERBOSE} from "../../utils/constants";
import {getApplicationService} from "../../services/app.service";
import {useAppDetails} from "./hooks/useAppDetails.hook";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import Grid from "@material-ui/core/Grid";
import AOS from "aos";

const useStyles = makeStyles((theme) => ({
    // title: {
    //     fontWeight: "bold",
    // },
    stepper: {
        borderRadius: '15px',
        paddingBottom:'-50px !important',
    },
    card: {
        display:'flex',
        flexDirection:'column',
        backgroundColor:theme.palette.background.cards,
        borderRadius: '15px',
        border: '1px solid',
        borderColor:  theme.palette.secondary.main,
        height: '150px',
        paddingTop:theme.spacing(2),
        alignItems:'center',
        justifyContent:'center',
        textAlign:'center',

    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: "500",
        marginBottom: theme.spacing(1),
        fontSize: '1.4rem',
    },
    description: {
        color: theme.palette.text.secondary,
        fontWeight: "400",

    },
}));

const iconStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        borderRadius: "50%",
        padding: 8,
        marginTop: -10,
        borderStyle: "solid",
        borderWidth: "medium",
        borderColor: "#11888B",
        zIndex: 1
    },
    active_container: {
        borderColor: theme.palette.primary.main,
        border:'2px solid',
    },
    completed_container: {
        backgroundColor: '#11888B',
    },
    incomplete_container: {
        backgroundColor: "grey",
        borderColor: "grey",
    },
    icon: {
        color: "white"
    }
}))


let STEPS = [
    {
        label: 'Application',
        icon: InsertDriveFileIcon,
        component: ApplicationModule,
        maximum_status: APP_STATUS.DRAFT,
    },
    ...(process.env.REACT_APP_SEAL_NO_PAY === '1' ? [] : [{
        label: 'Payment',
        icon: PaymentIcon,
        component: PaymentModule,
        maximum_status: APP_STATUS.PENDING_APPROVAL
    }]),
    {
        label: 'Materials',
        icon: FilterNoneIcon,
        component: CollectionModule,
        maximum_status: APP_STATUS.HALTED_REPORT
    },
    {
        label: 'Report',
        icon: PrintIcon,
        component: ReportModule,
        maximum_status: APP_STATUS.COMPLETED
    }
];

let STEPS_BASE_COUNT = STEPS.length;

function IconComponent(props) {

    const classes = iconStyles();

    const {active, completed, icon: Icon} = props;

    const icon_container_class = active ? `${classes.active_container} ${classes.completed_container}` :
        completed ? classes.completed_container : classes.incomplete_container

    // const icon_container_class = active ? `${classes.active_container} ${classes.completed_container}` :
    //     classes.completed_container

    return <div className={`${classes.container} ${icon_container_class}`}>
        <Icon className={classes.icon}/>
    </div>
}

function statusToStepConversion(status) {
    for(const step_index in STEPS) {
        if(status <= STEPS[step_index].maximum_status) return parseInt(step_index);
    }
    return STEPS.length - 1;
}

function statusToStepConversion_rev2(status) { //case: if you have to un-block more than 1 steps with same maximum_status value.
    let matchedIndex = 0;
    let firstGreaterStatus = 0;

    for(let step_index = 0; step_index < STEPS.length; step_index++) {
        if(status <= STEPS[step_index].maximum_status) {
            if (matchedIndex === 0) {
                matchedIndex = step_index;
                firstGreaterStatus = STEPS[step_index].maximum_status;
            }

            if (firstGreaterStatus !== STEPS[step_index].maximum_status) {
                return matchedIndex;
            } else {
                matchedIndex = step_index;
            }
        }
    }

    return STEPS.length - 1;
}

function Details(props) {
    const classes = useStyles();
    const history = useHistory();
    const {state = {}, search} = useLocation();
    const [app, setApp] = useState(state);
    const {application_id = new URLSearchParams(search).get('app_id'), app_status = APP_STATUS.DRAFT, dqseal_type} = app;
    const [status, setStatus] = useState(app_status);
    const [activeStep, setActiveStep] = useState(statusToStepConversion(status));
    const [selectedStep, setSelectedStep] = useState(activeStep);
    const ActiveComponent = STEPS[selectedStep].component;
    const [loading, setLoading] = useState(false);

    const {programInfo = {}, companyInfo = {}} = useAppDetails(application_id);

    useEffect(() => {
        if(application_id) {
            retrieveApplication(application_id).then();
        } else {
            returnToMainMenu();
        }
    }, [])

    useEffect(() => {
        if (app.dqseal_type === SEAL_TYPE.CERTIFY) {
            STEPS.push({
                label: 'Impact Tracking',
                icon: FilterNoneIcon,
                component: CertifiedModule,
                maximum_status: APP_STATUS.READY_TO_CERTIFY
            });
            STEPS.push({
                label: 'Assessment & Analytics',
                icon: FilterNoneIcon,
                component: AnalyticsModule,
                maximum_status: APP_STATUS.ASSESSMENTS_READY // ask ahmed
            });
            STEPS = STEPS.slice(0, STEPS_BASE_COUNT + 2);
        } else {
            STEPS = STEPS.slice(0, STEPS_BASE_COUNT);
        }
    }, [app]);

    function returnToMainMenu() {
        history.push('/application/list');
    }

    function updateStepByStatus(status, navigate = false) {
        const step = statusToStepConversion(status)
        console.log("Selected Step", step);
        setActiveStep(step);
        if(navigate) setSelectedStep(step);
    }

    async function retrieveApplication(application_id) {
        try {
            setLoading(true);
            const result = await getApplicationService(application_id);
            if(!result.error && result.data) {
                const app = result.data;
                setApp(app);
                const {app_status} =  app;
                setStatus(app_status);
                updateStepByStatus(app_status, true);
            } else {
                returnToMainMenu();
            }
            setLoading(false);
        }
        catch (e) {
            //already toasted
            setLoading(false);
        }
    }

    async function updateStatus(status, navigate = false) {
        try {
            //const result = await updateAppStatus(application_id, status);
            //if(!result.error && result.data.success) {
            setStatus(status);
            updateStepByStatus(status, navigate);
            //}
        }
        catch (e) {
            //already toasted
        }
    }

    function forwardStep() {
        const nextStep = selectedStep + 1;
        if(nextStep <= activeStep) setSelectedStep(nextStep);
    }

    const applicationProps = {application_id, status, updateStatus, programInfo, companyInfo, app};
    // const title = `${(programInfo.ProgramName || "")} ${companyInfo.NameOrg ? `(${companyInfo.NameOrg})` : ""}`
    const programTitle = `${programInfo.ProgramName || ""}`;
    const orgTitle = `${companyInfo.NameOrg ? `${companyInfo.NameOrg}` : ""}`;
    useEffect(() => {
        AOS.init({
            duration: 500,
            fading: 'fade-in',
            once: true,
        });
    }, []);

    return (
        <Container maxWidth={false}>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={3} data-aos="fade-in" data-aos-delay="200">
                    <Card className={classes.card}>
                        <CardContent style={{overflow:'scroll',}}>
                            <Typography className={classes.title} variant={'h5'} >
                                Program Title
                            </Typography>
                            <Typography className={classes.description}>
                                {programTitle}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} sm={3} data-aos="fade-in" data-aos-delay="400">
                    <Card className={classes.card}>
                        <CardContent style={{overflow:'scroll',}}>
                            <Typography className={classes.title} variant={'h5'} >
                                Organization Name
                            </Typography>
                            <Typography className={classes.description}>
                                {orgTitle}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} sm={3} data-aos="fade-in" data-aos-delay="600">
                    <Card className={classes.card}>
                        <CardContent style={{overflow:'scroll',}}>
                            <Typography className={classes.title} variant={'h5'} style={{marginTop:'15px'}}>
                                Application ID
                            </Typography>
                            <Typography className={classes.description}>
                                {application_id}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} sm={3} data-aos="fade-in" data-aos-delay="800">
                    <Card className={classes.card}>
                        <CardContent style={{overflow:'scroll',}}>
                            <Typography className={classes.title} variant={'h5'}>
                                Application Status
                            </Typography>
                            <Typography className={classes.description}>
                               {APP_STATUS_VERBOSE[status]}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <br/>
            <Stepper alternativeLabel activeStep={activeStep} className={classes.stepper}>
                {
                    STEPS.map((step, i) => (
                        <Step
                            key={step.label}
                            onClick={() => (i <= activeStep ? setSelectedStep(i) : false)}
                            style={{cursor: (i <= activeStep ? "pointer" : "default")}}
                        >
                            <StepLabel StepIconComponent={IconComponent} StepIconProps={{icon: step.icon, active: selectedStep === i, completed: i <= activeStep}}>{step.label}</StepLabel>
                        </Step>
                    ))
                }
            </Stepper>
            <br/>
            {
                <ActiveComponent updateStatus={updateStatus} forwardStep={forwardStep} {...applicationProps}/>
            }
            <LoaderWithBackDrop loading={loading} />
        </Container>
    );
}

export default Details;