import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

function CoverageChart(props) {
    const { xMax, coverage } = props;

    const formatted_data = {
        labels: coverage.map((item) => item.title),
        datasets: [{
            label: 'Coverage',
            data: coverage.map((item) => item.percent.toFixed(2)),
            backgroundColor: coverage.map((item) => item.color),
            barThickness: 20,
        }]
    };

    const options = {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                beginAtZero: true,
                min: 0,
                max: xMax,
                grid: {
                    display: true
                },
                ticks: {
                    stepSize: 10
                }
            },
            y: {
                grid: {
                    display: false
                },
                ticks: {
                    callback: function(value, index, ticks) {
                        return coverage[index].dqc_no.replace(/DQC0?/, "DQ");
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                anchor: 'end',
                align: function(context) {
                    const value = Number(context.dataset.data[context.dataIndex]);
                    const maxValue = xMax;
                    return value >= (maxValue * 0.90) ? 'start' : 'end';
                },
                clamp: true,
                clip: false,
                padding: {
                    right: function(context) {
                        const value = Number(context.dataset.data[context.dataIndex]);
                        const maxValue = xMax;
                        return value >= (maxValue * 0.90) ? 6 : 10;
                    }
                },
                formatter: function(value, context) {
                    return `${value}%`;
                }
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        const item = coverage[context.dataIndex];
                        return `${item.dqc_no.replace(/DQC0?/, "DQ")} ${item.title}: ${item.percent.toFixed(2)}%`;
                    }
                }
            }
        },
    };
    const chartHeight = coverage.length * 3.33;

    return <Bar data={formatted_data} options={options} />;
}

export default CoverageChart;
