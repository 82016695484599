import React from 'react';
import Bg3 from "../../../../assets/images/report/bg3.png";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";

export default function Page3(props) {

    const {pageNumber} = props;

    return <ReportPage pageNumber={pageNumber}>
        <Box id="1.-introduction" display={'flex'} flexDirection={'row'} alignItems={'flex-start'} justifyContent={"flex-start"} height={"inherit"} gridColumnGap={"3%"} px={10}>
            <Box display={'flex'} flexDirection={'column'} >
                <Typography id='title' variant={'h3'} style={{color: "deepskyblue", fontWeight: "bold", fontFamily: "Raleway", fontSize: '24px', paddingTop: 70}}>
                    1. Introduction
                </Typography>
                <Box id="1.1-about-the-dq-standards-conformity-test-report">
                <Typography
                    id={"1.1-about-the-dq-standards-conformity-test-report"}
                    variant={'h3'}
                    style={{
                        color: "orangered",
                        fontWeight: "lighter",
                        fontFamily: 'Raleway',
                        paddingTop: '30px',
                        fontSize: '22px'
                    }}
                >
                    1.1 About the DQ Standards Conformity Test Report
                </Typography>
                </Box>
                <Box>
                    <Typography
                        style={{
                            color: "black",
                            fontWeight: "lighter",
                            fontFamily: 'Source Sans Pro',
                            paddingTop: '30px',
                            fontSize: '18px',
                            textAlign: 'justify'
                        }}
                    >
                        The DQ Standards Conformity Test (DQ SCT) Report is a summary report designed for digital literacy program provider to understand their alignment with the
                        <Typography component={'span'} style={{color: "inherit", fontWeight: "bold", fontSize: "inherit"}}> IEEE DQ Global Standards (IEEE 3527.1&trade;)
                        </Typography>
                        . A high degree of alignment represents that your program delivers high-quality learning experience to users that is aligned with the 32 DQ Competencies. This Report can be used to identify areas of strengths and areas for further improvement of the program.
                    </Typography>
                    <Typography
                        style={{
                            color: "black",
                            fontWeight: "lighter",
                            fontFamily: 'Source Sans Pro',
                            paddingTop: '30px',
                            fontSize: '18px',
                            textAlign: 'justify'
                        }}
                    >
                        “Powered by DQ” is a seal of trust that a digital literacy intervention or program is credible and impactful based on
                        <Typography component={'span'} style={{color: "inherit", fontWeight: "bold", fontSize: "inherit"}}> IEEE DQ Global Standards (IEEE 3527.1&trade;)
                        </Typography>
                        . With Powered by DQ, your program will be part of the global coordination project to achieve our collective mission of digital empowerment for all.
                    </Typography>
                </Box>
            </Box>
        </Box>
    </ReportPage>
}