import React, { useState, useEffect } from "react";
import {useHistory, useLocation} from "react-router-dom";
import {
    Container,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Typography,
    Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field, useFormikContext } from "formik";
import axios from "axios";
import { addProgramInfoService } from "../../services/app.service";
import { initResetPassword } from "../../services/auth.service";
import { PROGRAM_TYPES } from "../../utils/META/cat_106";
import { PROGRAM_TARGETS } from "../../utils/META/cat_108";
import { TARGET_AGE } from "../../utils/META/cat_109";
import { PROGRAM_VENUE } from "../../utils/META/cat_111";
import { PROGRAM_DURATION } from "../../utils/META/cat_112";
import { PROGRAM_STATUS } from "../../utils/META/cat_121";
import { toast } from "../../utils/utils";
import {SEAL_TYPE, TOAST_TYPES} from "../../utils/constants";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import InfoIcon from '@material-ui/icons/Info'
import {SEAL_TYPES} from "../../utils/META/cat_seal_types";
import {REGIONS} from "../../utils/META/cat_101";
import { Autocomplete } from "@mui/material";
import { AutocompleteRenderInputParams } from '@mui/material';
import {PROGRAM_COST} from "../../utils/META/cat_113";
import {RESOURCES_TYPE} from "../../utils/META/cat_114";
import {RESOURCES_FORMAT} from "../../utils/META/cat_115";
import {DEVICES} from "../../utils/META/cat_116";
const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        minWidth: "100%",
    },
    section: {
        marginBottom: theme.spacing(4),
    },
    instruction: {
        margin: theme.spacing(3,3,3,0),
    },

}));

function validateWordLength(value, max_length) {
    const length = value ? value.match(/\w+/g).length : 0;
    const error = length > max_length;
    const error_message = error ? 'Word limit exceeded' : undefined;
    return { length, max_length, error, error_message };
}

function MaxLengthHelperText({ length, max_length, error, error_message }) {
    return <Box display={'flex'} flexDirection={'row'}>
        {error && <Box mr={'auto'}>{error_message}</Box>}
        <Box ml={'auto'}>{length}/{max_length}</Box>
    </Box>
}

const FormTextField = ({ label, name, required = true, ...rest }) => (
    <Field
        as={TextField}
        label={label}
        name={name}
        fullWidth
        required={required}
        margin="normal"
        variant="outlined"
        {...rest}
    />
);

const FormSelect = ({ label, name, options }) => (
    <FormControl fullWidth variant="outlined" margin="normal" required>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Field as={Select} label={label} name={name}>
            {options.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </Field>
    </FormControl>
);

const FormComponent = ({ disableSubmit, setFieldValue }) => {

    const classes = useStyles();
    const { values } = useFormikContext(); // get current form values
    // const descValidProps = validateWordLength(values.ProgramDescription, 500);
    const aimsObjValidProps = validateWordLength(values.ProgramAims, 250);
    const targetAudienceOptions = Object.values(PROGRAM_TARGETS);
    const countriesOptions = Object.values(REGIONS);
    const programVenueOptions = Object.values(PROGRAM_VENUE);
    const resourcesTypeOptions = Object.values(RESOURCES_TYPE);
    const resourcesFormatOptions = Object.values(RESOURCES_FORMAT);
    const devicesOptions = Object.values(DEVICES);
    const [durationError, setDurationError] = useState(false);

    const handleValueCheck = (value) => {
        if (value && parseInt(value) > 20) {
            setDurationError(false);
        } else {
            setDurationError(true);
        }
    };

    function parseInitialValues(initialValue, options) {
        return initialValue
            ? initialValue.split(', ').map(value => options.find(option => option.value === value)).filter(Boolean)
            : [];
    }

    const targetAudienceInitialValues = parseInitialValues(values.ProgramTarget, targetAudienceOptions);
    const countriesInitialValues = parseInitialValues(values.TargetCountry, countriesOptions);
    const venueInitialValues = parseInitialValues(values.ProgramVenue, programVenueOptions);
    const resourcesTypeInitialValues = parseInitialValues(values.ResourcesType, resourcesTypeOptions);
    const resourcesFormatInitialValues = parseInitialValues(values.ResourcesFormat, resourcesFormatOptions);
    const devicesInitialValues = parseInitialValues(values.Devices, devicesOptions);

    const handleAutocompleteChange = (fieldName) => (event, value) => {
        const stringValue = value.map(item => item.value).join(', ');
        setFieldValue(fieldName, stringValue);
    };
    // if(process.env.REACT_APP_NAME==='LOCAL' || process.env.REACT_APP_NAME==='DEVELOP') {
    //     console.log("Default Values Loaded for Local/Development Environment")
    // }
    // else  {
    //     console.log("Default Values Loaded for Production/Stage/etc.")
    // }

    return (
        <Form>
            <div className={classes.section}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Program Description
                </Typography>
                <Typography className={classes.instruction} variant="body1"><InfoIcon /> The following information will help us understand and evaluate your program more holistically .
                </Typography>
                <FormTextField label="Title of program" name="ProgramName" />
                {/*<FormSelect*/}
                {/*    label="Type of DQ-SEAL"*/}
                {/*    name="SealType"*/}
                {/*    options={SEAL_TYPES}*/}
                {/*/>*/}
                {/*<FormSelect*/}
                {/*    label="Type of program"*/}
                {/*    name="ProgramType"*/}
                {/*    options={PROGRAM_TYPES}*/}
                {/*/>*/}
                {/*{values.ProgramType === 'Others' && (*/}
                {/*    <FormTextField*/}
                {/*        label="Please specify your program type."*/}
                {/*        name="ProgramTypeOther"*/}
                {/*    />*/}
                {/*)}*/}
                <FormTextField
                    label="Objectives of program"
                    name="ProgramAims"
                    multiline={true}
                    maxRows={10}
                    helperText={<MaxLengthHelperText {...aimsObjValidProps} />}
                    error={aimsObjValidProps.error}
                    validate={() => aimsObjValidProps.error_message}
                />
                <Autocomplete
                    sx={{ marginTop: 0}}
                    multiple
                    fullWidth={true}
                    name="ResourcesType"
                    options={Object.values(RESOURCES_TYPE)}
                    value={resourcesTypeInitialValues}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    onChange={handleAutocompleteChange("ResourcesType")}
                    disableCloseOnSelect
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth={true}
                            variant="outlined"
                            label="Type of resources your program has (Select all that apply)"
                            InputLabelProps={ { required: true }}
                        />
                    )}
                />
                {values.ResourcesType && (values.ResourcesType.includes('Others') || (values.ResourcesType.length > 1 && values.ResourcesType.includes('Others'))) ? (
                    <FormTextField
                        label="Please specify your type of resources."
                        name="ResourcesTypeOther"
                    />
                ) : null}
                <Autocomplete
                    sx={{ marginTop: 3, marginBottom:1,}}
                    multiple
                    fullWidth={true}
                    name="ResourcesFormat"
                    options={Object.values(RESOURCES_FORMAT)}
                    value={resourcesFormatInitialValues}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    onChange={handleAutocompleteChange("ResourcesFormat")}
                    disableCloseOnSelect
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth={true}
                            variant="outlined"
                            label="Format of resources your program has (Select all that apply)"
                            InputLabelProps={ { required: true }}
                        />
                    )}
                />
                {values.ResourcesFormat && (values.ResourcesFormat.includes('Others') || (values.ResourcesFormat.length > 1 && values.ResourcesFormat.includes('Others'))) ? (
                    <FormTextField
                        label="Please specify your format of resources."
                        name="ResourcesFormatOther"
                    />
                ) : null}
                <Autocomplete
                    sx={{ marginTop: 3, marginBottom: 3 }}
                    multiple
                    fullWidth={true}
                    name="ProgramTarget"
                    options={Object.values(PROGRAM_TARGETS)}
                    getOptionLabel={(option) => option.label}
                    value={targetAudienceInitialValues}
                    getOptionSelected={(option, value) => option.value === value.value}
                    onChange={handleAutocompleteChange("ProgramTarget")}
                    disableCloseOnSelect
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth={true}
                            variant="outlined"
                            label="Target audience of program (Select all that apply)"
                            InputLabelProps={ { required: true }}
                        />
                    )}
                />
                {
                    (values.ProgramTarget && (values.ProgramTarget.includes('Teens') || values.ProgramTarget.includes('Youth') || values.ProgramTarget.includes('EarlyYears') || values.ProgramTarget.includes('Children'))) ? (
                        <>
                            <FormTextField
                                label="Minimum Age"
                                type="number"
                                name="ProgramTargetMinAge"
                            />
                            <FormTextField
                                label="Maximum Age"
                                type="number"
                                name="ProgramTargetMaxAge"
                            />
                        </>
                    ) : null}
                {values.ProgramTarget && (values.ProgramTarget.includes('Others') || (values.ProgramTarget.length > 1 && values.ProgramTarget.includes('Others'))) ? (
                    <FormTextField
                        label="Please specify target audience."
                        name="ProgramTargetOther"
                    />
                ) : null}
                <Autocomplete
                    sx={{ marginTop: 2, marginBottom: 2 }}
                    multiple
                    fullWidth={true}
                    name="TargetCountry"
                    options={Object.values(REGIONS)}
                    value={countriesInitialValues}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    onChange={handleAutocompleteChange("TargetCountry")}
                    disableCloseOnSelect
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth={true}
                            variant="outlined"
                            label="Target countries of program (Select all that apply)"
                            InputLabelProps={ { required: true }}
                            // placeholder="Multiple Autocomplete"
                        />
                    )}
                />
                <Autocomplete
                    sx={{ marginTop: 4}}
                    multiple
                    fullWidth={true}
                    name="Devices"
                    options={Object.values(DEVICES)}
                    value={devicesInitialValues}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    onChange={(event, newValue) => {
                        // If "Any device" is selected, clear the selection of other options
                        if (newValue.some(option => option.value === "AnyDevice")) {
                            newValue = newValue.filter(option => option.value === "AnyDevice");
                        }
                        handleAutocompleteChange("Devices")(event, newValue);
                    }}
                    disableCloseOnSelect
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth={true}
                            variant="outlined"
                            label="Device(s) needed by your target audience to use the resources (Select all that apply)"
                            InputLabelProps={ { required: true }}
                            // placeholder="Multiple Autocomplete"
                        />
                    )}
                />
                {values.Devices && (values.Devices.includes('Others') || (values.Devices.length > 1 && values.Devices.includes('Others'))) ? (
                    <FormTextField
                        label="Please specify device(s) needed."
                        name="DeviceTypeOther"
                    />
                ) : null}
                {/*<FormTextField*/}
                {/*    label="Description of program"*/}
                {/*    name="ProgramDescription"*/}
                {/*    multiline={true}*/}
                {/*    maxRows={10}*/}
                {/*    helperText={<MaxLengthHelperText {...descValidProps} />}*/}
                {/*    error={descValidProps.error}*/}
                {/*    validate={() => descValidProps.error_message}*/}
                {/*/>*/}
                <Autocomplete
                    sx={{ marginTop: 4}}
                    multiple
                    fullWidth={true}
                    name="ProgramVenue"
                    options={Object.values(PROGRAM_VENUE)}
                    value={venueInitialValues}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    onChange={handleAutocompleteChange("ProgramVenue")}
                    disableCloseOnSelect
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth={true}
                            variant="outlined"
                            label="Venue(s) where program is conducted (Select all that apply)"
                            InputLabelProps={ { required: true }}
                        />
                    )}
                />
                {values.ProgramVenue && (values.ProgramVenue.includes('Others') || (values.ProgramVenue.length > 1 && values.ProgramVenue.includes('Others'))) ? (
                    <FormTextField
                        label="Please specify venue."
                        name="ProgramVenueOther"
                    />
                ) : null}
                <FormSelect
                    label="Duration to complete the program"
                    name="ProgramDuration"
                    options={PROGRAM_DURATION}
                />
                {values.ProgramDuration === 'morethan20hours' && (
                    <FormTextField
                        label="Please specify program duration (in hours)."
                        type="number"
                        name="OtherDurationHours"
                        onBlur={(e) => handleValueCheck(e.target.value)}
                        error={durationError}
                        helperText={durationError && `Number must be greater than 20`}
                    />
                )}
                {/*<FormTextField label="Please elaborate how the program is delivered" name="ProgramDelivery" />*/}
                {/*<FormTextField*/}
                {/*    label="Duration of program (in hours)"*/}
                {/*    name="ProgramDuration"*/}
                {/*    type="number"*/}
                {/*/>*/}
                {/*<FormSelect*/}
                {/*    label="Duration of program (in hours)"*/}
                {/*    name="ProgramDuration"*/}
                {/*    options={PROGRAM_DURATION}*/}
                {/*/>*/}
                {/*<FormTextField*/}
                {/*    label="Resources required for program to be carried out"*/}
                {/*    name="RefResource"*/}
                {/*/>*/}
                <FormTextField label="Year of program launch" name="StartYear" type="number" />
                <FormSelect
                    label="Current status of program"
                    name="Status"
                    options={PROGRAM_STATUS}
                />
                <FormTextField label="Link to program" name="Links" />
                <FormSelect
                    label="Cost of program"
                    name="ProgramPrice"
                    options={PROGRAM_COST}
                />
            </div>
            <div className={classes.section}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Program Impact
                </Typography>
                <Typography className={classes.instruction} variant="body1"><InfoIcon />  The following information will help us understand and evaluate the impact of your program
                </Typography>
                <FormTextField
                    label="Definition of success for program"
                    name="Success"
                />
                <FormTextField
                    label="Measurement of success for program (e.g. reach target, social impact target)"
                    name="SuccessMeasure"
                />
                <FormTextField
                    label="Process to meet the program’s objectives"
                    name="KPITrack"
                />
                {/*<FormTextField*/}
                {/*    label="If you have a weblink that best describes your program, please provide:"*/}
                {/*    name="RefLink"*/}
                {/*    required={false}*/}
                {/*/>*/}
                <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel htmlFor="RefFramework">Is your program already aligned with existing framework?</InputLabel>
                    <Field
                        as={Select}
                        id="RefFramework"
                        value={values.RefFramework}
                        label="Is your program already aligned with existing framework?"
                        name="RefFramework"
                    >
                        <MenuItem value="yes">Yes</MenuItem>
                        <MenuItem value="no">No</MenuItem>
                    </Field>
                </FormControl>
                {values.RefFramework === 'yes' && (
                    <FormTextField
                        label="Which framework(s) does your program align with?"
                        name="RefAlignment"
                        required={false}
                    />
                )}

            </div>
            <Button type="submit" variant="contained" color="primary" disabled={disableSubmit}>
                Submit
            </Button>
        </Form>
    );

};

const CreateApplication = () => {
    const classes = useStyles();
    const initialValues = {
        ProgramName: 'Sample Program',
        // ProgramType: 'Research',
        // ProgramTypeOther: '',
        ProgramAims: 'Sample aims and objectives',
        ProgramTarget: 'Workforce',
        ProgramTargetOther:'Sample Program Target',
        ProgramTargetMinAge:'20',
        ProgramTargetMaxAge:'40',
        TargetAge: '',
        TargetCountry: 'Singapore',
        Devices:'Smartphone',
        DeviceTypeOther:'',
        // ProgramDescription: 'Sample program description',
        // ProgramDelivery: 'ASAP',
        ProgramVenue: 'Schools',
        ProgramVenueOther:'',
        ProgramDuration: 'lessthan15min',
        OtherDurationHours:'',
        ResourcesType:'Research',
        ResourcesTypeOther:'',
        ResourcesFormat:'Podcast',
        ResourcesFormatOther: '',
        RefResource: 'Sample resources',
        StartYear: '2023',
        Status: 'Active',
        Links: 'http://example.com',
        ProgramPrice: 'free',
        Success: 'Sample success definition',
        SuccessMeasure: 'Sample success measure',
        KPITrack: 'Sample KPI tracking',
        // RefLink: 'http://example.com',
        RefFramework: 'no',
        RefAlignment:'Framework Alignment',
    }

    const emptyInitialValues = {
        Devices: '',
        DeviceTypeOther:'',
        ProgramName: '',
        // ProgramType: '',
        // ProgramTypeOther: '',
        ProgramAims: '',
        ProgramTarget: '',
        ProgramTargetOther:'',
        ProgramTargetMinAge:'',
        ProgramTargetMaxAge:'',
        TargetAge: '',
        TargetCountry: '',
        // ProgramDescription: '',
        // ProgramDelivery: '',
        ProgramVenue: '',
        ProgramVenueOther:'',
        ProgramDuration: '',
        OtherDurationHours:'',
        ResourcesType:'',
        ResourcesTypeOther:'',
        ResourcesFormat:'',
        ResourcesFormatOther:'',
        RefResource: '',
        StartYear: '',
        Status: '',
        Links: '',
        ProgramPrice: '',
        Success: '',
        SuccessMeasure: '',
        KPITrack: '',
        // RefLink: '',
        RefFramework: '',
        RefAlignment:''
    }

    const history = useHistory();
    const location = useLocation();
    const SealType = location.state ? location.state.SealType : null;
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [loading, setLoading] = useState(false);


    const handleSubmit = async (values) => {
        setDisableSubmit(true);
        try {
            setLoading(true);
            const modifiedValues = { ...values, TargetAge: 'N/A', SealType };
            const result = await addProgramInfoService(modifiedValues);
            console.log('result.data -> ', result.data);
            // redirect to login page here
            toast(TOAST_TYPES.SUCCESS,  "Program Added", "Please wait until we reach out to you with a payment request.");
            history.push('/application/list');
        } catch (e) {
            // already toasted the error
            setLoading(false);
        }
        setDisableSubmit(false);
        setLoading(false);
    };

    return (
        <Container className={classes.container}>
            <Formik initialValues={(process.env.REACT_APP_NAME==='LOCAL') ? initialValues : emptyInitialValues} onSubmit={handleSubmit}>
                {({ setFieldValue }) => (
                    <FormComponent disableSubmit={disableSubmit} setFieldValue={setFieldValue}/>
                )}
            </Formik>
            <LoaderWithBackDrop loading={loading} />
        </Container>
    );
};

export default CreateApplication;