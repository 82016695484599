import {LOCAL_STORAGE_KEYS} from "../utils/constants";
import {invokeApi} from "../bl_libs/invokeApi";

export const postAddAssessmentReq = (data) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/certify/assessment/request',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        ...data
    };

    return invokeApi(requestObj);
}

export const getOrgDetails = (org_code) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/certify/assessment/get_org_details',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        org_code
    };

    return invokeApi(requestObj);
}

export const listOrgModules = (org_code) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/certify/assessment/list_org_modules',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        org_code
    };

    return invokeApi(requestObj);
}

export const updateOrgCallbackInfo = (org_code, data) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/certify/assessment/update_org_cb_info',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    const {callback_url, callback_key} = data;
    requestObj['postData'] = {
        org_code,
        callback_url,
        callback_key
    };

    return invokeApi(requestObj);
}






