import React from 'react';
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";
import Aligned from "../../../../assets/images/report/aligned.png";
import PoweredBy from "../../../../assets/images/report/poweredbydq.png";
import {makeStyles} from "@material-ui/core/styles";

const tableStyles = makeStyles((theme) => ({
    root: {
        borderCollapse: "collapse",
        "& th,td": {
            border: "1px solid black",
            padding: "1px 10px",
            fontSize: "18px"
        },
        "& th": {
            padding: "18px 12px",
            textAlign: "left",
            color: "white",
            fontFamily: 'Source Sans Pro',
            fontWeight: 'bold',
        },
        "& th:nth-child(1)": {
            width: "30%"
        },
        "& th:nth-child(2),": {
            width: "70%"
        },
    },
}));
export default function Page18(props) {

    const {pageNumber, onlyTable = false} = props;
    const classes = tableStyles();

    return <ReportPage pageNumber={pageNumber}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={"flex-start"} height={"inherit"} px={10}>
            {
                !onlyTable &&
                <Box id="5.-using-the-dq-brand" display={'flex'} flexDirection={'column'}>
                    <Typography variant={'h3'} style={{color: "deepskyblue", fontWeight: "bold", fontFamily: "Raleway", fontSize: '24px', paddingTop: 70}}>
                        5.	Using the DQ Brand
                    </Typography>
                    {/*<Typography*/}
                    {/*    style={{*/}
                    {/*        color: "black",*/}
                    {/*        fontWeight: "lighter",*/}
                    {/*        fontFamily: 'Source Sans Pro',*/}
                    {/*        paddingTop: '30px',*/}
                    {/*        fontSize: '18px',*/}
                    {/*        textAlign: 'justify'*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    There are two ways to using the DQ brand for your organization:*/}
                    {/*    <ol style={{ paddingLeft: '50px' }}>*/}
                    {/*        <li>DQ SEAL</li>*/}
                    {/*        <li>“Powered by DQ” Logo</li>*/}
                    {/*    </ol>*/}
                    {/*</Typography>*/}
                    {/*<Box id={"6.1-the-dq-seal"}>*/}
                    {/*<Typography*/}
                    {/*    variant={'h4'}*/}
                    {/*    style={{*/}
                    {/*        color: "orangered",*/}
                    {/*        fontWeight: "lighter",*/}
                    {/*        fontFamily: 'Raleway',*/}
                    {/*        paddingTop: '30px',*/}
                    {/*        fontSize: '22px'*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    6.1	The DQ SEAL*/}
                    {/*</Typography>*/}
                    {/*</Box>*/}
                    {/*<Box display={'flex'} alignItems={'center'}>*/}
                    {/*    <Box component="img" src={Aligned} alt="DQ Aligned" style={{ width: 'auto', height: '275px', marginRight: '20px', paddingTop: '30px' }} />*/}
                    {/*    <Typography>*/}
                        {/*    style={{*/}
                        {/*        color: "black",*/}
                        {/*        fontWeight: "lighter",*/}
                        {/*        fontFamily: 'Source Sans Pro',*/}
                        {/*        paddingTop: '30px',*/}
                        {/*        fontSize: '18px',*/}
                        {/*        textAlign: 'justify'*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    You can now use your {' '}*/}
                        {/*    <a href="https://storage.googleapis.com/dqlab-public/assets/DQ-Aligned.png" style={{ color: 'blue', textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">*/}
                        {/*        DQ Aligned SEAL*/}
                        {/*    </a>*/}
                        {/*    {' '} on your products and services to showcase that your program is aligned.*/}
                        {/*    <br /><br />*/}
                        {/*    When placing the SEAL on your products and services, we suggest attaching a link to the {' '}*/}
                        {/*    <a href="http://www.dqlab.net/" style={{ color: 'blue', textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">*/}
                        {/*        www.dqlab.net*/}
                        {/*    </a>*/}
                        {/*    {' '} page so that they can connect and understand the meaning of it.*/}
                        {/*</Typography>*/}

                    {/*</Box>*/}
                    <Box id={"5.1-the-“powered-by-dq”-logo"}>
                    <Typography
                        variant={'h4'}
                        style={{
                            color: "orangered",
                            fontWeight: "lighter",
                            fontFamily: 'Raleway',
                            paddingTop: '30px',
                            fontSize: '22px'
                        }}
                    >
                        5.1	The “Powered by DQ” Logo
                    </Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} style={{marginTop: "25px"}}>
                        <table className={classes.root} style={{width: "100%"}}>
                            <th>  <Box component="img" src={PoweredBy} alt="Powered By DQ" style={{
                                width: 'auto',
                                height: '100px',
                                marginRight: '20px',
                                paddingTop: '30px',
                            }}/></th>
                            <th>
                                <Typography
                                    style={{
                                        color: "black",
                                        fontWeight: "lighter",
                                        fontFamily: 'Source Sans Pro',
                                        paddingTop: '30px',
                                        fontSize: '18px',
                                        textAlign: 'justify'
                                    }}
                                >
                                    “Powered by DQ” is a seal of trust that a digital literacy intervention or program
                                    is credible and impactful based on the IEEE DQ Global Standards (IEEE 3527.1<sup>TM</sup>).
                                    <br/><br/>
                                    You can place the “Powered by DQ” logo together with your product/program title or
                                    on a visible area on your website.
                                    <br/><br/>
                                    When placing the “Powered by DQ” logo on a website, we suggest putting it as a
                                    button that links back to the following page:{' '}
                                    <a href="http://www.dqlab.net/"
                                       style={{color: 'blue', textDecoration: 'underline'}} target="_blank"
                                       rel="noopener noreferrer">
                                        www.dqlab.net
                                    </a>
                                    {' '} so that they can connect and understand the meaning of it.
                                    <br/><br/>
                                    You must not distort, change the colors, or manipulate the seal in any way.
                                    <br/><br/>
                                    The high-resolution logo can be found here:{' '}
                                    <a href="https://storage.googleapis.com/dqlab-public/assets/PoweredByDQ.png"
                                       style={{color: 'blue', textDecoration: 'underline'}} target="_blank"
                                       rel="noopener noreferrer">
                                        https://storage.googleapis.com/dqlab-public/assets/PoweredByDQ.png
                                    </a>

                                </Typography>
                            </th>

                        </table>
                    </Box>
                </Box>
            }
        </Box>
    </ReportPage>
}