import {SEAL_TYPE, SEAL_TYPE_VERBOSE} from "../constants";

export const SEAL_TYPES = [
    {
        "value": SEAL_TYPE.ALIGN,
        "label": SEAL_TYPE_VERBOSE[SEAL_TYPE.ALIGN] + " - Only report"
    },
    // {
    //     "value": SEAL_TYPE.ENHANCE,
    //     "label": SEAL_TYPE_VERBOSE[SEAL_TYPE.ENHANCE]
    // },
    {
        "value": SEAL_TYPE.CERTIFY,
        "label": SEAL_TYPE_VERBOSE[SEAL_TYPE.CERTIFY] + " - Report with assessment and Impact Tracker"
    },
];

export const SEAL_TYPES_MAP = {
    [SEAL_TYPE.ALIGN] : {
        "label": SEAL_TYPE_VERBOSE[SEAL_TYPE.ALIGN]
    },
    [SEAL_TYPE.ENHANCE] : {
        "label": SEAL_TYPE_VERBOSE[SEAL_TYPE.ENHANCE]
    },
    [SEAL_TYPE.CERTIFY] : {
        "label": SEAL_TYPE_VERBOSE[SEAL_TYPE.CERTIFY]
    },
};