import {LOCAL_STORAGE_KEYS} from "../utils/constants";
import {invokeApi} from "../bl_libs/invokeApi";

export const updateCompanyInfoService = (data) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/company/update',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        ...data
    };

    return invokeApi(requestObj);
}

export const getCompanyInfoService = () => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/company/get',
        method : 'GET',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };

    return invokeApi(requestObj);
}