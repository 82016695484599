import React, { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import StripeCheckout from "./StripeCheckout.comp";
import {createStripeIntentService, getStripeConfigService} from "../../../services/app.service";

function StripePayment(props) {

    const {application_id} = props;

    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");

    async function loadConfig() {
        const result = await getStripeConfigService();
        if(result.data) {
            const {publishableKey} = result.data;
            setStripePromise(loadStripe(publishableKey));
        }
        return true;
    }

    async function createIntent() {
        const result = await createStripeIntentService(application_id);
        if(result.data) {
            const {clientSecret} = result.data;
            setClientSecret(clientSecret);
        }
        return true;
    }

    useEffect(() => {
        loadConfig().then(() => console.log(`stripe config loaded`)).catch((e) => console.error(`stripe config failed, ${e}`));
        createIntent().then(() => console.log(`payment intent created`)).catch((e) => console.error(`payment intent creation failed, ${e}`));
    }, []);

    return (
        <>
            {clientSecret && stripePromise && (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <StripeCheckout {...props}/>
                </Elements>
            )}
        </>
    );
}

export default StripePayment;