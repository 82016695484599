import React from 'react';
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";
import {makeStyles} from "@material-ui/core/styles";
// import {DQ_DEFINITIONS} from "../../../../utils/constants";
import {asciiSortCompareFunWithKey} from "../../../../utils/utils";

const tableStyles = makeStyles((theme) => ({
    root: {
        borderCollapse: "collapse",
        tableLayout: 'fixed',
        width: '100vw',
        "& th,td": {
            border: "1px solid black",
            padding: "5px 10px",
            fontSize: "18px"
        },
        "& th": {
            padding: "18px 12px",
            fontWeight: 'bold',
            textAlign: "center",
            backgroundColor: "deepskyblue",
            color: "white",
        },
        "& th:nth-child(1), & td:nth-child(1)": {
            width: "70%"
        },
        "& th:nth-child(2), & td:nth-child(2)": {
            width: "30%"
        },
    },
}));

function MicroBadgeRow({badge, micro_badge}) {
    const badge_style = {backgroundColor: "#f6bc8f", fontWeight: "bold", color: "black"}
    const micro_style = {fontWeight: "normal", maxWidth: 500}
    const center_text_style = {textAlign: "center"}

    return (
        <tr>
            {badge ? (
                <>
                    <td style={{ ...badge_style }}>{badge.title}</td>
                    <td style={{ ...badge_style, ...center_text_style }}>{/*badge.count*/}</td>
                </>
            ) : (
                <>
                    <td style={{ ...micro_style }}>{micro_badge.title}</td>
                    <td style={{ ...micro_style, ...center_text_style }}>{micro_badge.count}</td>
                </>
            )}
        </tr>
    );
}

export default function Page13(props) {

    const {DQ_DEFINITIONS, pageNumber, depth, coverageMap, onlyTable = false} = props;

    const classes = tableStyles();

    const badges = depth;
    // if(depth.data) {
    //     for(const item of depth.data) {
    //         if(!item.type) {
    //             const {dqc_no, mb_no, mb_name, level9_count, is_first_mb} = item;
    //             const dq_num = parseInt(dqc_no.replace("DQC", ""), 10);
    //             const code = isNaN(dq_num) ? dqc_no : `DQ${dq_num}`
    //             const title = DQ_DEFINITIONS[code] ? DQ_DEFINITIONS[code].title : ""
    //             if (!badges[dqc_no]) badges[dqc_no] = {micro_badges: []}
    //             if (!badges[dqc_no].badge) badges[dqc_no].badge = {code, title, count: coverageMap[dqc_no].count}
    //             badges[dqc_no].micro_badges.push({title: `${mb_no}: ${mb_name}`, count: level9_count})
    //             badges[dqc_no].micro_badges.sort(asciiSortCompareFunWithKey('title'));
    //         }
    //     }
    // }

    return <ReportPage pageNumber={pageNumber}>
        <Box id="3.-dq-seal-enhancement" display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={"flex-start"} height={"inherit"} width={"100%"} px={10}>
            { !onlyTable &&
                <Box>
                    <Typography variant={'h3'} style={{color: "deepskyblue", fontWeight: "bold", fontFamily: "Raleway", fontSize: '24px', paddingTop: 70}}>
                        3.	DQ SEAL Enhancement
                    </Typography>
                    <Typography
                        style={{
                            color: "black",
                            fontWeight: "lighter",
                            fontFamily: 'Source Sans Pro',
                            paddingTop: '30px',
                            fontSize: '18px',
                            textAlign: 'justify'
                        }}
                    >
                        In this section, we present to you the opportunities to further boost your program to be 1) developed or enhanced based on, or 2) measured and certified by one or more of the 32 digital competencies in the <Typography component={'span'} style={{color: "inherit", fontWeight: "bold", fontSize: "inherit"}}> IEEE DQ
                        Global Standards (IEEE 3527.1&trade;).
                    </Typography>
                    </Typography>
                    <Box id={"3.1-enhancement-of-learning-content"}>
                        <Typography
                            variant={'h3'}
                            style={{
                                color: "orangered",
                                fontWeight: "lighter",
                                fontFamily: 'Raleway',
                                paddingTop: '30px',
                                fontSize: '22px'
                            }}
                        >
                            3.1	Enhancement of Learning Content
                        </Typography>
                    </Box>
                    <Box id={"3.1.1-increase-the-depth-of-learning-messages"}>
                        <Typography
                            variant={'h6'}
                            style={{
                                color: "blue",
                                fontWeight: "lighter",
                                fontFamily: 'Raleway',
                                paddingTop: '30px',
                                fontSize: '20px'
                            }}
                        >
                            3.1.1	Increase the Depth of Learning Messages
                        </Typography>
                    </Box>
                    <Typography
                        style={{
                            color: "black",
                            fontWeight: "lighter",
                            fontFamily: 'Source Sans Pro',
                            paddingTop: '30px',
                            fontSize: '18px',
                            textAlign: 'justify'
                        }}
                    >
                        There were learning messages in the program that matched the following DQ GSMs. However, the depth of learning content in these GSMs can be increased to enhance the quality of the program.
                    </Typography>
                </Box>
            }
            <Box alignSelf={onlyTable ? "flex-start" : "inherit"} paddingTop={onlyTable ? "70px" : "30px"}>
                <table className={classes.root} style={{width: "100%"}}>
                    {!onlyTable &&
                        <tr>
                            <th>Digital Competency</th>
                            <th># of Messages</th>
                        </tr>
                    }
                    <tbody>
                    {depth.map((item, index) => (
                        <MicroBadgeRow
                            key={index}
                            badge={item.isBadge ? item : null}
                            micro_badge={!item.isBadge ? item : null}
                        />
                    ))}
                    </tbody>
                </table>
            </Box>
        </Box>
    </ReportPage>
}