import React, {useEffect, useState} from 'react';
import TabContainer from "../../../components/Tabs/container.component";
import ProgramDescription from "../components/program_desc.comp";
import ProgramImpact from "../components/program_impact.comp";
import {getAppDetailService} from "../../../services/app.service";
import {Typography} from "@material-ui/core";

function ApplicationModule(props) {

    const {application_id, programInfo, status,activeStep} = props;

    return <>
        {activeStep === 0 && (
        <Typography variant="h6" style={{margin:'20px',textAlign:'center'}}>Please wait until we reach out to you with a payment request.</Typography>
        )}
        <TabContainer>
            <ProgramDescription title={'Program Description'} progDesc={programInfo} application_id={application_id} app_status={status}/>
            <ProgramImpact title={'Program Impact'} progImpact={programInfo} application_id={application_id} app_status={status}/>
        </TabContainer>
    </>
}

export default ApplicationModule;