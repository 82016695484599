import React, {useEffect} from 'react';
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";
// import {DQ_DEFINITIONS} from "../../../../utils/constants";
import {asciiSortCompareFunWithKey, getMapFromObjectListByKey} from "../../../../utils/utils";
import {makeStyles} from "@material-ui/core/styles";
import DQCircle from "../../../../assets/images/report/dqcircle.png";

const tableStyles = makeStyles((theme) => ({
    root: {
        borderCollapse: "collapse",
        width: '100%',
        "& th, & td": {
            border: "1px solid black",
            padding: "5px 10px",
            fontSize: "18px"
        },
        "& th": {
            padding: "18px 12px",
            textAlign: "left",
            backgroundColor: "deepskyblue",
            color: "white",
            fontFamily: 'Source Sans Pro',
            fontWeight: 'bold',
        },
        "& th:nth-child(1)": {
            width: "30%"
        },
        "& th:nth-child(2)": {
            width: "70%"
        },
        "& td:nth-child(1)": {
            width: "30%"
        },
        "& td:nth-child(2)": {
            width: "70%"
        },
    },
}));


function BadgeRow(props) {
    const { badge } = props;
    const c = { color: "black", fontWeight: "normal", textAlign: "left", fontFamily: 'Source Sans Pro', fontSize: '15px' };

    return (
        badge && (
            <tr>
                <td style={{ ...c, width: "30%" }}>{badge.mb_no} {badge.title}</td>
                <td style={{ ...c, width: "70%" }}>{badge.definition}</td>
            </tr>
        )
    );
}

export default function Page20(props) {

    const {LEVEL6, pageNumber, coverage, instruments, onlyTable = false} = props;

    const classes = tableStyles();
    const badges = coverage;

    // if(coverage && coverage.data) {
    //     for(const item of coverage.data) {
    //         const {mb_no} = item;
    //         const level6_entry = LEVEL6.find(entry => entry.gsm_no === mb_no);
    //         const title = level6_entry ? level6_entry.full_name : "";
    //         const definition = level6_entry ? level6_entry.definition : "";
    //         badges.push({mb_no, title, definition});
    //     }
    // }

    return (
        <ReportPage pageNumber={pageNumber}>
            <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={"flex-start"} height={"inherit"} width={"100%"} px={10}>
                {
                    !onlyTable &&
                    <Box id="6.2-appendix-b:-description-of-dq-gsms-mentioned-in-analysis-ii" display={'flex'} flexDirection={'column'}>
                        <Typography
                            variant={'h3'}
                            style={{
                                color: "orangered",
                                fontWeight: "lighter",
                                fontFamily: 'Raleway',
                                paddingTop: '70px',
                                fontSize: '22px'
                            }}
                        >
                            6.2 Appendix B: Description of DQ GSMs mentioned in <a style={{ color: 'blue', textDecoration: 'underline' }} href={`#${'2.2 Analysis II: The content is aligned with the following DQ GSMs based on the depth of the learning messages'.toLowerCase().replace(/ /g, '-')}`}>Analysis II</a>.
                        </Typography>
                    </Box>
                }
                <Box paddingTop={onlyTable ? "70px" : "30px"} width="100%">
                    <table className={classes.root}>
                        {!onlyTable && (
                            <thead>
                            <tr>
                                <th>DQ GSM</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                        )}
                        <tbody>
                        {badges.map((b, index) => (
                            <BadgeRow key={index} badge={b} />
                        ))}
                        </tbody>
                    </table>
                </Box>
            </Box>
        </ReportPage>
    );
}