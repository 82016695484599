import React from 'react';
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";
import {makeStyles} from "@material-ui/core/styles";
// import {DQ_DEFINITIONS} from "../../../../utils/constants";
import {asciiSortCompareFunWithKey} from "../../../../utils/utils";

const tableStyles = makeStyles((theme) => ({
    root: {
        borderCollapse: "collapse",
        tableLayout: 'fixed',
        width: '100vw',
        "& th,td": {
            border: "1px solid black",
            padding: "5px 10px",
            fontSize: "18px"
        },
        "& th": {
            padding: "18px 12px",
            fontWeight: 'bold',
            textAlign: "center",
            backgroundColor: "deepskyblue",
            color: "white",
        },
        '& th:first-child, & td:first-child': {
            textAlign: 'left',
        },
        "& th:nth-child(1), & td:nth-child(1)": {
            width: "50%"
        },
        "& th:nth-child(2), & td:nth-child(2)": {
            width: "15%"
        },
        "& th:nth-child(3), & td:nth-child(3)": {
            width: "15%"
        },
        "& th:nth-child(4), & td:nth-child(4)": {
            width: "20%"
        },
        // "& th:nth-child(3)": {
        //     width: "30%"
        // },
    },
}));

// const badges = [
//     {code: 'DQ1', title: 'Digital Citizen Identity', mb: 1, count: 6, aligned: false},
//     {code: 'DQ2', title: 'Balanced Digital Use', mb: 1, count: 9, aligned: false},
//     {code: 'DQ4', title: 'Personal Cyber Security Management', mb: 2, count: 15, aligned: true},
//     {code: 'DQ5', title: 'Digital Empathy', mb: 2, count: 9, aligned: true},
//     {code: 'DQ7', title: 'Media and Information Literacy', mb: 1, count: 21, aligned: true},
//     {code: 'DQ8', title: 'Privacy Management', mb: 1, count: 7, aligned: false},
//     {code: 'DQ25', title: 'Digital User Identity', mb: 1, count: 22, aligned: true}
// ]

function BadgeRow(props) {
    const {badge} = props;

    const badge_style = {fontWeight: "normal", fontFamily: 'Source Sans Pro', fontSize: '18px',}
    const aligned_badge_style = {backgroundColor: "#dcf404", fontWeight: "normal", color: "black", fontFamily: 'Source Sans Pro', fontSize: '18px',}
    const center_text_style = {textAlign: "center", fontFamily: 'Source Sans Pro', fontSize: '18px',}

    const style_to_use = badge.aligned ? aligned_badge_style : badge_style;
    return <>
        <tr>
            <td style={style_to_use}>{badge.title}</td>
            <td style={{...style_to_use, ...center_text_style}}>{badge.mb}</td>
            <td style={{...style_to_use, ...center_text_style}}>{badge.count}</td>
            <td style={{...style_to_use, ...center_text_style}}>
                {badge.aligned !== undefined && badge.aligned !== null && badge.aligned !== '' ? (badge.aligned ? "Yes" : "No") : ""}
            </td>
        </tr>
    </>
}

export default function Page14(props) {

    const {DQ_DEFINITIONS, pageNumber, coverageMap, breadth, onlyTable = false} = props;

    const classes = tableStyles();

    // function getDQ(dqc_no) {
    //     const dq_num = parseInt(dqc_no.replace("DQC", ""), 10);
    //     const code = isNaN(dq_num) ? dqc_no : `DQ${dq_num}`
    //     const title = DQ_DEFINITIONS[code] ? DQ_DEFINITIONS[code].title : ""
    //     return {code, title};
    // }

    return <ReportPage pageNumber={pageNumber}>
        <Box id="3.1.2-increase-the-breadth-of-learning-messages" display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={"flex-start"} height={"inherit"} px={10}>
            {
                !onlyTable &&
                <Box>
                    <Typography
                        variant={'h6'}
                        style={{
                            color: "blue",
                            fontWeight: "lighter",
                            fontFamily: 'Raleway',
                            paddingTop: '70px',
                            fontSize: '20px'
                        }}
                    >
                        3.1.2	Increase the Breadth of Learning Messages
                    </Typography>
                    <Typography
                        style={{
                            color: "black",
                            fontWeight: "lighter",
                            fontFamily: 'Source Sans Pro',
                            paddingTop: '30px',
                            fontSize: '18px',
                            textAlign: 'justify'
                        }}
                    >
                        Although the program touched on the following DQ Competencies (white rows), there were insufficient GSMs aligned in each of them. More DQ GSMs can be taught to enhance the quality of the learning program. By focusing on acquiring more aligned competencies, your program can continue to expand its reach and impact on a global scale.
                    </Typography>
                </Box>
            }
            <Box alignSelf={onlyTable ? "flex-start" : "inherit"} paddingTop={onlyTable ? '70px' : '30px'}>
                <table className={classes.root} style={{width: "100%"}}>
                    { !onlyTable &&
                        <tr>
                            <th>DQ Competency</th>
                            <th># of Aligned MBs</th>
                            <th># of Learning Messages</th>
                            <th>Aligned (to DQ Competency)</th>
                        </tr>
                    }
                    {
                        breadth &&
                        breadth.map((b) => <BadgeRow badge={{
                            title: b.title,
                            mb: b.mb,
                            count: b.count,
                            aligned: b.aligned
                        }}/>)
                    }
                </table>
            </Box>
        </Box>
    </ReportPage>
}