import React from 'react';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import dqlab from "../../../../assets/images/report/dqlab.png";
const useStyles = makeStyles((theme) => ({
    background: {
        width: "inherit",
        height: "inherit",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
        // border: "#000 solid 2px"
    },
    // background_image: {
    //     width: "100%",
    //     maxWidth: "1280px",
    //     height: "auto",
    // },
    // page_container: {
    //     borderStyle: "solid", borderWidth: "2px", borderColor: "lightgrey"
    // },
}))

export default function PageContainer(props) {

    const classes = useStyles();
    const {pageNumber, backgroundImage, children} = props;

    // A4 dimensions in pixels for 96 PPI
    const A4_WIDTH_PX = 794;
    const A4_HEIGHT_PX = 1193; //Original A4 height is 1123 and adjusted the value here for print preview and PDF

    return <div className={'print-page-break'}>
        <Box display={'flex'} justifyContent={'center'}>
            <Grid container direction="column" alignItems="center">
                <Grid className={'page-outline'} item style={{width: A4_WIDTH_PX, height: A4_HEIGHT_PX, position: 'relative'}}>
                    <div className={classes.background} style={{backgroundImage: `url(${backgroundImage})`, height:A4_HEIGHT_PX - 30}}>
                        {/* Image positioned at the top right corner */}
                        <img src={dqlab} alt="Header Image" style={{
                            position: 'absolute',
                            top: '15px',
                            right: '15px',
                            maxWidth: '100px',
                            maxHeight: '100px',
                        }}/>
                        {children}
                        <Box flexDirection={'row'} justifyContent={'center'} alignItems={'flex-start'} className={'print-footer'} mt={15} mb={3} textAlign={'center'} style={{fontSize: 12}}>
                            <Box ml={"auto"}>
                                ©{new Date().getFullYear()} DQ LAB Pte Ltd. All Rights Reserved
                            </Box>
                            <Box ml={"auto"} style={{float:"right"}} mt={-2} mr={3}>
                                {pageNumber}
                            </Box>
                        </Box>
                    </div>
                </Grid>
            </Grid>
        </Box>
    </div>
}