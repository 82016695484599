import React from 'react';
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";

export default function Page17(props) {

    const {pageNumber, onlyTable = false} = props;

    return <ReportPage pageNumber={pageNumber}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={"flex-start"} height={"inherit"} px={10}>
            {
                !onlyTable &&
                <Box id={"4.-about-our-organization"} display={'flex'} flexDirection={'column'}>
                    <Typography variant={'h3'} style={{color: "deepskyblue", fontWeight: "bold", fontFamily: "Raleway", fontSize: '24px', paddingTop: 70}}>
                        4.	About Our Organization
                    </Typography>
                    <Box id={"4.1-dq-institute"}>
                    <Typography
                        variant={'h3'}
                        style={{
                            color: "orangered",
                            fontWeight: "lighter",
                            fontFamily: 'Raleway',
                            paddingTop: '30px',
                            fontSize: '22px'
                        }}
                    >
                        4.1	DQ Institute
                    </Typography>
                    </Box>
                    <Typography
                        style={{
                        color: "black",
                        fontWeight: "lighter",
                        fontFamily: 'Source Sans Pro',
                        paddingTop: '30px',
                        fontSize: '18px',
                        textAlign: 'justify'
                        }}
                    >
                        The DQ Institute is an international think-tank that is dedicated to setting global standards for
                        digital intelligence that ensures safety, empowerment, and well-being of individuals, organizations,
                        and nations in the digital age. It is registered as a 501(c)(3) Not-For-Profit Organization in the
                        United States (Employer ID #82-0628471) and a public company limited by guarantee in Singapore (UEN 202142503D).
                        Its affiliated organizations are DQ Lab Pte Ltd, a social enterprise based in Singapore that focuses on research,
                        development, and dissemination of the DQ Global Standards (IEEE 3527.1&trade;) and the Coalition for Digital Intelligence.
                    </Typography>
                    <Box id={"4.2-dq-lab"}>
                    <Typography
                        variant={'h3'}
                        style={{
                            color: "orangered",
                            fontWeight: "lighter",
                            fontFamily: 'Raleway',
                            paddingTop: '30px',
                            fontSize: '22px'
                        }}
                    >
                        4.2	DQ Lab
                    </Typography>
                    </Box>
                    <Typography
                        style={{
                            color: "black",
                            fontWeight: "lighter",
                            fontFamily: 'Source Sans Pro',
                            paddingTop: '30px',
                            fontSize: '18px',
                            textAlign: 'justify'
                        }}
                    >
                        DQ Lab Pte Ltd is a global social enterprise arm of the DQ Institute. It is dedicated to provide
                        innovative products and services to help assess, educate, and empower individuals, organizations,
                        and nations to be digitally safe and competent.
                    </Typography>

                </Box>
            }
        </Box>
    </ReportPage>
}