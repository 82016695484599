import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';


function LoaderBackDropWithPercentage(props) {

    const { loading, value } = props;

    return (
        loading && <div className={'backdrop-loading-container-darker'}>
            <Box position="relative" display="inline-flex">
                <CircularProgress size={120} variant="static" {...props} />
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography
                        style={{
                            color: 'beige',
                            fontSize: '2rem'
                        }}
                        variant="caption" component="div" color="textSecondary">{`${Math.round(value,)}%`}</Typography>
                </Box>
            </Box>
        </div>

    );
}

export default LoaderBackDropWithPercentage