export const SECTORS = [
    {
        "value": "AerospaceIndustries",
        "label": "Aerospace and defense"
    },
    {
        "value": "Agriculture",
        "label": "Agriculture"
    },
    {
        "value": "AutomotiveIndustries",
        "label": "Automotive and assembly"
    },
    {
        "value": "AviationTravelandTourism",
        "label": "Aviation, travel, and logistics"
    },
    {
        "value": "ChemistryandAdvancedMaterials",
        "label": "Chemicals"
    },{
        "value": "ConstructionandBuildingMaterials",
        "label": "Construction and building materials"
    },
    {
        "value": "ConsumerIndustries",
        "label": "Consumer packaged goods"
    },
    {
        "value": "Education",
        "label": "Education"
    },
    {
        "value": "Electronics",
        "label": "Electric power, natural gas, and utilities"
    },
    // {
    //     "value": "Energy",
    //     "label": "Energy"
    // },
    {
        "value": "FinancialServices",
        "label": "Financial services"
    },
    {
        "value": "HealthHealthcare",
        "label": "Healthcare systems and services"
    },
    {
        "value": "InformationCommTech",
        "label": "Information technology and electronics"
    },
    // {
    //     "value": "InfraUrbanDev",
    //     "label": "Infrastructure and Urban Development"
    // },
    // {
    //     "value": "Investors",
    //     "label": "Investors"
    // },
    // {
    //     "value": "Manufacturing",
    //     "label": "Manufacturing"
    // },
    {
        "value": "MediaEntInfo",
        "label": "Media and entertainment"
    },
    // {
    //     "value": "Mobility",
    //     "label": "Mobility"
    // },
    {
        "value": "MiningandMetals",
        "label": "Metals and mining"
    },
    {
        "value": "OilandGas",
        "label": "Oil and gas"
    },
    {
        "value": "Pharma",
        "label": "Pharmaceuticals and medical products"
    },
    {
        "value": "PublicandSocialSectors",
        "label": "Public and social sectors"
    },
    // {
    //     "value": "ProfessionalServices",
    //     "label": "Professional Services"
    // },
    {
        "value": "RealEstate",
        "label": "Real estate"
    },
    {
        "value": "Retail",
        "label": "Retail"
    },{
        "value": "Telecommunications",
        "label": "Telecommunications"
    },
    // {
    //     "value": "SupplyChainandTransport",
    //     "label": "Supply Chain and Transport"
    // },
    {
        "value": "other",
        "label": "Other"
    }
];

export const SECTORS_MAP = {
    "AerospaceIndustries": {
        "label": "Aerospace and defense"
    },
    "Agriculture": {
        "label": "Agriculture"
    },
    "AutomotiveIndustries": {
        "label": "Automotive and assembly"
    },
    "AviationTravelandTourism": {
        "label": "Aviation, travel, and logistics"
    },
    "ChemistryandAdvancedMaterials": {
        "label": "Chemicals"
    },
    "ConstructionandBuildingMaterials": {
        "label": "Construction and building materials"
    },
    "ConsumerIndustries": {
        "label": "Consumer packaged goods"
    },
    "Education": {
        "label": "Education"
    },
    "Electronics": {
        "label": "Electric power, natural gas, and utilities"
    },
    // "Energy": {
    //     "label": "Energy"
    // },
    "FinancialServices": {
        "label": "Financial Services"
    },
    "HealthHealthcare": {
        "label": "Healthcare systems and services"
    },
    "InformationCommTech": {
        "label": "Information technology and electronics"
    },
    // "InfraUrbanDev": {
    //     "label": "Infrastructure and Urban Development"
    // },
    // "Investors": {
    //     "label": "Investors"
    // },
    // "Manufacturing": {
    //     "label": "Manufacturing"
    // },
    "MediaEntInfo": {
        "label": "Media and entertainment"
    },
    // "Mobility": {
    //     "label": "Mobility"
    // },
    "MiningandMetals": {
        "label": "Metals and mining"
    },
    "OilandGas": {
        "label": "Oil and gas"
    },
    "Pharma": {
        "label": "Pharmaceuticals and medical products"
    },
    "PublicandSocialSectors": {
        "label": "Public and social sectors"
    },
    // "ProfessionalServices": {
    //     "label": "Professional Services"
    // },
    "RealEstate": {
        "label": "Real estate"
    },
    "Retail": {
        "label": "Retail"
    },
    "Telecommunications": {
        "label": "Telecommunications"
    },
    // "SupplyChainandTransport": {
    //     "label": "Supply Chain and Transport"
    // },
    "other": {
        "label": "Other"
    }
}