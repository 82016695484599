import React from 'react';
import not_found from "../../assets/images/404.png";
import Container from '@material-ui/core/Container';
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

export default function NotFound() {
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <img src={not_found}  className="App-logo" alt="logo" />
            </div>
        </Container>
    );
}
