import React from 'react';
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {Divider, Grid, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import {APP_STATUS} from "../../../utils/constants";
import {PROGRAM_TARGETS_MAP} from "../../../utils/META/cat_108";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(1),
    },
    label: {
        // marginLeft: theme.spacing(10),
        wordBreak: "break-word"
    },
    value: {
    // marginTop: theme.spacing(0.8),
    // marginLeft: theme.spacing(10),
    wordBreak: "break-word",
        color: theme.palette.text.secondary,
        fontWeight:'500',
    },
    button:{
    backgroundColor: theme.palette.primary.main,
        color:'white',
        padding:'6px 20px 6px 20px',
        borderRadius:'11px',
        borderColor: theme.palette.primary.main,
        '&:hover': {
        color: theme.palette.primary.main,
            backgroundColor: theme.palette.background.paper,
        },
    },
    title: {
        color: theme.palette.text.secondary,
        paddingLeft: '30px !important',
        display: 'flex',
        alignItems: 'flex-end',
        fontWeight:'bold !important',
    }
}));

const key_desc = {
    Success: "Definition of success for program",
    SuccessMeasure: "Measurement of success for program",
    KPITrack: "Process to meet the program’s objectives",
    // RefLink: "Weblink that best describes your program",
    RefFramework: "Is your program already aligned with any existing framework?",
    RefAlignment: "Framework your program is aligned with"
}

const key_val = {
    RefFramework: (value) => {
        return value === 'yes' ? 'Yes' : 'No';
    },

}

function ProgramImpact(props) {

    const classes = useStyles();

    const {progImpact, application_id, app_status} = props;

    const history = useHistory();

    const shouldHideButton = app_status < APP_STATUS.COLLECTED_DATA;

    const editProgramImpact = (e) => {
        history.push('/application/edit/program', {existingValues: progImpact, application_id});
    }

    return <Container maxWidth={false} className={classes.container} component={Paper}>
        <Grid container spacing={3} style={{marginTop: "20px"}}>
            <Grid item xs={6} className={classes.title}>
                <Typography variant="h5">Program Impact</Typography>
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
            {shouldHideButton && (
                <Button className={classes.button} variant={'outlined'} style={{marginLeft: 'auto'}} onClick={editProgramImpact}>
                    Edit
                </Button>
            )}
            </Grid>
            <Grid item xs={12}>
                <Divider style={{ backgroundColor: '#052F44' }}/>
            </Grid>

            {Object.entries(key_desc).map(([key, value]) => (
                <Grid item xs={12} key={key}>
                    <Grid container alignItems="flex-start">
                        <Grid item xs={4} style={{ display: 'flex', textAlign: 'left', marginLeft: '100px', marginRight:'20px' }}>
                            <Typography variant="h6" className={classes.label}>{value}</Typography>
                        </Grid>
                        <Grid item xs={6} style={{ display: 'flex', textAlign: 'left'  }}>
                            <Typography variant="h6" className={classes.value}>
                                {key_val[key] ? key_val[key](progImpact[key]) : progImpact[key]}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    </Container>
}

export default ProgramImpact;
