import React, { useEffect, useState } from 'react';
import {Container, Grid, Typography, Button, makeStyles, CardContent, Card, Divider} from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { getCompanyInfoService } from "../../services/company.service";
import { REGIONS_MAP } from "../../utils/META/cat_101";
import { ORG_TYPES_MAP } from "../../utils/META/cat_102";
import { SECTORS_MAP } from "../../utils/META/cat_103";
import { HIST_DURATIONS_MAP } from "../../utils/META/cat_104";
import { SIZE_EMPLOYEE_MAP } from "../../utils/META/cat_105";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import InfoIcon from '@material-ui/icons/Info'
import 'aos/dist/aos.css'
import AOS from 'aos'

const useStyles = makeStyles((theme) => ({
    label: {
        textAlign: 'left',
        marginRight: theme.spacing(2),
        paddingLeft: '15px !important',
    },
    value: {
        marginTop: theme.spacing(0.5),
        marginLeft: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    button:{
        backgroundColor: theme.palette.primary.main,
        color:'white',
        padding:'6px 20px 6px 20px',
        marginBottom:'15px',
        borderRadius:'11px',
        borderColor: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.background.paper,
        },
    },
    instruction: {
        margin: theme.spacing(3,3,3,0),
    },
    card: {
        textAlign: 'justify',
        margin:'14px',
        border:'1px solid #052F44',
        backgroundColor: "#F4F4F4",
        borderRadius: "12px",
        // boxShadow: "6px 0px 20px rgba(0, 0, 0, 0.3)",
    },
    title: {
        marginBottom:'15px',
        color: theme.palette.text.secondary,
        paddingLeft: '15px !important',
        display: 'flex',
        alignItems: 'flex-end',
    }
}));

const key_desc = {
    NameOrg: "Name of organization",
    NameContact: "Name of contact person",
    NameDept: "Department and position of contact person",
    Region: "Country of organization’s headquarters",
    OrgType: "Type of organization",
    OrganizationEntered:"Other type of organization",
    Sector: "Sector of organization",
    SectorEntered: "Other sector of organization",
    History: "Age of organization",
    SizeEmployee: "Size of organization",
    OrgVision: "Mission and vision of organization"
}

const CompanyInfoView = () => {
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [compInfo, setCompInfo] = useState({
        NameOrg: '',
        NameContact: '',
        NameDept: '',
        Region: '',
        OrgType: '',
        OrganizationEntered:'',
        Sector: '',
        SectorEntered: '',
        History: '',
        SizeEmployee: '',
        OrgVision: '',
    });

    const getCompanyInfo = async () => {
        try {
            setLoading(true);
            const result = await getCompanyInfoService();
            if (!result.error) {
                if (result.data.comp) {
                    const region_name = REGIONS_MAP[result.data.comp.region].name ?
                        REGIONS_MAP[result.data.comp.region].name : result.data.comp.region;

                    const org_type_label = ORG_TYPES_MAP[result.data.comp.org_type].label ?
                        ORG_TYPES_MAP[result.data.comp.org_type].label : result.data.comp.org_type;

                    const sector_label = SECTORS_MAP[result.data.comp.sector].label ?
                        SECTORS_MAP[result.data.comp.sector].label : result.data.comp.sector;

                    const history_label = HIST_DURATIONS_MAP[result.data.comp.history].label ?
                        HIST_DURATIONS_MAP[result.data.comp.history].label : result.data.comp.history;

                    const size_emp_label = SIZE_EMPLOYEE_MAP[result.data.comp.size_employee].label ?
                        SIZE_EMPLOYEE_MAP[result.data.comp.size_employee].label : result.data.comp.size_employee;

                    setCompInfo({
                        ...compInfo,
                        NameOrg: result.data.comp.name_org,
                        NameContact: result.data.comp.name_contact,
                        NameDept: result.data.comp.name_dept,
                        Region: region_name,
                        OrgType: org_type_label,
                        OrganizationEntered:org_type_label === 'Other' ? result.data.comp.organization_entered : '',
                        Sector: sector_label,
                        SectorEntered: sector_label === 'Other' ? result.data.comp.sector_entered : '',
                        History: history_label,
                        SizeEmployee: size_emp_label,
                        OrgVision: result.data.comp.org_vision,
                    });
                }
                setLoading(false);
            }
        }
        catch (e) {
            // already toasted the error
            setLoading(false);
        }
    }

    useEffect(() => {
        getCompanyInfo().then((res) => `fetch initiated, ${res}`);
    }, [])

    const handleUpdateClick = () => {
        history.push('/company/update');
    };

    const OrgInfo = Object.entries(compInfo);
    const PersonalInfo = OrgInfo.slice(0, 2);
    const OrgDetails = OrgInfo.slice(2);
    const renderEntries = (OrgInfo) => (
        OrgInfo.map(([key, value]) => value && (
            <Grid item xs={12} key={key}>
                <Grid container justifyContent="center" alignItems="flex-start">
                    <Grid item xs={6}>
                        <Typography variant="h6" className={classes.label}>{key_desc[key]}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" className={classes.value}>
                            {typeof value === 'function' ? value() : value}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        ))
    );

    useEffect(() => {
        AOS.init({
            duration: 500,
            slidingEnter: 'slide-up',
            once: true,
        });
    }, []);

    return (
        <Container>
            <Grid container spacing={1} >
                {/*<Typography  className={classes.instruction} variant="body1">  <InfoIcon/>  In order to start the application process, please update your company information below. This will help us to identify who the program belongs to.*/}
                {/*</Typography>*/}
            </Grid>
            <Grid container  style={{marginTop:'25px', display:'flex', alignItems:'center', flexDirection:'column'}}>
                <Grid item xs={12} md={10} data-aos="slide-up" style={{ width: '100%' }}>
                    <Card className={classes.card} style={{ width: '100%' }}>
                        <CardContent>
                            <Grid container>
                            <Grid item xs={6} className={classes.title}>
                                <Typography component="h1" variant="h5" style={{fontWeight:'bold'}}>Contact Person</Typography>
                            </Grid>
                            <Grid item xs={6} container justifyContent="flex-end">
                                    <Button variant="outlined" onClick={handleUpdateClick} className={classes.button}>
                                        Edit
                                    </Button>
                            </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider style={{ backgroundColor: '#052F44',  marginBottom:'15px', }}/>
                            </Grid>
                            <Grid container spacing={2}>
                                {renderEntries(PersonalInfo)}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={10} data-aos="slide-up" data-aos-delay="400" style={{ width: '100%' }}>
                    <Card className={classes.card} style={{ width: '100%' }}>
                        <CardContent>
                            <Grid container>
                            <Grid item xs={6} className={classes.title}>
                                <Typography component="h1" variant="h5" style={{fontWeight:'bold'}}>Organization Details</Typography>
                            </Grid>
                            <Grid item xs={6} container justifyContent="flex-end">
                                    <Button variant="outlined" onClick={handleUpdateClick} className={classes.button}>
                                        Edit
                                    </Button>
                            </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider style={{ backgroundColor: '#052F44',  marginBottom:'15px', }}/>
                            </Grid>
                            <Grid container spacing={2}>
                                {renderEntries(OrgDetails)}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                {/*{Object.entries(compInfo).map(([key, value]) => value &&(*/}
                {/*    <Grid item xs={12} key={key}>*/}
                {/*        <Grid container justify="center" alignItems="flex-start">*/}
                {/*            <Grid item xs={6}>*/}
                {/*                <Typography variant="h6" className={classes.label}>{key_desc[key]}</Typography>*/}
                {/*            </Grid>*/}
                {/*            <Grid item xs={6}>*/}
                {/*                <Typography variant="body1" className={classes.value}> {typeof compInfo[key] === 'function' ? compInfo[key](value) : value}*/}
                {/*                </Typography>*/}
                {/*            </Grid>*/}
                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*))}*/}
            </Grid>
            {/*<Grid container justifyContent="center" style={{ marginBottom: '20px', marginTop: '50px' }}>*/}
            {/*    <Button className={classes.button} variant="contained" color="primary" onClick={handleUpdateClick}>*/}
            {/*        Edit Info*/}
            {/*    </Button>*/}
            {/*</Grid>*/}
            <LoaderWithBackDrop loading={loading} />
        </Container>
    );
};

export default CompanyInfoView;
