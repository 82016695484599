import React from "react";

import {Box, Typography} from "@material-ui/core";

class TabHeader extends React.Component {

    render() {
        return <Typography variant="h6">
            <Box fontWeight="fontWeightBold">{this.props.title}</Box>
        </Typography>
    }
}

export default TabHeader;
