import React, { useEffect, useRef, useState } from 'react';
import { nanoid } from 'nanoid'
import { DropzoneDialog } from 'material-ui-dropzone';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    Typography,
    TextField,
    FormControl,
    InputLabel, Select, MenuItem, Divider
} from '@material-ui/core';
import LoaderBackDropWithPercentage from "../../../components/LoaderWithBackDrop/LoaderBackDropWithPercentage.comp";
import axios from "axios";
import { APP_STATUS, LOCAL_STORAGE_KEYS, TOAST_TYPES } from "../../../utils/constants";
import { toast, toastWarning } from "../../../utils/utils";
import Button from "@material-ui/core/Button";
import CloseIcon from '@material-ui/icons/Close'
import {
    addModuleData, deleteContent, deleteModuleData,
    listModuleData,
    submitModuleData,
    updateModuleData,
    updateProgramContent, uploadMedia
} from "../../../services/app.service";
import {
    Container,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field, useFormikContext } from "formik";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import PopUpForm from "../components/pop_up.comp";
import {TARGET_AGE} from "../../../utils/META/cat_109";
import {Autocomplete} from "@mui/material";
import {PROGRAM_TARGETS, PROGRAM_TARGETS_MAP} from "../../../utils/META/cat_108";
import {PROGRAM_DURATION, PROGRAM_DURATION_MAP} from "../../../utils/META/cat_112";
import {REGIONS_MAP} from "../../../utils/META/cat_101";
import {useAuth} from "../../../context/auth";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2,2,4,2),
        borderRadius:'12px',
    },
    formControl: {
        marginBottom: theme.spacing(2),
        minWidth: "100%",
    },
    closeImg:
        {cursor:'pointer', float:'right', marginTop: '5px', width: '20px'},
    paper: {
        position: 'absolute',
        width: '80%',
        maxHeight: '80%',
        overflowY: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    instruction: {
        margin: theme.spacing(2, 3, 2, 0),
    },
    button: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1, 3, 1, 3),
        backgroundColor: theme.palette.info.main,
        fontSize: '15px',
        color: theme.palette.background.default,
        '&:hover': {
            color: theme.palette.background.default,
            backgroundColor: theme.palette.info.main,
        },
    },
    mt: {
        marginTop: theme.spacing(2),
    },
    mb: {
        marginBottom: theme.spacing(2),
    }
}));

function validateWordLength(value, max_length) {
    const length = value ? value.match(/\w+/g).length : 0;
    const error = length > max_length;
    const error_message = error ? 'Word limit exceeded' : undefined;
    return { length, max_length, error, error_message };
}

function MaxLengthHelperText({ length, max_length, error, error_message }) {
    return <Box display={'flex'} flexDirection={'row'}>
        {error && <Box mr={'auto'}>{error_message}</Box>}
        <Box ml={'auto'}>{length}/{max_length}</Box>
    </Box>
}

const FormTextField = ({ label, name, ...rest }) => (
    <Field
        as={TextField}
        label={label}
        name={name}
        fullWidth
        required
        margin="normal"
        variant="outlined"
        {...rest}
    />
);

const FormSelect = ({ label, name, options }) => (
    <FormControl fullWidth variant="outlined" margin="normal" required>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Field as={Select} label={label} name={name}>
            {options.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </Field>
    </FormControl>
);

const FormComponent = ({disableSubmit, edit, setFieldValue}) => {

    const classes = useStyles();
    const {values} = useFormikContext(); // get current form values
    const objectiveValidProps = validateWordLength(values.LearningObjective, 100);
    const targetAudienceOptions = Object.values(PROGRAM_TARGETS);
    const [durationError, setDurationError] = useState(false);

    const handleValueCheck = (value) => {
        if (values.TimeMinutes === 'morethan20hours' && parseInt(value) <= 20) {
            setDurationError(true);
        } else {
            setDurationError(false);
        }
    };
    function parseInitialValues(initialValue, options) {
        return initialValue
            ? initialValue.split(', ').map(value => options.find(option => option.value === value)).filter(Boolean)
            : [];
    }
    const targetAudienceInitialValues = parseInitialValues(values.TargetAudience, targetAudienceOptions);

    const handleAutocompleteChange = (fieldName) => (event, value) => {
        const stringValue = value.map(item => item.value).join(', ');
        setFieldValue(fieldName, stringValue);
    };
    return (
        <Form>
            <FormTextField label="Module ID" name="ModuleID" disabled={true} />
            <FormTextField label="Module name" name="ModuleName" />
            <FormTextField
                label="Learning objectives"
                name="LearningObjective"
                multiline={true}
                helperText={<MaxLengthHelperText {...objectiveValidProps}/>}
                error={objectiveValidProps.error}
                validate={() => objectiveValidProps.error_message}
            />
            <Autocomplete
                sx={{ marginTop: 0, marginBottom: 2 }}
                multiple
                fullWidth={true}
                name="TargetAudience"
                options={Object.values(PROGRAM_TARGETS)}
                value={targetAudienceInitialValues}
                getOptionLabel={(option) => option.label}
                onChange={handleAutocompleteChange("TargetAudience")}
                disableCloseOnSelect
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth={true}
                        variant="outlined"
                        label="Target audience of module (Select all that apply)"
                        InputLabelProps={ { required: true }}
                    />
                )}
            />
            {
                (values.TargetAudience && (values.TargetAudience.includes('Teens') || values.TargetAudience.includes('Youth') || values.TargetAudience.includes('EarlyYears') || values.TargetAudience.includes('Children'))) ? (
                    <>
                        <FormTextField
                            label="Minimum Age"
                            type="number"
                            name="MinAge"
                        />
                        <FormTextField
                            label="Maximum Age"
                            type="number"
                            name="MaxAge"
                        />
                    </>
                ) : ''}
            {values.TargetAudience && (values.TargetAudience.includes('Others') || (values.TargetAudience.length > 1 && values.TargetAudience.includes('Others'))) ?  (
                <FormTextField
                    label="Please specify target audience."
                    name="ProgramTargetOther"
                />
            ) : ''}
            {/*<FormSelect*/}
            {/*    label="Target Age Group"*/}
            {/*    name="TargetAge"*/}
            {/*    options={TARGET_AGE}*/}
            {/*/>*/}
            {/*<FormSelect*/}
            {/*    style={{ display: 'none' }}*/}
            {/*    label="Difficulty"*/}
            {/*    name="Difficulty"*/}
            {/*    options={[*/}
            {/*        { value: 'N/A', label: 'N/A' },*/}
            {/*        { value: 'Beginner', label: 'Beginner' },*/}
            {/*        { value: 'Intermediate', label: 'Intermediate' },*/}
            {/*        { value: 'Advance', label: 'Advance' }*/}
            {/*    ]}*/}
            {/*    defaultValue="N/A"*/}
            {/*/>*/}

            {/*<FormTextField type="number" label="Duration to complete the module" name="TimeMinutes" />*/}
            <FormSelect
                label="Duration to complete the module"
                name="TimeMinutes"
                options={PROGRAM_DURATION}
            />
            {values.TimeMinutes === 'morethan20hours' && (
                <FormTextField
                    label="Please specify module duration (in hours)."
                    type="number"
                    name="OtherDurationHours"
                    onBlur={(e) => handleValueCheck(e.target.value)}
                    error={durationError}
                    helperText={durationError && `Number must be greater than 20`}
                />
            )}
            <Box mt={3} mb={2}>
                <Button type="submit" variant="contained" color="primary" disabled={disableSubmit} style={{backgroundColor:'#052F44', color:'white', borderRadius:'15px'}}>
                    {edit ? `Submit Details` : `Add Module`}
                </Button>
            </Box>
        </Form>
    )
}

const initialValues = {
    "ModuleID": "LEG0-M1",
    "ModuleName": "Module 1",
    "LearningObjective": "Objective-2",
    // "TargetAge": "19-24YO",
    "TargetAudience": 'Workforce',
    "ProgramTargetOther": 'Sample Audience',
    "MinAge": 20,
    "MaxAge": 50,
    // "Difficulty": "Advance",
    "TimeMinutes": "lessthan15min",
    "OtherDurationHours": 30,
};

const emptyInitialValues = {
    "ModuleID": "",
    "ModuleName": "",
    "LearningObjective": "",
    // "TargetAge": "",
    "TargetAudience": "",
    "ProgramTargetOther": "",
    "MinAge": '',
    "MaxAge":'',
    // "Difficulty": "",
    "TimeMinutes": "",
    "OtherDurationHours":"",
};

const convertToFormValues = (db_values) => {
    return {
        "ModuleID": db_values.module_id,
        "ModuleName": db_values.module_name,
        "LearningObjective": db_values.learning_objective,
        "TargetAudience":db_values.target_audience,
        "ProgramTargetOther": db_values.target_audience.includes('Others') ? db_values.target_audience_other : '',
        "MinAge": (db_values.target_audience.includes('Teens') ||
            db_values.target_audience.includes('Youth') ||
            db_values.target_audience.includes('EarlyYears') ||
            db_values.target_audience.includes('Children')) ?
            db_values.min_age : '',
        "MaxAge": (db_values.target_audience.includes('Teens') ||
            db_values.target_audience.includes('Youth') ||
            db_values.target_audience.includes('EarlyYears') ||
            db_values.target_audience.includes('Children')) ?
            db_values.max_age : '',
        "Difficulty": db_values.difficulty,
        "TimeMinutes": db_values.time_minutes,
        "OtherDurationHours": db_values.time_minutes === 'morethan20hours' ? db_values.module_duration_other : '',
    }
}

const getInitialFormValues = () => {
    let formValues = process.env.REACT_APP_NAME==='LOCAL' ?  initialValues : emptyInitialValues;
    formValues["ModuleID"] = nanoid();
    return formValues;
}

function UploadFileDialogue(props) {

    const { openDialogue, dialogueCloseHandler, successCB } = props;

    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    async function FileDialogueHandleSave(files) {
        setUploading(true);
        const formData = new FormData();
        formData.append('media', files[0]);
        try {
            const result = await uploadMedia(formData, progressEvent => {
                const uploadPercentage = Math.floor((progressEvent.loaded / progressEvent.total) * 99);
                setUploadProgress(uploadPercentage);
            })
            if(result.data) {
                const {media_path} = result.data;
                setUploadProgress(100);
                setTimeout(()=> {
                    setUploading(false);
                    setUploadProgress(0);
                    successCB(media_path);
                }, 200);
            }
        }
        catch (e) {
            console.error("Error uploading file", e);
            dialogueCloseHandler();
        }
    }

    const getFileRejectMessage = (rejectedFile, acceptedFiles, maxFileSize) => {
        const typeRegex = new RegExp(`(${acceptedFiles.join("|")})$`, "i")
        if(!typeRegex.test(rejectedFile.name))
            return "File {file_name} does not have allowed file type, i.e. {file_types}.".format({file_name: rejectedFile.name, file_types: acceptedFiles.join(', ')})
        if(rejectedFile.size === 0)
            return "File size cannot be 0";
        if(rejectedFile.size > maxFileSize)
            return `File size cannot be greater than {max_size} kb`.format({max_size: Math.ceil(maxFileSize/1000)});
        return "Invalid file";
    }

    return (
        <>
            <DropzoneDialog
                open={openDialogue}
                onSave={FileDialogueHandleSave}
                acceptedFiles={['.doc', '.docx']}
                showPreviews={true}
                maxFileSize={5000000}
                filesLimit={1}
                onClose={dialogueCloseHandler}
                dropzoneProps={{minSize: 1}}
                getDropRejectMessage={getFileRejectMessage}
            />
            {
                <LoaderBackDropWithPercentage
                    loading={uploading}
                    value={uploadProgress}
                />
            }
        </>
    );
}

function CollectionModule(props) {

    const { application_id, status, updateStatus } = props;
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [openDialogue, setOpenDialogue] = useState(false);
    const [moduleData, setModuleData] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [openPopUp, setOpenPopUp] = useState(false);
    const {authUser} = useAuth();

    let module_db_id = useRef(null);

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        module_db_id.current = null;
        setOpen(false);
    };

    const loadModuleData = async () => {
        try {
            setLoading(true);
            const result = await listModuleData(application_id);
            if (!result.error) {
                if (result.data.all_mods) {
                    setModuleData(result.data.all_mods);
                }
            }
            setLoading(false);
        } catch (e) {
            // already toasted the error
            setLoading(false);
        }
    }

    useEffect(() => {
        loadModuleData().then((res) => `fetch initiated, ${res}`);
    }, [])

    const addFilePathToApplication = async (file_path) => {
        try {
            //remove existing if available (asynchronously)
            if(module_db_id.current.content_file_uploaded) {
                const {content_file_path_original: file_path} = module_db_id.current;
                deleteContent(file_path).then((r) => console.log(`File removed ${file_path}`));
            }
            //update content with new file path
            setLoading(true);
            const result = await updateProgramContent(application_id, module_db_id.current._id, file_path);
            if (!result.error) {
                toast(TOAST_TYPES.SUCCESS, "Content Uploaded", "Successfully uploaded content")
                if (result.data.all_mods) {
                    setModuleData(result.data.all_mods);
                }
            }
            closeUploadDialog();
            setLoading(false);
        }
        catch (e) {
            //already toasted
            closeUploadDialog();
            setLoading(false);
        }
    }

    const submitModules = async () => {
        try {
            setLoading(true);
            const result = await submitModuleData(application_id);
            if (!result.error) {
                toast(TOAST_TYPES.SUCCESS, "Modules Submitted", "Successfully submitted modules, they will be analyzed and report will be available shortly.")
                if (result.data.success) {
                    updateStatus(result.data.status);
                }
            }
            setLoading(false);
        }
        catch (e) {
            setLoading(false);
            //already toasted
            closeUploadDialog();
        }
    }

    const createModuleAction = () => {
        setOpen(true);
    }

    const editModuleAction = (module) => {
        module_db_id.current = module;
        setOpen(true);
    }

    const deleteModuleAction = async (module) => {
        module_db_id.current = module;

        try {
            if (module_db_id.current.content_file_uploaded) {
                const {content_file_path_original: file_path} = module_db_id.current;
                deleteContent(file_path).then((r) => console.log(`File removed ${file_path}`));
            }
            //delete content
            setLoading(true);
            const result = await deleteModuleData(application_id, module_db_id.current._id);
            if (!result.error) {
                toast(TOAST_TYPES.SUCCESS, "Module Deleted", "Successfully deleted module")
                if (result.data.all_mods) {
                    setModuleData(result.data.all_mods);
                }
            }
            module_db_id.current = null;
            setLoading(false);
        } catch (e) {
            //already toasted
            setLoading(false);
        }
    }

    const handleSubmit = async (values) => {
        setDisableSubmit(true);
        try {
            setLoading(true);
            let result;
            const modifiedValues = { ...values, Difficulty: 'N/A', TargetAge: 'N/A' };

            if (module_db_id.current) {
                result = await updateModuleData(module_db_id.current._id, modifiedValues, );
            }
            else {
                result = await addModuleData(application_id, modifiedValues);
            }
            if (!result.error) {
                if (result.data.all_mods) {
                    setModuleData(result.data.all_mods);
                }
            }
            setLoading(false);
        } catch (e) {
            // already toasted the error
            setLoading(false);
        }
        setOpen(false);
        setDisableSubmit(false);
    }

    const uploadContentAction = (e, row) => {
        module_db_id.current = row;
        setOpenDialogue(true);
    }

    const closeUploadDialog = () => {
        module_db_id.current = null;
        setOpenDialogue(false);
    }

    const isStatusGreaterThanModule = status > APP_STATUS.IN_PROGRESS_COLLECT_DATA;

    let isAnyContentFileUploaded = moduleData.length > 0;
    for (const module of moduleData) {
        if (!module.content_file_uploaded) {
            isAnyContentFileUploaded = false;
            break;
        }
    }

    const handleOpenPopUp = () => {
        setOpenPopUp(true);
    };

    const handleClosePopUp = () => {
        setOpenPopUp(false);
    };

    return (
        <Container className={classes.container}>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Module Details</DialogTitle>
                <DialogContent>
                    <Formik initialValues={
                        module_db_id.current ? convertToFormValues(module_db_id.current) :
                            getInitialFormValues()
                    } onSubmit={handleSubmit}>
                        {({ setFieldValue }) => (
                            <Form>
                                <FormComponent
                                    disableSubmit={disableSubmit}
                                    edit={Boolean(module_db_id.current)}
                                    setFieldValue={setFieldValue}
                                    enableReinitialize={true}
                                />
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
            <Container className={classes.container} style={{ flexDirection: 'column', border:'1px solid #052F44', marginBottom:'15px'}}>
                <Typography component="h1" variant="h5" color="inherit" style={{ fontWeight: 'bold', color:'#11888B' }}>
                    Add Program Content
                </Typography>
                <Grid item xs={12} padding={3}>
                    <Divider style={{ backgroundColor: '#052F44', margin:'10px 0px' }}/>
                </Grid>
                <br/>
                <ol>
                    <li>
                        <Typography>
                            Click <span style={{fontWeight:'bold',}}>ADD A MODULE </span> and fill in the details. A module is usually a self-contained set of lesson(s) that are delivered together.
                        </Typography>
                    </li>
                    <br/>
                    <li>
                        <Typography>Once details have been filled, a green <span style={{fontWeight:'bold'}}>UPLOAD CONTENT </span>button will appear below the table.</Typography>
                    </li>
                    <br/>
                    <li>
                        <Typography>
                            Upload only one Word document for each module. Please use our optional <Typography
                            component="a"
                            href="https://storage.googleapis.com/mydq-live-bucket/TEMPLATE_FILES/dq_seal_template_v1.docx"
                            style={{color:'#2196f3',fontWeight:'bold'}}
                        >
                            template
                        </Typography>
                            {' '}
                            to expedite your review process.
                        </Typography>
                    </li>
                    <br/>
                    <li>
                        <Typography>
                            If you have multiple modules, please upload each module as a separate Word document. For instance, 5 modules would need 5 Word documents to be uploaded. Rest assured, you will receive a single report regardless of the number of modules uploaded.
                        </Typography>
                    </li>
                    <br/>
                    <li>
                        <Typography>
                            Once content has been uploaded, click <span style={{fontWeight:'bold',}}>SUBMIT ALL</span>. Please note that submitted files cannot be edited on the portal afterward, so please double-check before submitting. If you need to make corrections after accidental submission, please contact us
                            {' '}
                            <Typography
                                component="a"
                                style={{color: "#2196f3"}}
                                onClick={handleOpenPopUp}
                            >
                                here
                            </Typography>

                            .
                        </Typography>
                    </li>
                    <br/>
                    <li>
                        <Typography>
                            When your report is ready, we will send you an email notification to access it in this portal.
                        </Typography>
                    </li>
                    <Dialog open={openPopUp} onClose={handleClose} maxWidth="md" fullWidth>
                        <DialogTitle>Contact Us
                            <CloseIcon style={{float:'right'}} onClick={handleClosePopUp}/>
                        </DialogTitle>
                        <DialogContent>
                            <PopUpForm
                                name={authUser.first_name + " " + authUser.last_name}
                                email={authUser.email}
                                application_id={application_id}
                                onClose={handleClosePopUp}
                            />
                        </DialogContent>
                    </Dialog>
                </ol>
                <Grid container justify="center">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isStatusGreaterThanModule}
                        className={classes.button}
                        style={{backgroundColor:'#052F44', color:'white', borderRadius:'15px', marginTop:'15px'}}
                        onClick={createModuleAction}
                    >
                        Add A Module
                    </Button>
                </Grid>
            </Container>
            <br/> <br/>

            <TableContainer component={Paper} style={{border:'1px solid #052F44'}}>
                <Table aria-label="simple table">
                    <TableHead style={{backgroundColor:'white', boxShadow: "0 4px 6px -2px rgba(0, 0, 0, 0.3)",}}>
                        <TableRow>
                            <TableCell style={{fontSize:'17px', fontWeight:'500'}}>Module ID</TableCell>
                            <TableCell style={{fontSize:'17px', fontWeight:'500'}}>Module name</TableCell>
                            <TableCell style={{fontSize:'17px', fontWeight:'500'}}>Learning objectives</TableCell>
                            <TableCell style={{fontSize:'17px', fontWeight:'500'}}>Target audience</TableCell>
                            <TableCell style={{fontSize:'17px', fontWeight:'500'}}>Other Target audience</TableCell>
                            <TableCell style={{fontSize:'17px', fontWeight:'500'}}>Minimum Age</TableCell>
                            <TableCell style={{fontSize:'17px', fontWeight:'500'}}>Maximum Age</TableCell>
                            <TableCell style={{fontSize:'17px', fontWeight:'500'}}>Module duration</TableCell>
                            <TableCell style={{fontSize:'17px', fontWeight:'500'}}>Other Module duration </TableCell>
                            <TableCell style={{fontSize:'17px', fontWeight:'500'}}>Content File</TableCell>
                            <TableCell style={{fontSize:'17px', fontWeight:'500'}}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            [...moduleData]
                                .sort((a, b) => a.order_no - b.order_no)
                                .map((row) =>(
                                    <TableRow style={{ color: "#11888B",}}>
                                        <TableCell style={{ color: "#11888B",}}>{row.module_id}</TableCell>
                                        <TableCell style={{ color: "#11888B",}}>{row.module_name}</TableCell>
                                        <TableCell style={{ color: "#11888B",}}>{row.learning_objective}</TableCell>
                                        <TableCell style={{ color: "#11888B",}}>{row.target_audience ? row.target_audience.split(', ').map(val => PROGRAM_TARGETS_MAP[val]?.label || val).join(', ') : ''}</TableCell>
                                        <TableCell style={{ color: "#11888B",}}>{row.target_audience.includes('Others') ? row.target_audience_other : '-'}</TableCell>
                                        <TableCell style={{ color: "#11888B",}}> {(row.target_audience.includes('Teens') ||
                                            row.target_audience.includes('Youth') ||
                                            row.target_audience.includes('EarlyYears') ||
                                            row.target_audience.includes('Children')) ? row.min_age : '-'}</TableCell>
                                        <TableCell style={{ color: "#11888B",}}> {(row.target_audience.includes('Teens') ||
                                            row.target_audience.includes('Youth') ||
                                            row.target_audience.includes('EarlyYears') ||
                                            row.target_audience.includes('Children')) ? row.max_age : '-'}</TableCell>
                                        <TableCell style={{ color: "#11888B",}}>{row.time_minutes ? row.time_minutes.split(', ').map(val => PROGRAM_DURATION_MAP[val]?.label || val).join(', ') : ''}</TableCell>
                                        <TableCell style={{ color: "#11888B",}}>{row.time_minutes === 'morethan20hours' ? row.module_duration_other : '-'}</TableCell>
                                        <TableCell style={{ color: "#11888B",}}>
                                            {
                                                row.content_file_uploaded ?
                                                    <>
                                                        <a href={row.content_file_path_original}
                                                           target="_blank" rel="noopener noreferrer">
                                                            Download File
                                                        </a>
                                                        {
                                                            !isStatusGreaterThanModule &&
                                                            <>
                                                                {` | `}
                                                                <a href={"#"} rel="noopener noreferrer" onClick={(e) => uploadContentAction(e, row)}>
                                                                    Reupload
                                                                </a>
                                                            </>
                                                        }
                                                    </>
                                                    :
                                                    <Button variant="contained" style={{backgroundColor:'#11888B', color:'white'}} onClick={(e) => uploadContentAction(e, row)}>upload content (docx file)</Button>
                                            }
                                        </TableCell>

                                        <TableCell style={{ color: "#11888B",}}>
                                            <Grid style={{display:'flex'}}>
                                            <Button
                                                variant="contained"
                                                disabled={isStatusGreaterThanModule}
                                                style={{width: '50px', margin: '5px', backgroundColor:'#11888B', color: 'white'}}
                                                onClick={(e) => editModuleAction(row)}
                                            >
                                                Edit
                                            </Button>
                                            { !isStatusGreaterThanModule &&
                                            <Button
                                                variant="contained"
                                                disabled={isStatusGreaterThanModule}
                                                style={{width: '50px', margin: '5px', color: 'white', backgroundColor: 'red'}}
                                                onClick={(e) => deleteModuleAction(row)}
                                            >
                                                Delete
                                            </Button>
                                            }
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                )
                                )
                        }

                    </TableBody>
                </Table>
            </TableContainer>
            <Typography className={classes.mt} style={{textAlign:'center',fontWeight:'bold'}}>Note: The content and its IP belong solely to the organization that uploads it and will be used for only the purpose of alignment.</Typography>
            <Grid container justify={'center'}>
                <Button className={classes.mt} style={{borderRadius:'15px'}} variant="contained" color="primary" onClick={() => submitModules()} disabled={moduleData.length <= 0 || !isAnyContentFileUploaded || isStatusGreaterThanModule}>
                    Submit All
                </Button>
            </Grid>
            <UploadFileDialogue
                openDialogue={openDialogue}
                dialogueCloseHandler={() => { closeUploadDialog() }}
                successCB={addFilePathToApplication}
            />
            <LoaderWithBackDrop loading={loading} />
        </Container>
    );
}

export default CollectionModule;