export const DEVICES = [
    {
        "value": "AnyDevice",
        "label": "Any device"
    },
    {
        "value": "Smartphone",
        "label": "Smartphone"
    },
    {
        "value": "Tablet",
        "label": "Tablet"
    },
    {
        "value": "LaptopDesktop",
        "label": "Laptop / Desktop"
    },
    {
        "value": "AugmentedVirtualMixedReality",
        "label": "Augmented / Virtual / Mixed reality"
    },
    {
        "value": "Others",
        "label": "Others"
    }
];

export const DEVICES_MAP = {
    "AnyDevice": {
        "label": "Any device"
    },
    "Smartphone": {
        "label": "Smartphone"
    },
    "Tablet": {
        "label": "Tablet"
    },
    "LaptopDesktop": {
        "label": "Laptop / Desktop"
    },
    "AugmentedVirtualMixedReality": {
        "label": "Augmented / Virtual / Mixed reality"
    },
    "Others": {
        "label": "Others"
    }
};