import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";

import {Copyright} from '../../../components';

import { useFormik } from 'formik';

import {loginUser, logoutUser} from '../../../services/auth.service';
import { useAuth } from "../../../context/auth";
import {LOCAL_STORAGE_KEYS} from "../../../utils/constants";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const Logout = (props) => {
    const classes = useStyles();

    const { setAuthToken, setAuthUser } = useAuth();

    useEffect( () => {
        handleLogout().then('Successfully Logged out...');
    }, [])

    const handleLogout = async () => {
        try {
            await logoutUser();
            setAuthToken(null);
            setAuthUser(null);
            localStorage.removeItem(LOCAL_STORAGE_KEYS.auth_token);
            localStorage.removeItem(LOCAL_STORAGE_KEYS.auth_user);
            localStorage.removeItem(LOCAL_STORAGE_KEYS.refresh_token);
            // history.push('/login');
        } catch (e) {
            // already toasted the error
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Logging Out...
                </Typography>

            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default withRouter(Logout);