import React from 'react';
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {Divider, Grid, Typography} from "@material-ui/core";
import {PROGRAM_TYPES_MAP} from "../../../utils/META/cat_106";
import {PROGRAM_TARGETS_MAP} from "../../../utils/META/cat_108";
import {TARGET_AGE_MAP} from "../../../utils/META/cat_109";
import {PROGRAM_VENUE_MAP} from "../../../utils/META/cat_111";
import {PROGRAM_DURATION_MAP} from "../../../utils/META/cat_112";
import {PROGRAM_STATUS_MAP} from "../../../utils/META/cat_121";
import {SEAL_TYPES_MAP} from "../../../utils/META/cat_seal_types";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import {APP_STATUS} from "../../../utils/constants";
import {REGIONS_MAP} from "../../../utils/META/cat_101";
import {RESOURCES_TYPE_MAP} from "../../../utils/META/cat_114";
import {RESOURCES_FORMAT_MAP} from "../../../utils/META/cat_115";
import {DEVICES_MAP} from "../../../utils/META/cat_116";
import { PROGRAM_COST_MAP} from "../../../utils/META/cat_113";
import isURL from 'validator/lib/isURL';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(1),
        justifyContent:"center",
    },
    label: {
        // marginLeft: theme.spacing(10),
        wordBreak: "break-word"
    },
    value: {
        // marginTop: theme.spacing(0.8),
        // marginLeft: theme.spacing(10),
        // margin: '0 0 1px 0',
        wordBreak: "break-word",
        color: theme.palette.text.secondary,
        fontWeight:'500',
    },
    button:{
        backgroundColor: theme.palette.primary.main,
        color:'white',
        padding:'6px 20px 6px 20px',
        borderRadius:'11px',
        borderColor: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.background.paper,
        },
    },
    title: {
        color: theme.palette.text.secondary,
        paddingLeft: '30px !important',
        display: 'flex',
        alignItems: 'flex-end',
        fontWeight:'500 !important',
    },
    link: {
        textDecoration: 'underline',
        // margin: '0 0 4px 0',
    }
}));

const key_desc = {
    ProgramName: "Title of program",
    // ProgramType: "Type of program",
    // ProgramTypeOther: "More details About Type",
    ProgramAims: "Objectives of program",
    ResourcesType: "Type of resources",
    ResourcesTypeOther:"Other type of resources",
    ResourcesFormat:"Format of resources",
    ResourcesFormatOther:"Other format of resources",
    ProgramTarget: "Target audience",
    ProgramTargetOther:'Other type of target audience',
    ProgramTargetMinAge:'Program target minimum age',
    ProgramTargetMaxAge:'Program target maximum age',
    // TargetAge: "Target Age group",
    TargetCountry: "Target countries",
    Devices:"Device(s) needed by target audience",
    DeviceTypeOther:"Other type of device(s)",
    ProgramVenue: "Venue(s) where program is conducted",
    ProgramVenueOther:"Other type of venue",
    ProgramDuration: "Duration to complete the program",
    OtherDurationHours:"More than 20hrs duration",
    StartYear: "Year of program launch",
    Status: "Current status of program",
    Links: "Link to program",
    ProgramPrice: "Cost of program",
    // ProgramDescription: "Description of program",
    // ProgramDelivery: "Delivery of program",
    // RefResource: "Resources required",

}

const key_val = {
    // ProgramType: (value) => PROGRAM_TYPES_MAP[value] && PROGRAM_TYPES_MAP[value].label ? PROGRAM_TYPES_MAP[value].label : value,
    ProgramTarget: (value) => {
        return value ? value.split(', ').map(val => PROGRAM_TARGETS_MAP[val]?.label || val).join(', ') : '';
    },
    TargetCountry: (value) => {
        return value ? value.split(', ').map(val => REGIONS_MAP[val]?.name || val).join(', ') : '';
    },
    ResourcesType: (value) => {
        return value ? value.split(', ').map(val => RESOURCES_TYPE_MAP[val]?.label || val).join(', ') : '';
    },
    Devices: (value) => {
        return value ? value.split(', ').map(val => DEVICES_MAP[val]?.label || val).join(', ') : '';
    },
    ResourcesFormat: (value) => {
        return value ? value.split(', ').map(val => RESOURCES_FORMAT_MAP[val]?.label || val).join(', ') : '';
    },
    ProgramVenue: (value) => {
        return value ? value.split(', ').map(val => PROGRAM_VENUE_MAP[val]?.label || val).join(', ') : '';
    },
    ProgramDuration: (value) => {
        return value ? value.split(', ').map(val => PROGRAM_DURATION_MAP[val]?.label || val).join(', ') : '';
    },
    Status: (value) => PROGRAM_STATUS_MAP[value] && PROGRAM_STATUS_MAP[value].label ? PROGRAM_STATUS_MAP[value].label : value,
    ProgramPrice: (value) => PROGRAM_COST_MAP[value] && PROGRAM_COST_MAP[value].label ? PROGRAM_COST_MAP[value].label : value,
}

function ProgramDescription(props) {

    const {progDesc, application_id, app_status} = props;

    const classes = useStyles();

    const history = useHistory();

    const shouldHideButton = app_status < APP_STATUS.COLLECTED_DATA;

    const editProgramDesc = (e) => {
        history.push('/application/edit/program', {existingValues: progDesc, application_id});
    }

    return <Container maxWidth={false} className={classes.container} component={Paper}>
        <Grid container spacing={3} style={{marginTop: "20px"}}>
            <Grid item xs={6} className={classes.title}>
                <Typography variant="h5">Program Description</Typography>
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
            {shouldHideButton && (
                <Button variant={'outlined'} className={classes.button} onClick={editProgramDesc}>
                    Edit
                </Button>
            )}
            </Grid>
            <Grid item xs={12}>
                <Divider style={{ backgroundColor: '#052F44' }}/>
            </Grid>
            {Object.entries(key_desc).map(([key, value]) => value && progDesc[key] && (
                <Grid item xs={12} key={key}>
                    <Grid container alignItems="flex-start">
                        <Grid item xs={4} style={{ display: 'flex', textAlign: 'left', marginLeft: '100px', marginRight:'20px' }}>
                            <Typography variant="h6" className={classes.label}>{value}</Typography>
                        </Grid>
                        <Grid item xs={6} style={{ display: 'flex', textAlign: 'left',  }}>
                            {key === 'Links' && isURL(progDesc[key]) ? (
                                <a href={progDesc[key]} target="_blank" className={classes.link} >
                                    <Typography variant="h6" className={classes.value}>
                                        {progDesc[key]}
                                    </Typography>
                                </a>
                            ) : (
                                <Typography variant="h6" className={classes.value}>
                                    {key_val[key] ? key_val[key](progDesc[key]) : progDesc[key]}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    </Container>;
}

export default ProgramDescription;
