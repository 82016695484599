import {useEffect} from "react";
import {getLevel6Service} from "../../../services/app.service";

export function useLevel6(setLevel6) {

    const loadLevel6 = async () => {
        try {
            const result = await getLevel6Service();
            if(!result.error) {
                setLevel6(result.data.metaData);
            }
        }
        catch (e) {
            console.error(e);
            // already toasted the error
        }
    }

    useEffect( ()=> {
        loadLevel6().then((res) => `level6 fetch initiated, ${res}`);
    }, [])
}