import React from 'react';
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";
import {makeStyles} from "@material-ui/core/styles";
// import {DQ_DEFINITIONS} from "../../../../utils/constants";
import DQCircle from "../../../../assets/images/report/DQFrameworkWheel_2023_FINAL-1536x1424.png";


function ListItem(props) {

    const {title} = props;

    return <li style={{fontSize: 32, fontWeight: "lighter"}}>
        <Typography
            style={{
                color: "black",
                fontWeight: "lighter",
                fontFamily: 'Source Sans Pro',
                fontSize: '18px',
                textAlign: 'justify'
            }}
        >
            {title}
        </Typography>
    </li>
}

export default function Page15(props) {

    const {pageNumber, codes, onlyTable = false} = props;

    return <ReportPage pageNumber={pageNumber}>
        <Box id="3.1.3-increase-the-holistic-learning-experience" display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={"flex-start"} height={"inherit"} px={10}>
            { !onlyTable &&
                <Box>
                    <Typography
                        variant={'h6'}
                        style={{
                            color: "blue",
                            fontWeight: "lighter",
                            fontFamily: 'Raleway',
                            paddingTop: '70px',
                            fontSize: '20px'
                        }}
                    >
                        3.1.3	Increase the Holistic Learning Experience
                    </Typography>
                    <Box display={'flex'} alignItems={'center'}>
                        <Box component="img" src={DQCircle} alt="DQ Circle" style={{ width: '500px', height: 'auto', marginRight: '30px', marginLeft: '50px', paddingTop: '30px' }} />
                    </Box>
                    <Typography
                        style={{
                            color: "black",
                            fontWeight: "lighter",
                            fontFamily: 'Source Sans Pro',
                            paddingTop: '30px',
                            fontSize: '18px',
                            textAlign: 'justify'
                        }}
                    >
                        The holistic learning experience of the program can be enhanced by including the following
                        DQ Competencies in other areas of Connectivity and Citizenship:
                    </Typography>
                </Box>
            }
            <Box alignSelf={onlyTable ? "flex-start" : "inherit"} paddingTop={onlyTable ? "70px" : "30px"}>
                <Box alignSelf={onlyTable ? "flex-start" : "inherit"}>
                    <ul style={{display: "flex", flexDirection: "column", listStyleType: "disc", fontWeight: "lighter"}}>
                        {
                            codes.map((code) => <ListItem title={code}/>)
                        }
                    </ul>
                </Box>
            </Box>
        </Box>
    </ReportPage>
}