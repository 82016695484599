export const PROGRAM_DURATION = [
    {
        "value": "lessthan15min",
        "label": "Less than 15 minutes"
    },
    {
        "value": "15-30min",
        "label": "15 minutes - 1 hour"
    },
    // {
    //     "value": "30min-1hr",
    //     "label": "30min-1hr"
    // },
    // {
    //     "value": "1hour",
    //     "label": "1 hour"
    // },
    // {
    //     "value": "2hours",
    //     "label": "2 hours"
    // },
    {
        "value": "1-3hours",
        "label": "1-3 hours"
    },
    {
        "value": "3-5hours",
        "label": "3-5 hours"
    },
    {
        "value": "5-10hours",
        "label": "5-10 hours"
    },
    // {
    //     "value": "6-7hours",
    //     "label": "6-7 hours"
    // },
    // {
    //     "value": "8-10hours",
    //     "label": "8-10 hours"
    // },
    {
        "value": "11-15hours",
        "label": "10-15 hours"
    },
    {
        "value": "16-20hours",
        "label": "15-20 hours"
    },
    {
        "value": "morethan20hours",
        "label": "More than 20 hours"
    }
];

export const PROGRAM_DURATION_MAP = {
    "lessthan15min": {
        "label": "Less than 15 minutes"
    },
    "15-30min": {
        "label": "15 minutes - 1 hour"
    },
    // "30min-1hr": {
    //     "label": "30min-1hr"
    // },
    // "1hour": {
    //     "label": "1 hour"
    // },
    // "2hours": {
    //     "label": "2 hours"
    // },
    "1-3hours": {
        "label": "1-3 hours"
    },
    "3-5hours": {
        "label": "3-5 hours"
    },
    "5-10hours": {
        "label": "5-10 hours"
    },
    // "6-7hours": {
    //     "label": "6-7 hours"
    // },
    // "8-10hours": {
    //     "label": "8-10 hours"
    // },
    "11-15hours": {
        "label": "10-15 hours"
    },
    "16-20hours": {
        "label": "15-20 hours"
    },
    "morethan20hours": {
        "label": "More than 20 hours"
    }
};