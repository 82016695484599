import React from 'react';
import DQFramework from "../../../../assets/images/report/dqframework.png";
import DQCompetencies from "../../../../assets/images/report/DQCompetencies_2023_Final.png"
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";

export default function Page6(props) {

    const {pageNumber} = props;

    return <ReportPage pageNumber={pageNumber}>
        <Box id="1.3-about-the-dq-global-standards-framework-2.0" display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={"flex-start"} height={"inherit"} px={10}>
            <Typography
                variant={'h3'}
                style={{
                    color: "orangered",
                    fontWeight: "lighter",
                    fontFamily: 'Raleway',
                    paddingTop: '70px',
                    fontSize: '22px'
                }}
            >
                1.3 About the DQ Global Standards Framework 2.0
            </Typography>
            <Typography
                style={{
                    color: "black",
                    fontWeight: "lighter",
                    fontFamily: 'Source Sans Pro',
                    paddingTop: '30px',
                    fontSize: '18px',
                    textAlign: 'justify'
                }}
            >
                The Digital Intelligence (DQ) Global Standards Framework 2.0 is a comprehensive set of technical, cognitive, meta-cognitive, and socio-emotional competencies that are grounded in universal moral values and that enable individuals to face the challenges and harness the opportunities of digital life.
            </Typography>
            <Typography
                style={{
                    color: "black",
                    fontWeight: "lighter",
                    fontFamily: 'Source Sans Pro',
                    paddingTop: '30px',
                    fontSize: '18px',
                    textAlign: 'justify'
                }}
            >
                The DQ Framework 2.0 has 4 levels, 8 areas, and 32 competencies composed of knowledge, skills, attitudes, and values. With four levels of DQ across eight areas, the following 32 competencies have been identified based on an aggregation of over 25 leading frameworks on digital competencies worldwide.<sup>1</sup>
            </Typography>

            <Box mt={4} display={'flex'} justifyContent={'center'} width={'100%'}>
                <img src={DQCompetencies} alt="DQ Framework Illustration" style={{ maxWidth: '100%', height: 'auto' }}/>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: '20px',
                    left: '12%',
                    right: '50%',
                    padding: '30px 30px',
                    borderTop: '1px solid #000',
                }}
            >
                <Typography component="div">
                    <span style={{
                        marginRight: '8px',
                        fontSize: '10px',
                        verticalAlign: 'super'
                    }}>1</span>
                    <a href="https://www.dqinstitute.org/global-standards/" style={{ fontSize: '10px', textDecoration: 'underline', color: 'blue' }}>
                        https://www.dqinstitute.org/global-standards/
                    </a>
                </Typography>
            </Box>

        </Box>
    </ReportPage>
}