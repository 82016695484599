import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
    Tabs,
    Tab,
    Box,
} from "@material-ui/core";

import TabHeader from './header';
import TabChild from "./child.component";
import {NavLink, Link} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root:{
        backgroundColor: "white",
        color: theme.palette.text.primary,
        // margin: "0px 1px 0px 1px",
        padding: "0px 0px 0px 0px",
        minWidth: "auto",
        minInlineSize: "auto",
        // borderRadius: '15px 0 0 15px',
        border: '1px solid',
        borderColor:  theme.palette.text.primary,
        opacity:'1',
        boxShadow: "6px 0px 20px rgba(0, 0, 0, 0.3)",

        "&.Mui-selected": {
            backgroundColor: theme.palette.text.secondary,
            color: "white",
            borderColor:  theme.palette.text.secondary,
        }
    },
    firstTab: {
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    boxShadow: "6px 0px 20px rgba(0, 0, 0, 0.3)",
},

    secondTab: {
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
    boxShadow: "6px 0px 20px rgba(0, 0, 0, 0.3)",
},
    indicator: {
        backgroundColor: "primary",
        boxShadow: "6px 0px 20px rgba(0, 0, 0, 0.3)",
        height: "100%",
        opacity: "0%",
        width: "100%",
    },

    link: {

    }
}));


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
    };
}


export default function TabContainer(props) {

    const {children: comps, value: selected_tab, onChange: parentHandleChange = () => {}} = props;
    const components = [].concat(comps); //this ensures components to always be an array
    const classes = useStyles();
    const [value, setValue] = React.useState(selected_tab ? selected_tab : 0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if(parentHandleChange)
            parentHandleChange(newValue);
    };

    return <Box>
        <Tabs centered variant="fullWidth" scrollButtons="auto" value={value} onChange={handleChange} aria-label="styled tabs example" TabIndicatorProps={{ className: classes.indicator }}>
            {
                components.map((component, index) => {
                    const {title, link} = component.props;
                    return <Tab
                        key={index}
                        classes={{ root: `${classes.root} ${index === 0 ? classes.firstTab : classes.secondTab }`}}
                        label={
                            link ?
                                <Link style={{ textDecoration: 'none', color: "#052F44", width: "100%", padding: "10px"}} to={link}>
                                    <TabHeader title={title}/>
                                </Link>
                                : <TabHeader title={title}/>
                        }
                        {...a11yProps(index)}
                    />
                })
            }
        </Tabs>
        {
            components.map((component, index) =>
                <TabChild key={index} value={value} index={index}>
                    {component}
                </TabChild>
            )
        }
        {/*{*/}
        {/*    comps.map((component, index) => {*/}
        {/*        const modified_component = {...component};*/}
        {/*        modified_component.props = {...component.props, }*/}
        {/*        return modified_component;*/}
        {/*    })*/}
        {/*}*/}
    </Box>;
}